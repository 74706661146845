import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { useFormikContext } from 'formik'
import { camelCaseToCapital } from "../../../../shared/commonHelpers"
import { InputGroup } from "react-bootstrap"
import { FormikControl } from "@stem-sims/nexus"

type FormInputProps = {
    fieldName: string
    type?: string
    label?: string
    onChange?: (event, formik?) => void
    disabled?: boolean
    groupText?: string
    id?: string
    role?: string
    required?: boolean
    className?: any
}
export default function FormInput({fieldName, type="text", label, onChange, className, ...rest}: FormInputProps) {
    const formik = useFormikContext<unknown>()

    const onChangeWithFormik = (event) => {
        formik.handleChange(event)
        if(onChange) onChange(event, formik)
    }

    return <>
        <Row className="mb-3">
            <Form.Label htmlFor={fieldName} className="d-none d-sm-inline col-3 text-end mt-2">{label ?? camelCaseToCapital(fieldName)}</Form.Label>
            <Col sm={6}>
                <InputGroup>
                    <FormikControl
                        className={className}
                        type={type}
                        onChange={onChangeWithFormik}
                        id={fieldName}
                        name={fieldName}
                        value={formik.values[fieldName]}
                        placeholder={camelCaseToCapital(fieldName)}
                        data-description={`enter your ${camelCaseToCapital(fieldName).toLowerCase()}`}
                        maxInput={175}
                        role={type === "password" ? "password" : "textbox"}
                        {...rest}
                    />
                </InputGroup>
            </Col>
        </Row>
    </>
}
