import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import * as organizations from '../../shared/routes/organizationsRoutes'
import * as accountModel from "../../shared/routes/accountInfoRoutes"
import { AccountInfoProps } from '../../shared/types/accountTypes'
import redirect from '../redirect/redirect'
import message from '../message/message'
import readabilityPDF from '../../content/files/readability-of-stories.pdf'
import pronunciationGuidePDF from '../../content/files/pronunciation-guide.pdf'
import logo from '../../content/images/logo.svg'
import { modelRequest } from '@stem-sims/nexus'
import Loader from 'react-spinners/BeatLoader'

type Org = {
    id: string
    name: string
    groups: string[]
    personal: "Yes" | "No"
}

type Props = {
    currentOrg: {
        name: string
        groupName: string
        personal: "Yes" | "No"
        permissions: string
    }
    switchOrg: (org: string, group: string) => Promise<void>
    updatePermissions: () => Promise<void>
    homeURL: string
}

type State = {
    currentOrgName: string
    orgs: Org[]
    currentGroup: string
    loadingLists: boolean
    account: AccountInfoProps
}

class CustomerNavbar extends React.Component<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            orgs: [],
            currentOrgName: props.currentOrg.name,
            currentGroup: props.currentOrg.groupName,
            loadingLists: true,
            account: null
        }
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this.customHooks()
        accountModel.get().then((account) => {
            this.setState({account})
        }).catch(() => {
            message.error("Could not get your account information. Please refresh the page.")
        })
        this.updateOrgs()
    }

    componentDidUpdate(prevProps: Props) {
        // update state to reflect most current org/group
        // actually check for a change to current org/group (prev vs new)
        if (prevProps.currentOrg.name !== this.props.currentOrg.name) {
            this.updateOrgs()
            this.setState({
                currentOrgName: this.props.currentOrg.name
            })
        } else if (prevProps.currentOrg.groupName !== this.props.currentOrg.groupName) {
            this.setState({
                currentGroup: this.props.currentOrg.groupName
            })
        }
    }

    /**
     * Updates the organization list
     */
    updateOrgs = () => {
        organizations.get().then((orgs) => {
            this.setState({
                orgs,
                loadingLists: false
            })
        }).catch(() => {
            message.error("Could not get your organization and groups. Please refresh the page.")
        })
    }

    /**
     * Switches to a selected organization
     *
     * @param {string} org
     * @param {string} group
     */
    selectOrg = (org, group) => {
        this.props.switchOrg(org, group).then(() => {
        })
            .catch(() => {
                message.error("There was an error changing orgs.")
            })
            .finally(() => {
                this.props.updatePermissions()
            });
    }

    /**
     * Converts a string to 2 letters by taking the first letter of the first word and first letter of last word
     * If there is only one word only 1 letter is returned
     *
     * @param {string} title
     */
    getIconLetters = (title) => {

        if (!title) return

        let words = title.split(' ');
        if (words.length > 1) {
            return words[0].charAt(0).toUpperCase() + words[words.length - 1].charAt(0).toUpperCase();
        } else {
            return words[0].charAt(0).toUpperCase();
        }
    }

    /**
     * contains jquery code with custom event handlers to ensure the account menu dropdown works properly
     */
    customHooks = () => {
        window.$("ul.dropdown-menu [data-toggle='dropdown']").off('click.dropdown').on("click.dropdown", function (event) {
            event.preventDefault();
            event.stopPropagation();

            window.$(this).siblings().toggleClass("show");

            if (!window.$(this).next().hasClass('show')) {
                window.$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
            }
            window.$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
                window.$('.dropdown-submenu .show').removeClass("show");
            });
        });

        // prevent dropwdown from closing when the collapse buttons are clicked
        window.$('#account-dropdown').on('hide.bs.dropdown', function (e) {
            if (!e.clickEvent) {
                return true;
            }
            var target = window.$(e.clickEvent.target);

            return !(target.hasClass('keep-open') || target.parents('.keep-open').length);
        });
    }

    logout(event) {
        event.preventDefault()
        modelRequest.post("/api/logout").then(() => {
            this.props.updatePermissions().then(() => {
                redirect.send("/", this.props, () => {
                    message.success("You have successfully logged out.")
                })
            })
        })
    }

    render() {
        if(!this.state.orgs?.length || !this.state.account){
            return <Loader color="#2a75f6" />
        }
        const accountName = this.state.account.name
        const onlyPersonal = this.state.orgs.length === 1 && this.state.orgs[0].personal
        const profileIcon = <>
            <div className={`circle-icon big position-relative bg-primary inline ${this.getIconLetters(accountName).length === 1 ? "single" : ""}`}>
                {this.getIconLetters(accountName)}
                {!onlyPersonal && 
                    <div className={`circle-icon mini inline ${this.getIconLetters(this.state.currentOrgName).length === 1 ? "single" : ""}`}>
                        {this.getIconLetters(this.state.currentOrgName)}
                    </div> 
                }
            </div> 
        </>

        return (<Navbar id="navbar" className="d-flex fixed-top hidden-print flex-center px-2 py-0" expand="lg">
            <Container>
                <Navbar.Brand className="d-flex flex-center">
                    <Nav.Link href={this.props.homeURL} onClick={() => {
                        window.scrollTo(0, 0) //Lisa wants to be able to scroll to top instantly
                    }}>
                        <img src={logo} title="Home" className='p-1 ms-2' alt="Logo" />
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className='w-100 d-flex align-items-lg-center'>
                        <Nav.Link href="/dashboard" className="ms-half-navbar px-2" onClick={() => {
                            window.scrollTo(0, 0) // Lisa wants to be able to scroll to top instantly
                        }}>
                            Dashboard
                        </Nav.Link>
                        <Nav.Link className="px-2" href="/students">Manage Students</Nav.Link>

                        {!this.props.currentOrg.personal && (this.props.currentOrg.permissions === 'Licensee' || this.props.currentOrg.permissions === 'Manager') ?
                            <Nav.Link className="px-2" href="/manage">Manage Org</Nav.Link>
                            : ''}
                        {this.props.currentOrg.personal || this.props.currentOrg.permissions === 'Licensee' || this.props.currentOrg.permissions === 'Manager' ?
                            <Nav.Link className="px-2" href="/subscribe/choose">Pricing</Nav.Link>
                            : ''}
                        <NavDropdown title="Resources" id="resources-dropdown">
                            <NavDropdown.Item href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noopener noreferrer">Instructions</NavDropdown.Item>
                            <NavDropdown.Item href={readabilityPDF} target="_blank" rel="noopener noreferrer">Readability of Stories</NavDropdown.Item>
                            <NavDropdown.Item href={pronunciationGuidePDF} target="_blank" rel="noopener noreferrer">Pronunciation Guide</NavDropdown.Item>
                            <NavDropdown.Item href="https://greatleaps.com/index.php?main_page=contact_us" target="_blank" rel="noopener noreferrer">Customer Support</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown id="org-dropdown" title={profileIcon} className="account-dropdown ms-auto text-center">
                            {/* Orgs */}
                            {this.state.loadingLists ?
                                // return 5 of same block
                                Array.apply(null, Array(3)).map((_, i) => {
                                    return (
                                        <li className="keep-open" key={i}>
                                            <div className="dropdown-item">
                                                <Skeleton circle={true} height={32} width={32} />
                                                <Skeleton height={16} width={120} style={{ lineHeight: "0px", marginLeft: "10px" }} />
                                            </div>
                                        </li>
                                    )
                                })
                                : this.state.orgs.length > 1 ?
                                    this.state.orgs.map((org, index) => {
                                        const personalOrg = index === 0
                                        const currentOrgSelected = org.name === this.state.currentOrgName // todo GL-801: add org id to currentOrg, put it on state, and use id here
                                        return (
                                            <div key={org.id} className="keep-open">
                                                {org.groups.length > 1 ?
                                                        <li className="with-icon with-caret px-3" data-toggle="collapse" data-target={"#group-list-" + org.name.split(" ").join("-")} aria-controls="org-list">
                                                            <div className={`circle-icon big ${personalOrg ? "bg-primary" : "bg-success"} me-2`}>{this.getIconLetters(org.name)}</div>
                                                            <span className="nav-label">{org.name}</span>
                                                        </li>
                                                        :
                                                        <NavDropdown.Item className={`with-icon ${currentOrgSelected ? "selected-dropdown" : ""}`} onClick={() => this.selectOrg(org.id, org.groups[0])}>
                                                            <div className={`circle-icon big ${personalOrg ? "bg-primary" : "bg-success"} me-2`}>{this.getIconLetters(org.name)}</div>
                                                            <span className="nav-label">{org.name}</span>
                                                        </NavDropdown.Item>
                                                }
                                                <ul id={"group-list-" + org.name.split(" ").join("-")} className="group-list collapse px-0">
                                                    {
                                                        this.state.loadingLists ? <Skeleton count={3} width={160} />
                                                            : this.state.orgs[index].groups.map((group) => {
                                                                return <NavDropdown.Item key={group} className="" data-toggle="dropdown" data-target="#account-dropdown" onClick={() => this.selectOrg(org.id, group)}>
                                                                    {group}
                                                                </NavDropdown.Item >
                                                            })
                                                    }
                                                </ul>

                                            </div>
                                        )
                                    }) : ""
                            }
                            {
                                this.state.orgs.length > 1 ? <div className="dropdown-divider"></div> : ""
                            }
                            <NavDropdown.Item className="py-1" href="/help/frequently-asked-questions">FAQ</NavDropdown.Item>
                            <NavDropdown title="Learn More" id="about-dropdown" className="px-3 py-1">
                                <NavDropdown.Item className="" href="/about/the-program">The Program</NavDropdown.Item>
                                <NavDropdown.Item className="" href="/about/testimonials">Testimonials</NavDropdown.Item>
                                <NavDropdown.Item className="" href="/about/research">Research</NavDropdown.Item>
                                <NavDropdown.Item className="" href="/about/use-cases">Use Cases</NavDropdown.Item>
                                <NavDropdown.Item className="" href="/about/our-story">Our Story</NavDropdown.Item>
                            </NavDropdown>
                            <div className="dropdown-divider"></div>

                            <NavDropdown.Item className="" href="/account/information">My Account</NavDropdown.Item>
                            <form id="log-out-form" className="prevent-next-submit" noValidate method="post" action="/log-out" onSubmit={this.logout}>
                                <button type="submit" className="dropdown-item">Log Out</button>
                            </form>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>)
    }
}

export default CustomerNavbar
