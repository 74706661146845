import React from 'react'
import getURLParameters from '../../../helpers/getURLParams'
import redirect from '../../../components/redirect/redirect'
import message from '../../../components/message/message'
import BeatLoader from 'react-spinners/BeatLoader'
import { modelRequest } from '@stem-sims/nexus'

class VerifyEmailAddress extends React.Component {

    componentDidMount() {
        var params = getURLParameters(this.props)

        if (!params.code) {
            return redirect.send('/', this.props, () => {
                message.error("We weren't able to verify your email address, please try again.")
            })
        }

        modelRequest.post('/api/account/verify-email', {
            code: params.code
        })
            .then((response) => {
                this.props.updatePermissions()
                    .then((user) => {
                        if (user.includes("customer")) {
                            redirect.send('/dashboard', this.props, () => {
                                message.success("Your email was sucessfully verified.")
                            })
                        }
                        else if (user.includes("student")) {
                            redirect.send('/student/dashboard', this.props, () => {
                                message.success("Your email was sucessfully verified.")
                            })
                        }
                        else {
                            redirect.send('/account/log-in/teacher', this.props, () => {
                                message.info("Please log in to continue with your account")
                            })
                        }
                    })
            }).catch((err) => {
                redirect.send('/dashboard', this.props, () => {
                    message.error(err)
                })
            })
    }

    render() {
        return (
            <div style={{ margin: "0 auto", display: "table", height: "80vh" }}>
                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                    <BeatLoader
                        size={15}
                        color={"#123abc"}
                        loading={true}
                    />
                </div>
            </div>
        )
    }
}

export default VerifyEmailAddress
