import React from 'react'
import HelpButton from './HelpButton'

function ProbeListHelp() {
    return (
        <React.Fragment>
            <HelpButton modelId = 'probe-list-help' />

            <div className="modal fade" id = "probe-list-help" role = "dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Exercise List Help</h3>
                            <button type = "button" className="close" data-dismiss = "modal">&times;</button>
                        </div>
                        <div className="modal-body row text-start" style={{marginLeft: '10px', marginRight: '10px', fontSize: '17px'}}>
                            <h3 style={{marginTop: '3px'}}>Basic Procedure</h3>
                            <ul>
                                <li style={{paddingBottom: '10px'}}>Begin with a phonics timing, then sight phrases and finish with stories followed by Comprehension Development Questions.</li>
                                <li style={{paddingBottom: '10px'}}><strong>BE SURE</strong> to stay on the highlighted exercise until the student makes a goal. The program will highlight the correct exercises for your student each day.</li>
                                <li style={{paddingBottom: '10px'}}>The <strong>student code</strong> is used to connect the instructor’s device with the student’s device. Make sure the student has selected “Student” when logging in and uses the correct username and the student code provided.</li>
                                <li><strong>Optimal growth with Great Leaps comes from working with a student 5 days a week. Less than 3 days per week is not using it with fidelity.</strong></li>
                            </ul>
                            <h3 style={{width: '100%'}}>Watch</h3>
                            <p style={{marginBottom: '25px', width: '100%'}}><a href = "https://www.youtube.com/watch?v=vD98CEEIpMY&t=3s" target="_blank" rel="noreferrer">Complete Lesson Example</a></p>
                            <p className="w-100">For additional reading instructions, <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">click here</a>.</p>
                            <p className="w-100">For additional math instructions, <a href={"/file/math_instructions"} target="_blank" rel="noreferrer">click here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProbeListHelp
