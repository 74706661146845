import React from 'react'
import { Field, useFormikContext } from 'formik'
import StudentModal from './StudentModal'
import { useRoster } from './StudentsContext'
import { modelRequest } from '@stem-sims/nexus'

type TransferProps = {
    show: boolean
}

function TransferForm() {
    const roster = useRoster()
    const { values } = useFormikContext<any>()
    return(<>
        <div className= "row subheader-text">
            <div className= "col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                <p>Please enter the username of the person to whom you wish to transfer students.</p>

                <p>Please note that transferring a student is <strong>permanent</strong>, and will result in the student and all of their records being removed from your account and added to the other user's.</p>
            </div>
        </div>
        <div className= "mb-3 row">
            <label className= "text-center-xs bold-xs col-sm-3 control-label text-end">Student{roster.selectedStudents.length > 1 ? "s" : ""}</label>
            <div className= "col-sm-6">
            {
                roster.selectedStudents.map((student) => {
                    return <p className= "form-control-static text-start text-center-xs break-words">{student.name}</p>
                })
            }
            </div>
        </div>

        <div className= "mb-3 row">
            <label htmlFor="username" className= "d-none d-sm-inline col-sm-3 control-label text-end">Email Address</label>
            <div className= "col-sm-6">
                <Field value={values?.email} type="text" className="form-control" id="email-address" name="email" placeholder="Email Address" autoFocus data-description = "enter an email address" required/>
            </div>
        </div>
    </>)
}

function Transfer(props: TransferProps) {
    const roster = useRoster()
    const transferStudent = () => {
        const studentIDs = roster.selectedStudents.map(student => student.id)
        return modelRequest.post('/api/students/transfer/send', {
            students: studentIDs,
            email: document.getElementById('email-address')['value']
        })
    }
    return (<>
        <StudentModal show={props.show} hide={roster.hideModals} action="Transfer" submitAction={transferStudent} >
            <TransferForm />
        </StudentModal>
    </>)
}

export default Transfer
