import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import getURLParameters from '../../helpers/getURLParams';
import message from '../../components/message/message';
import redirect from '../../components/redirect/redirect';
import ReactGA from 'react-ga4'
import { modelRequest } from '@stem-sims/nexus'

class Processing extends React.Component {
    constructor() {
        super()
        this.state = {
            transaction: null
        }
    }

    componentDidMount() {
        let params = getURLParameters(this.props)
        if (params.status && params.checkout_id) {
            modelRequest.get(`/api/transaction/completed/?status=${params.status}&checkout_id=${params.checkout_id}`).then((response) => {
                let transaction = response
                redirect.send('/students', this.props, () => {
                    message.success(transaction)
                    ReactGA.event({
                        category: 'digitalCheckout',
                        action: 'purchase'
                    })
                })
            }).catch((err) => {
                message.error(err)
            })
        }
        else {
            redirect.send('/', this.props, () => {
                message.error('You do not have access to this page')
            })
        }
    }

    render() {
        return (
            <BeatLoader></BeatLoader>
        )
    }
}

export default Processing
