import React from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { Instructor } from "../../shared/types/orgUserTypes"
import organizationRemoveInstructor from "../../shared/routes/organizationInstructorRoutes"
import message from "../message/message"

interface Props {
    selectedInstructor: Instructor
    groupName: string
    isOpen: boolean
    onClose: () => void
}

export default function RemoveInstructorGroup({ selectedInstructor, groupName, isOpen, onClose }: Props) {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    return (
        <Modal
            show={isOpen}
            onHide={onClose}
        >
            <Modal.Header closeButton>
                <h2 className=" h3">Remove Organization Instructor From Group</h2>
            </Modal.Header>
            <Modal.Body>
                <div className = "row text-start">
                    <div className = "col-md-10 offset-md-1">
                        <p>Please note that after the instructor is removed from the group they <strong>will lose access to any students in the group</strong>.</p>
                    </div>
                </div>


                <div className = "mb-3 row">
                    <label className = "d-none d-sm-inline col-sm-6 control-label text-end">Instructor Name:</label>

                    <div className = "col-sm-6">
                        <p className = "form-control-static text-start text-center-xs break-words">{selectedInstructor.name}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>onClose()} className="button-inline" variant="secondary">Cancel</Button>
                <Button
                    className="button-inline"
                    variant="danger"
                    disabled={isSubmitting}
                    onClick={() => {
                        const GENERIC_ERROR = "There has been an error removing the instructor. Please try again."
                        setIsSubmitting(true)
                        message.clear()
                        organizationRemoveInstructor.removeFromGroup({
                            groupName: groupName,
                            customerUsername: selectedInstructor.username,
                        }).then((response) => {
                            message.success("The instructor has been successfully removed.")
                        }).catch((err) => {
                            message.error(err ?? GENERIC_ERROR)
                        }).finally(() => {
                            setIsSubmitting(false)
                            onClose()
                        })
                    }}
                >
                    Remove
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
