import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import Skeleton from 'react-loading-skeleton'
import alphabet from "../../../content/images/alphabet.svg"
import phonics from "../../../content/images/phonics.svg"
import phrases from "../../../content/images/phrases.svg"
import stories from "../../../content/images/stories.svg"
import { RecordsContext } from '../RecordsContext'
import * as recordsModel from '../../../shared/routes/recordRoutes'
import { ToolTip } from '../../students/components/EditLicenses'

function StatsCard({header, children}) {

    return <Card className='h-100 w-100 p-3'>
        <h2 className='gl-blue h5 fw-bold ps-2'>{header}</h2>
        {children}
    </Card>
}
function InfoBox({header, color="light-blue", children}) {

    return <Card className={`infoBox bg-${color} my-1`}>
        <label className='gl-blue h6 fw-bold'>{header}</label>
        <div className='h4'>{children}</div>
    </Card>
}
function GoalListItem({title, img, goals}) {

    return <ListGroup.Item className='d-flex justify-content-between align-items-center ps-0'>
    <div className='py-2'>
      <Image src={img} className='img-fluid px-2 px-xl-3'/>
      <span>{title}</span>
    </div>
    <div>{goals ?? <Skeleton width={25}/>}</div>
</ListGroup.Item>
}

function monthDiff(d1: Date, d2: Date) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
function weeksBetween(d1, d2) {
  return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
}

export default function Stats() {
  const context = useContext(RecordsContext)
  const [alphabetGoals, setAlphabetGoals] = useState<number>(null)
  const [phonicsGoals, setPhonicsGoals] = useState<number>(null)
  const [phrasesGoals, setPhrasesGoals] = useState<number>(null)
  const [storiesGoals, setStoriesGoals] = useState<number>(null)
  const [avgSesh, setAvgSesh] = useState<number>(null)
  const [lengthInProgram, setLengthInProgram] = useState<number>(null)

  function formatLengthInProgram() {
    if(lengthInProgram > 12) {
      const yearsInProgram = (lengthInProgram / 12)
      const formattedYearsInProgram = +(yearsInProgram).toFixed(1)
      return <>
        <div className='display-5 text-center'>{formattedYearsInProgram ?? <Skeleton width={"3rem"}/>}</div>
        <div className='h3 text-center'>{"year" + (yearsInProgram === 1 ? "" : "s")}</div>
      </>
    } else return <>
      <div className='display-5 text-center'>{+(lengthInProgram)?.toFixed(1) ?? <Skeleton width={"3rem"}/>}</div>
      <div className='h3 text-center'>{"month" + (lengthInProgram === 1 ? "" : "s")}</div>
    </>
  }

  useEffect(() => {
    let totalSessions = new Set()
    let totalWeeksInProgram = 0
    let totalMonthsInProgram = 0
    const setGoals = (type, setGoals) => {
      return recordsModel.get(context.studentID, type).then((records) => {
        
        records?.rawRecords.forEach((record) => {
          if(!record.date) return
          const dateString = new Date(record.date).toLocaleDateString("en-US")
          totalSessions.add(dateString)
        })
        const firstSessionDate = new Date(records.rawRecords?.[0]?.date)
        if(firstSessionDate instanceof Date && !isNaN(firstSessionDate as any)) { // typescript wants a number for isNaN (NaN is returned from Date constructor)
          const endDate = new Date(records.endDate)
          const monthsInProgram: number = monthDiff(firstSessionDate, endDate)
          const weeksInProgram = weeksBetween(firstSessionDate, endDate)
          totalWeeksInProgram = Math.max(totalWeeksInProgram, weeksInProgram)
          totalMonthsInProgram = Math.max(totalMonthsInProgram, monthsInProgram)
        }
        const goals = records?.chartGoals?.length
        setGoals(goals)
      })
    }
    const setAlphabetPromise = setGoals("alphabet", setAlphabetGoals)
    const setPhonicsPromise = setGoals("phonics", setPhonicsGoals)
    const setPhrasesPromise = setGoals("phrases", setPhrasesGoals)
    const setElementaryPromise = setGoals("elementary", (goals) => setStoriesGoals((prevGoals) => prevGoals += goals))
    const setMiddlePromise = setGoals("middle", (goals) => setStoriesGoals((prevGoals) => prevGoals += goals))
    const setHighPromise = setGoals("high", (goals) => setStoriesGoals((prevGoals) => prevGoals += goals))
    Promise.all([setAlphabetPromise,setPhonicsPromise,setPhrasesPromise,setElementaryPromise,setMiddlePromise,setHighPromise]).then(() => {
      setLengthInProgram(totalMonthsInProgram)
      const totalSessionCount = totalSessions.size
      setAvgSesh(totalSessionCount / totalWeeksInProgram)
    })
  }, [context.studentID])

  return(<>
      <Row className='mb-3'>
          <Col md={8}>
              <StatsCard header={"PERFORMANCE HIGHLIGHTS"}>
                <Row>
                  <Col>
                    <InfoBox header={"GRADE LEVEL GROWTH"}>
                      {context.donutData?.stories ? 
                      <>
                        <span>{context.donutData?.stories.years ?? 0}</span>
                        <span> years</span>
                        <span> {context.donutData?.stories.years > 0 && "↑"}</span>
                      </>
                      : <Skeleton width={"100%"}/>
                      }
                    </InfoBox>
                  </Col>
                  <Col>
                    <InfoBox header={"READING RATE"}>
                    {context.donutData?.stories ? 
                      <>
                        <span>{context.donutData?.stories.wpmPercentChange >= 0 ? `${context.donutData?.stories.wpmPercentChange}%` : "N/A" }</span>
                      </>
                      : <Skeleton width={"100%"}/>
                    }
                    </InfoBox>
                  </Col>
                  <Col>
                    <InfoBox header={"AVG SESSIONS PER WEEK"}>
                      <ToolTip title="Gaps between reading sessions are still counted">
                        <span>{avgSesh ? (Number.isFinite(avgSesh) ? avgSesh.toFixed(0) : "N/A") : <Skeleton width={"2rem"} />} </span>
                      </ToolTip>
                    </InfoBox>
                  </Col>
                </Row>
              </StatsCard>
          </Col>
          <Col md={4} className="d-flex">
              <StatsCard header={"LENGTH IN PROGRAM"}>
                <div className='d-flex flex-1 flex-column justify-content-center'>
                {formatLengthInProgram()}
                </div>
              </StatsCard>
          </Col>
      </Row>
      <Row>
          <Col md={4}>
              <StatsCard header={"GOALS ACHIEVED"}>
                <ListGroup variant='flush' className='border-0'>
                    <GoalListItem title="Alphabet" img={alphabet} goals={alphabetGoals} />
                    <GoalListItem title="Phonics" img={phonics} goals={phonicsGoals} />
                    <GoalListItem title="Phrases" img={phrases} goals={phrasesGoals} />
                    <GoalListItem title="Stories" img={stories} goals={storiesGoals} />
                </ListGroup>
              </StatsCard>
          </Col>
          <Col md={8} className="d-flex">
              <StatsCard header={"Grade Level Growth"}>
                <div id="progress-chart" className="overflow-hidden flex-grow-1" data-show-goals="true" />
              </StatsCard>
          </Col>
      </Row>
  </>)
}