import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import Nav from "react-bootstrap/Nav"
import { buildQuery, ReadingDonuts, MathDonuts, EmergentReaderDonuts } from "@stem-sims/great-leaps-react-common"
import BeatLoader from "react-spinners/BeatLoader"
import { useModel } from '@stem-sims/nexus';
import { ProgramsType, StudentMoreInfo } from "../../shared/types/studentTypes"
import LicenseModal from "../licensing/LicenseModal"
import { SubscriptionStatus } from "../../shared/types/subscriptionTypes"

interface ProgramHeaderProps {
    initialProgram: ProgramsType
    status: SubscriptionStatus
    statusLoading: boolean
    refreshProgramStatus: () => unknown
    studentID: string
    studentName: string
    refreshDonuts: boolean
    basePath: string
    readingActive?: boolean
    mathActive?: boolean
    emergentReaderActive?: boolean
}

export default function ProgramHeader({ status, statusLoading, refreshProgramStatus, initialProgram, studentID, refreshDonuts, basePath, readingActive = true, emergentReaderActive= true, mathActive = true }: ProgramHeaderProps) {
    const programType: ProgramsType = initialProgram.charAt(0).toUpperCase() + initialProgram.slice(1) as ProgramsType
    const [selectedProgram, setSelectedProgram] = React.useState<ProgramsType>(programType)
    const [donutsAreLoading, setDonutsAreLoading] = React.useState(true)
    const [licenseModal, setLicenseModal] = React.useState<ProgramsType>(null)
    const standardVariables = buildQuery({ id: studentID })
    const hideCallback = React.useCallback(() => setLicenseModal(null), [])

    const handleTabSelect = (eventKey: ProgramsType) => {
        setSelectedProgram(eventKey);
    };

    return (<>
        <Nav
            variant="tabs"
            defaultActiveKey={programType}
            className="mb-4"
            onSelect={handleTabSelect}
            fill
        >
            <Nav.Item onClick={() => { if (!emergentReaderActive && !statusLoading) setLicenseModal("Emergent Reader") }}>
                <Nav.Link
                    eventKey="Emergent Reader"
                    as={Link}
                    to={`${basePath}/alphabet?${standardVariables}`}
                    disabled={!emergentReaderActive}
                >
                    Emergent Reader
                </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => { if (!readingActive && !statusLoading) setLicenseModal("Reading") }}>
                <Nav.Link
                    eventKey="Reading"
                    as={Link}
                    to={`${basePath}/phonics?${standardVariables}`}
                    disabled={!readingActive}
                >
                    Reading
                </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => { if (!mathActive && !statusLoading) setLicenseModal("Math") }}>
                <Nav.Link
                    eventKey="Math"
                    as={Link}
                    to={`${basePath}/addition?${standardVariables}`}
                    disabled={!mathActive}
                >
                    Math Facts
                </Nav.Link>
            </Nav.Item>
        </Nav>
        {(statusLoading || donutsAreLoading) &&
            <div style={{ margin: "0 auto", display: "table" }}>
                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center", height: "215px" }}>
                    <BeatLoader
                        size={15}
                        color={"#123abc"}
                        loading={true}
                    />
                </div>
            </div>
        }
        {selectedProgram === "Math" && <>
            <MathDonuts
                studentID={studentID}
                refresh={refreshDonuts}
                onLoaded={() => setDonutsAreLoading(false)}
            />
            <div className="py-2" />
        </>}
        {selectedProgram === "Emergent Reader" && <>
            <EmergentReaderDonuts
                studentID={studentID}
                refresh={refreshDonuts}
                onLoaded={() => setDonutsAreLoading(false)}
            />
            <div className="py-2" />
        </>}
        {selectedProgram === "Reading" &&
            <ReadingDonuts
                studentID={studentID}
                refresh={refreshDonuts}
                onLoaded={() => setDonutsAreLoading(false)}
            />
        }

        <LicenseModal
            program={licenseModal}
            status={status}
            studentId={studentID}
            onHideCallback={hideCallback}
            assignCallback={refreshProgramStatus}
        />
    </>)
}
