import React from 'react'
import Container from 'react-bootstrap/Container'
import isemail from 'isemail'
import * as organizationInvite from '../shared/routes/organizationInviteRoutes'
import * as usernameAvailable from '../shared/routes/usernameAvailableRoutes'
import Selection from './forms/Selection'
import Form from './forms/Form'
import SubmitButton from './forms/SubmitButton'
import message from './message/message'
import redirect from './redirect/redirect'
import StudentLimitSelection from './organization/StudentLimitSelection'
import * as organizationUsers from '../shared/routes/organizationUsersRoutes'
import { Group } from '../shared/types/orgUserTypes'
import CommonContent from '../context/Common'
import Common from '../context/Common'

const KEYS = {
    ENTER     : 13,
    TAB       : 9,
    BACKSPACE : 8,
    SPACE     : 32
}

class Entry extends React.Component<{ entry: string, hasError: (entry: string) => void, removeEntry: (entry: string) => void }, { hasError: boolean }> {
    
//declare context: React.ContextType<typeof CommonContent> // Typescript and Node 10 aren't supporting declare somehow. All solutions require editing babel config.

    state = {
        hasError: false
    }

    componentDidMount() {
        let { entry } = this.props
        //check to see if entry is a valid username
        usernameAvailable.get(entry)
        .then((available) => {
            //if it's not valid and not an email throw an error
            if (available && !isemail.validate(entry)) {
                this.setState({ hasError: true })
                this.props.hasError(entry)
            }
        })
    }

    render() {
        let stylePrams = { display: 'inline', backgroundColor: '#f3f3f3', color: 'rgba(0, 0, 0, 0.6)', margin: '3px', padding: '4px', borderRadius: '5px', fontSize: '1.2rem' }

        if (this.state.hasError) {
            stylePrams['backgroundColor'] = 'rgba(200, 0, 0, 0.5)'
        }

        return (
            <li style={stylePrams} className="float-left" >
                {this.props.entry}
                <span style={{ marginLeft: '0.8rem', cursor: 'pointer' }} onClick={() => this.props.removeEntry(this.props.entry) }>
                ×
                </span>
            </li>
        )
    }
}

interface ReactMultiSelectProps {
    entries: string[],
    validateEmail: (entries: string) => Promise<boolean>,
    setEntries: (entries: string[]) => void,
    setError: (error: boolean) => void
}

interface ReactMultiSelectState {
    entries: string[],
    errorEntries: string[]
}


class ReactMultiSelect extends React.Component<ReactMultiSelectProps, ReactMultiSelectState> {
    state = {
        entries : [],
        errorEntries: []
    }

    inputRef : React.RefObject<HTMLInputElement> = React.createRef()

    componentDidMount() {
        this.setState({ entries: this.props.entries })
    }

    /**
     * grabs what's in the input and formats it
     */
    insertEmails = () => {
        let entries = this.inputRef.current.value.split(/[ ,;]/g).filter(n => {
            return n !== '' && n !== undefined && n !== null;
        })

        let completeEntries = [ ...this.state.entries, ...entries ]
        //remove any duplicates using a set
        let set = new Set(completeEntries)

        let newEntries = Array.from(set)

        this.setState({ entries: newEntries })
        this.props.setEntries(newEntries)
        this.inputRef.current.value = ""
    }

    /*
     * Handle keys
     */

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.which) {
            case KEYS.BACKSPACE:
                //Only delete cases when input box is empty.
                //We want to be able to delete characters currently in the input box without deleting previous entries
                if (this.inputRef.current.value === "") {
                    this.removeEntry(this.state.entries[this.state.entries.length - 1])
                }
                break
            case KEYS.ENTER:
                event.preventDefault()
                break
        }
    }

    onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        switch (event.which) {
            case KEYS.TAB:
            case KEYS.SPACE:
            case KEYS.ENTER:
                this.insertEmails()
                break
        }
    }

    onPaste = (event: React.ClipboardEvent) => {
        setTimeout(() => this.insertEmails()) // onPaste is triggered BEFORE text is pasted, so timeout waits until after text is pasted.
    }

    /*
     * Methods for Entry component
     */

    hasError = (entry: string) => {

        //only add error if entry hasn't been removed yet
        if (this.state.entries.indexOf(entry) !== -1) {
            this.setState({ errorEntries: [ ...this.state.errorEntries, entry ] })
        }

    }

    removeEntry = (entry: string) => {
        let entriesRemaining = this.state.entries.filter((_entry) => {
            return entry !== _entry
        })

        let entriesErrorsRemaining = this.state.errorEntries.filter((_entry) => {
            return entry !== _entry
        })

        this.setState({ entries: entriesRemaining, errorEntries: entriesErrorsRemaining })
        this.props.setEntries(entriesRemaining)
    }

    render() {
        const { entries, errorEntries } = this.state
        this.props.setError(errorEntries.length > 0)
        
        return (
            <React.Fragment>
                <div
                    className="customTextBox p-0"
                    onClick={() => { this.inputRef.current.focus() }}>
                    <ul style={{ padding: '5px' }}>
                        {entries.map((entry) => {
                            return (
                                <Entry entry={entry} hasError={this.hasError} removeEntry={this.removeEntry} />
                            )
                        })}
                        <input
                            className="w-100 mt-2"
                            style={{ outline: 'none', border: 'none' }}
                            ref={this.inputRef}
                            type="text"
                            placeholder="Enter Emails or Usernames"
                            onKeyDown={this.onKeyDown}
                            onKeyUp={this.onKeyUp}
                            onPaste={this.onPaste}
                        />
                    </ul>
                </div>

                <div className="col-6 offset-3">
                    { errorEntries.length > 0 ?
                        <p style={{ 'color': 'rgb(220, 53, 69)' }}>
                            The entered username{errorEntries.length > 1 ? "s" : ""} or email{errorEntries.length > 1 ? "s" : ""} "{errorEntries.join(', ')}" {errorEntries.length > 1 ? "are" : "is"} not a valid email or username.
                        </p>
                    
                    : "" }
                </div>
            </React.Fragment>
        )
    }
}

export default class InviteInstructors extends React.Component<
{
    redirectUrl: string
},{ 
    entries: string[]
    groups: Group[]
    hasError: boolean
    loading: boolean
    permission: string
}> {

    state = {
        entries: [],
        groups: [],
        hasError: false,
        loading: true,

        permission: "Instructor"
      };

    static contextType = Common

    componentDidMount() {
        organizationUsers.get()
        .then((users) => {
            this.setState({ groups: users.groups ?? [], loading: false })
        })
        this.setState({
            permission: this.context["currentOrg"]?.permissions
        })
    }

    submit = (values) => {
        let studentLimit = values['Student Limit'].value === 'No Limit' ? null : values['Student Limit'].value
        let group = values['Group'].value === 'No Group' ? null : values['Group'].value
        return organizationInvite.post({
            entries: this.state.entries,
            permission: values['Permission'].value,
            studentLimit: studentLimit,
            group: group
        }).then((response) => {
            redirect.send(this.props.redirectUrl, this.props, () => {
                message.success("You have successfully invited instructors to the organizations.")
            })
        }).catch((err) => {
            return message.error(err)
        })
    }

    render() {
        const { entries, hasError, loading, groups } = this.state;

        if(!this.context['currentOrg']) redirect.send(this.props.redirectUrl, this.props) // refreshing causes currentOrg to be undefined
        else var userPermissions = this.context['currentOrg']['permissions']

        return (
            <Container>
                <h1 className='text-center mt-4'>Invite Users to your Organization</h1>
                <p className='py-4 text-center'>
                    <span className="lead">Please enter usernames or email addresses to invite users into your organization.</span>
                    <br/>
                    If an email address is not registered, then an email will be sent asking them to sign up for an account.
                </p>
                <Form submit={this.submit}>
                    { submitting => {
                        return (
                            <React.Fragment>
                                <Selection
                                    name="Permission"
                                    longName="Permission"
                                    submitting={submitting || loading}
                                    className="d-none"
                                    
                                    options={["Instructor", "Manager", "Licensee"].filter((permission) => {
                                        //Managers should not be able to delegate Licensee's
                                        if (permission === "Licensee" && userPermissions === "Manager") {
                                            return false
                                        }

                                        //Managers can only be in groups, therefore if there are no groups then managers can't be an option
                                        if (permission === "Manager" && groups.length === 0) {
                                            return false
                                        }

                                        return true
                                    })}
                                    onChange={(permission: string) => {
                                        this.setState({ permission: permission })
                                    } }/>
                                
                                <Selection
                                    name="Group"
                                    longName="Group"
                                    className="d-none"
                                    submitting={submitting || loading || groups.length === 1 || this.state.permission === "Licensee"}
                                    options={ this.state.permission === "Licensee" ? ["No Group"] : groups.filter((group) => {
                                        //filter out the default group if manager is selected
                                        if (group.name === "Default" && this.state.permission === "Manager") {
                                            return false
                                        }
                                        
                                        return true
                                    }).map((group) => {
                                    
                                    if (group.name !== "Default") {
                                        return group.name
                                    }
                                    else {
                                        return "No Group"
                                    }
                                })} />
                                <StudentLimitSelection
                                    submitting={submitting || loading || this.state.permission === "Licensee" || this.state.permission === "Manager"}
                                    className="d-none" 
                                />
                                <div className="content-block-small mb-3 m-auto">
                                    <label htmlFor = "username" className = "control-label bold">To:</label>
                                    <div className = "">
                                        <ReactMultiSelect
                                            entries={[]}
                                            setEntries={(_entries) => {
                                                this.setState({ entries: _entries })
                                            }}
                                            validateEmail={() => Promise.resolve(true)}
                                            setError={(error) => {
                                                if (error !== this.state.hasError) {
                                                    this.setState({ hasError: error })
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className='d-flex'>
                                        <div className='text-muted flex-1'>
                                            <p>Enter many emails or usernames by separating each with a comma <br/>(i.e. username1, username2).</p>
                                        </div>
                                        <div className="text-end">
                                            <SubmitButton submitting={submitting} disabled={entries.length === 0 || hasError || loading}>Invite</SubmitButton>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }}
                </Form>
            </Container>
        )
    }
}

InviteInstructors.contextType = CommonContent
