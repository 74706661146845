import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import Col from "react-bootstrap/Col"
import { initializeFormValidation } from "../../../../helpers/forms/form-validation"
import message from "../../../../components/message/message"
import redirect from "../../../../components/redirect/redirect"
import getURLParameters from "../../../../helpers/getURLParams"
import ClipLoader from "react-spinners/ClipLoader"
import passwordValidator from "../../../../validators/passwordValidator"
import { modelRequest } from '@stem-sims/nexus'

export default function ResetPassword(props) {
    var params = getURLParameters(props)
    React.useEffect(() => {
        initializeFormValidation()
        modelRequest.get("/api/account/recover", {
            params: { code: params.code }
        }).then((data) => {
        }).catch(() => {
            redirect.send("/account/log-in/teacher", props, () => {
                message.error("We weren't able to reset your password. Please try again.")
            })
        })
    }, [params.code, props])
    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassowrd: ""
        },
        validationSchema: Yup.object().shape({
            newPassword: passwordValidator()
        }),
        onSubmit: (values) => {
            const GENERIC_ERROR = "There has been a problem with your request. Please try again."
            return modelRequest.put("/api/account/recover", {
                code: params.code,
                password: values.newPassword
            })
                .then((response) => {
                    return redirect.send("/account/log-in/teacher", props, () => {
                        message.success("Your password is now changed! Please login to access your account.")
                    })
                })
                .catch((err) => {
                    message.error(err ?? GENERIC_ERROR)
                })
        }
    })
    return (
        <Col lg={10} className='align-self-center align-items-center text-center'>
            <h1 className="section-header">Reset Your Password</h1>

            <form
                className="form-horizontal form-validate"
                noValidate
                method="post"
                onSubmit={formik.handleSubmit}
            >
                <div className="mb-3 row">
                    <label htmlFor="password" className="d-none d-sm-inline col-sm-3 control-label text-end">Password</label>

                    <div className="col-sm-6">
                        <input
                            type="password"
                            className={`form-control string ${formik.touched.newPassword && !!formik.errors.newPassword && "is-invalid"}`}
                            disabled={formik.isSubmitting}
                            id="password"
                            name="newPassword"
                            placeholder="Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoFocus
                        />
                        <div className="invalid-feedback" style={{ fontSize: "16px" }}>
                            {formik.errors.newPassword}
                        </div>
                    </div>
                </div>

                <div className="mb-3 row">
                    <label htmlFor="password-confirmation" className="d-none d-sm-inline col-sm-3 control-label text-end">Confirm&nbsp;Password</label>

                    <div className="col-sm-6">
                        <input
                            type="password"
                            className="form-control confirmation"
                            disabled={formik.isSubmitting}
                            id="password-confirmation"
                            name="password-confirmation"
                            placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            data-confirms="password"
                            data-description="confirm your new password"
                            data-type="passwords"
                            required
                        />
                    </div>
                </div>

                <button
                    type="submit"
                    className="btn btn-secondary btn-lg"
                    disabled={formik.isSubmitting}>{formik.isSubmitting ?
                        <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={formik.isSubmitting}
                        /> : "Reset Password"}</button>
            </form>
        </Col>
    )
}
