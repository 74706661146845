import { modelRequest } from '@stem-sims/nexus'
import { ProbeList } from '../types/probeListTypes'
import { GetParameters } from '../types/probeListTypes'

export const HIGH_PROBE = "high"
export const MIDDLE_PROBE = "middle"
export const ELEMENTARY_PROBE = "elementary"

export function isStoryType(type: string) {
    return type === HIGH_PROBE || type === MIDDLE_PROBE || type === ELEMENTARY_PROBE
}

export function get({ studentID, type }: GetParameters): Promise<ProbeList> {
    return modelRequest.get("/api/probe-list", {
        params: {
            studentID: studentID,
            type: type
        }
    })
}
