import React from "react"
import { Link } from "react-router-dom"
import buildQuery from "../../helpers/buildQuery"
import { EmergentReaderSubjects } from "../../shared/types/emergentReaderTypes"

interface ProbeGroupHeaderParameters {
    studentID: string,
    active: EmergentReaderSubjects,
    suggestedPhonicsType: "phonics" | "alphabet",
    sectionClick?: () => void,
}

export default function EmergentReaderGroupHeader({ studentID, active, suggestedPhonicsType, sectionClick = () => { } }: ProbeGroupHeaderParameters) {
    //todo: this seems to be adding an & to the first entry
    const standardVariables = buildQuery({ id: studentID })
    return <div className="row subheader-text">
            <Link
                to={`/probes/alphabet?${standardVariables}`}
                className={`col-12 col-md-3 col-lg-3 offset-0 offset-md-2 btn btn btn-secondary btn-lg ${active === "alphabet" ? "active" : ""}`}
                onClick={sectionClick}>
                Alphabet
            </Link>

            <Link
                to={`/probes/awareness?${standardVariables}`}
                className={`col-12 col-md-3 col-lg-3 offset-0 offset-md-2 btn btn btn-secondary btn-lg ${active === "awareness" ? "active" : ""}`}
                onClick={sectionClick}>
                Sound Awareness
            </Link>
    </div>
}
