import React from 'react'
import LoginContext from '../../../context/LoginContext'
import CustomerLogin from './CustomerLogin'

interface Props {
    setAuthViewState: (state: boolean) => void
    licenses: {}
}

function LoginModal(props: Props) {
    const handleAfterLogin = () => {
        window.scrollTo(0, document.body.scrollHeight)
    }

    return (
        <LoginContext.Provider value={{ handleAfterLogin }}>
            <div className='d-flex flex-column justify-content-center text-center mt-4'>
                    <h1 className='section-header'>Log in to Checkout</h1>
                <CustomerLogin useToast={true} signUpLink={<p className='cursor-pointer' onClick={() => props.setAuthViewState(true)}>Don't have an account? <strong><span className='link-primary'>Sign up here</span> to proceed with your checkout!</strong></p>} />
            </div>
        </LoginContext.Provider>
    )
}


export default LoginModal
