import React from 'react'
import StudentModal from './StudentModal'
import { useRoster } from './StudentsContext'
import { FormikControl, modelRequest } from '@stem-sims/nexus'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'

type EditGroupProps = {
    show: boolean
    groups: string[]
}

function EditGroupForm({ groups }: { groups: string[] }) {
    const roster = useRoster()
    return (<>
        <div className="row subheader-text">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                <p>Select the group to assign to the students.</p>
            </div>
        </div>

        <div className="mb-3 row">
            <Form.Label className="d-inline-block col-sm-3 text-sm-end">Group</Form.Label>
            <Col sm={8} className="d-inline-block">
                <InputGroup>
                    <FormikControl defaultValue="" as="select" id="group" name="group">
                        {groups.map((group) => (
                            <option value={group} key={group}>{group}</option>
                        ))}
                    </FormikControl>
                </InputGroup>
            </Col>
        </div>

        <div className="mb-3 row">
            <label className="text-center-xs bold-xs col-sm-3 control-label text-end">Student{roster?.selectedStudents.length > 1 ? "s" : ""}</label>
            <div className="col-sm-6">
                {roster.selectedStudents.map((student) => {
                    return (<div key={student.id}>
                        <p className="form-control-static text-start text-center-xs break-words" >{student.name}</p>
                    </div>)
                })}
            </div>
        </div>
    </>)
}

export default function EditGroup(props: EditGroupProps) {
    const roster = useRoster()
    const editGroup = async () => {
        const newGroup = document.getElementById("group")["value"]
        await Promise.all(roster.selectedStudents.map((student) => {
            const programs = student.licenses?.map((license) => license.program) ?? []
            student["group"] = newGroup
            return modelRequest.post('/api/students/edit', { student: student, programs: programs })
        }))
        return { message: "Assigned group" }
    }
    return (<>
        <StudentModal show={props.show} hide={roster.hideModals} action="Assign Group" submitAction={editGroup}>
            <EditGroupForm groups={props.groups} />
        </StudentModal>
    </>)
}
