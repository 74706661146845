import React from "react"
import Image from "react-bootstrap/Image"
import { Button, Modal } from "react-bootstrap"
import flipperLicense from "../../content/images/flipper_license.svg"
import PurchaseModal from "./PurchaseModal"
import { ProgramsType, StudentMoreInfo } from "../../shared/types/studentTypes"
import { SubscriptionStatus } from "../../shared/types/subscriptionTypes"
import { toast } from "react-toastify"
import * as studentsModel from '../../shared/routes/studentRoutes'

interface Props {
    program: ProgramsType
    status: SubscriptionStatus
    studentId: string
    extraText?: string
    assignCallback: () => void
    onHideCallback: () => void
}

export default function LicenseModal({ extraText, program, status, onHideCallback, studentId, assignCallback }: Props) {
    const [purchaseModal, setPurchaseModal] = React.useState<ProgramsType>(null)
    const [licenseModal, setLicenseModal] = React.useState<ProgramsType>(null)
    const [licenseAmount, setLicenseAmount] = React.useState<number>(0)
    const [student, setStudent] = React.useState<StudentMoreInfo>()

    React.useEffect(() => {
        if (!studentId) return
        studentsModel.getOne(studentId).then((studentRes) => {
            setStudent(studentRes)
        })
    }, [studentId])

    React.useEffect(() => {
        if (program) {
            const licensesAvailable = status[(program === "Emergent Reader") ? "emergentReader" : program.toLowerCase()].available
            setLicenseAmount(licensesAvailable)
            if (licensesAvailable === 0) {
                setPurchaseModal(program)
            } else {
                setLicenseModal(program)
            }
        } else {
            setPurchaseModal(null)
            setLicenseModal(null)
        }
    }, [program])

    const assignLicense = React.useCallback(async () => {
        // if they have license but this modal is triggered, its been archived
        const hasArchivedLicense = student.licenses.find((license) => license.program === program)
        if (hasArchivedLicense) {
            toast.error("This license has been archived.")
            setLicenseModal(null)
            return
        }
        const newLicenses = [program, ...student.licenses.map((license) => license.program)]
        try {
            await studentsModel.updateOne(student, newLicenses)
            toast.success("License Assigned!")
            setLicenseModal(null)
            assignCallback()
        } catch (err) {
            toast.error(err ?? "There has been an error")
        }
    }, [student, program, assignCallback])

    return <>
        <Modal show={!!licenseModal} onHide={() => {
            setLicenseModal(null)
            onHideCallback()
        }}>
            <Modal.Header closeButton>
                Assign License
            </Modal.Header>
            <Modal.Body className="text-center">

                {extraText && <p className="text-start">{extraText}</p>}

                <p>You currently have {licenseAmount} {program} license(s) to assign</p>
                <div className="my-3">
                    <Image src={flipperLicense} className="mb-3" />
                    <div className="bg-light-blue d-inline-block rounded px-4">
                        <span>1 {program} License</span>
                        <i className="fa fa-arrow-right gl-blue p-3"></i>
                        <span>{student?.name}</span>
                    </div>
                </div>
                <p className="my-4">
                    Would you like to assign a <strong>{program} License</strong>  to <strong>{student?.name}?</strong>
                </p>
                <Button onClick={() => {
                    setLicenseModal(null)
                    onHideCallback()
                }} variant="secondary" className="mx-3">Cancel</Button>
                <Button onClick={assignLicense} size="sm" className="mx-3">Assign License</Button>
            </Modal.Body>
        </Modal>

        <PurchaseModal extraText={extraText} modalType={purchaseModal} setModalType={(type) => {
            setPurchaseModal(type as ProgramsType)
            onHideCallback()
        }} />
    </>

}
