import { modelRequest } from '@stem-sims/nexus'
import { SuggestedResponse } from '../types/suggestedTypes'

export function get({studentID}: { studentID: string }): Promise<SuggestedResponse> {
    return modelRequest.get("/api/suggested", {
        params: {
            studentID: studentID
        }
    })
}
