import React from 'react'
import BeatLoader from "react-spinners/BeatLoader"
import { Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { Stories } from '@stem-sims/great-leaps-react-common'
import getURLParameters from '../../helpers/getURLParams'
import buildQuery from '../../helpers/buildQuery'
import Container from 'react-bootstrap/Container'
import { MatchParams } from '../../shared/types/probeListTypes'
import message from '../../components/message/message'
import { modelRequest } from '@stem-sims/nexus'

interface Props extends RouteComponentProps<MatchParams> {
    type: string
    showButtons?: boolean
}

class StoriesDOK extends React.Component<Props>
{
    state = {
        levels: [],
        story: {
            responses: [],
            title: "",
            indentedParagraphs: false
        }
    }
    componentDidMount() {
        var params = getURLParameters(this.props)
        modelRequest.get('/api/dok', {
            params: {
                type: this.props.type,
                number: this.props.match.params.probeNumber
            }
        }).then((response) => {
            this.setState({ levels: response })
        }).catch((err) => {
            message.error(err)
        })

        modelRequest.get('/api/probe', {
            params: {
                type: this.props.type,
                number: this.props.match.params.probeNumber,
                studentID: params.id
            }
        }).then((response) => {
            this.setState({ story: response })
        }).catch((err) => {
            message.error(err)
        })
    }

    render() {
        var { type } = this.props
        const showButtons = this.props.showButtons ?? true
        var { levels, story } = this.state
        var params = getURLParameters(this.props)
        const standardVariables = buildQuery({ id: params.id })
        return (
            <React.Fragment>
                <Container className="subheader-text">
                    <h2>Comprehension Development Questions</h2>

                    <div className="text-start">

                        <h2>Activities</h2>
                        <ol type="A">
                            <li>After the first read, ask your student to retell the story in their own words. Repeat this when a student completes a story for the first time.</li>
                            <li>Give your student the option to draw a part of the story on their own time and share it with you. This is meant to be fun, not homework, and we'd love to see their pictures! Send them over to <a href="mailto:glinfo@greatleaps.com">glinfo@greatleaps.com</a>, and we may share with our community!</li>
                            <li>When the student makes the goal, have them create two new questions to ask you. Answer them in depth and model for the student the in depth answers we expect to see from them. Then, use it to lead a short conversation based on the student's question.</li>
                        </ol>

                        <h2>Questions</h2>
                        {levels.length === 0 ?
                            <div style={{ margin: "0 auto", display: "table", height: "40vh" }}>
                                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                                    <BeatLoader
                                        size={15}
                                        color={"#123abc"}
                                        loading={true}
                                    />
                                </div>
                            </div>
                            :
                            levels.map((level) => {
                                return (
                                    <React.Fragment>
                                        <h3>Level {level.level}</h3>
                                        <ol>
                                            {level.questions.map((question) => {
                                                return <li>{question.question}</li>
                                            })}
                                        </ol>
                                    </React.Fragment>)
                            })
                        }
                    </div>
                    {showButtons && <>
                        <div className="row">
                            <button type="button" className="btn btn-info float-left" data-toggle="collapse" data-target="#story-review">
                                <i className="fas fa-info" style={{ "paddingRight": "10px" }}></i>
                                Review Exercise
                            </button>
                        </div>
                        <div className="row">
                            <div id="story-review" className="collapse">
                                <div className="card-body text-justify">
                                    {levels.length > 0 && <Stories exerciseNumber={this.props.match.params.probeNumber} title={story.title} responses={story.responses} indentedParagraphs={story.indentedParagraphs} hideGhosting={true} />
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="subheader-text row">
                            <div className="col-sm-6">
                                <Link to={`/students/records/stories/${type}?${standardVariables}`} target="_blank" className="btn btn-secondary btn-lg">View Student's Records</Link>
                            </div>

                            <div className="col-sm-6">
                                <Link to="/dashboard" className="btn btn-secondary btn-lg">Next Student</Link>
                            </div>
                        </div>
                    </>}
                </Container>
            </React.Fragment>

        )
    }
}

export default StoriesDOK
