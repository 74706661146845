import { modelRequest } from "@stem-sims/nexus"

/**
 * Create an organization
 * 
 * @param name name of org
 */
export function post(name: string) : Promise<{ status: number, message: string }> {
    return modelRequest.post('/api/organizations', {
        name: name
    })
}

/**
 * Gets all organizations that a user is in
 */
export function get() : Promise<{ name: string, groups: string[], id: string, personal: "Yes" | "No" }[]> {
    return modelRequest.get('/api/organizations')
}
