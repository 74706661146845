import { levels } from '../shared/records'
import { getWeeklySessionCounts } from '../shared/routes/recordRoutes'
var d3 = require('d3')
var moment = require('moment')
// set default graph parameters 
let graph = {
	margin: {top: 20, right: 30, bottom: 80, left: 65},
	aspectRatio: 14/7,
	separation: 10,
	pointSize: 2.5
}
const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24
var xAxisGraphics = null
var yAxisGraphics = null
var controlButtons = null

d3.scaleLogisticLinearLog = function() {
	var domain = [0, 2];
	var range = [0, 2];
	var transition = 1;
	var steepness = 20;
	
	
	const unitStep = function(input) {
		return 1 / (1 + Math.exp(-steepness * (Math.abs(transition) / transition * (input - transition))));
	}
	
	const log = function(input)
	{
		return (range[1] - range[0]) / Math.log(2 * domain[1] / transition) * Math.log(2 * input / transition + Number.MIN_VALUE) + range[0];
	}
	
	const linear = function(input)
	{
		return (log(transition) - range[0]) / transition * input + range[0];
	}
	
	const output = function(input)
	{
		var unitStepValue = unitStep(input);
		
		return unitStepValue * (log(input)) + (1 - unitStepValue) * (linear(input));
	}
	
	
	const scale = function(input)
	{
		return output(input);
	}
	
	
	scale.copy = function()
	{
		var scaleCopy = d3.scaleLogisticLinearLog();
		
		scaleCopy.domain(domain);
		scaleCopy.range(range);
		scaleCopy.steepness(steepness);
		
		return scaleCopy;
	}
	
	
	scale.domain = function(newDomain) {
		if (arguments.length === 0)
		{
			return domain;
		}
		
		domain[1] = newDomain[1];
	}
	
	scale.range = function(newRange) {
		if (arguments.length === 0)
		{
			return range;
		}
		
		range = newRange;
	}
	
	
	scale.steepness = function(newSteepness)
	{
		if (arguments.length === 0)
		{
			return steepness;
		}
		
		steepness = newSteepness;
	}
	
	
	return scale;
}

// draw lines between 2 points
function connectPoints(container,point1,point2, lineClass="response") {
		var responsesPath = container.append("path");
		responsesPath.datum([point1, point2]);
		responsesPath.attr("class", `${lineClass} line`);
		responsesPath.attr("d", graph.responsesLine);
		return responsesPath
}

function createArrows(container, unscaledLine) {
	var controlButtons = container.append("g");
	controlButtons.attr("id", "control-buttons");

	graph.arrowHeight = 16;
	var arrowTailWidth = 1.4 * graph.arrowHeight;
	var arrowHeadWidth = 1/3 * arrowTailWidth;

	graph.rectangleWidth = 1.8 * arrowTailWidth;
	var rectangleHeight = 1.75 * graph.arrowHeight;

	var arrowHeadPoints = [
		{"x": arrowHeadWidth,	"y": -graph.arrowHeight / 2},
		{"x": 0,				"y": 0},
		{"x": arrowHeadWidth,	"y": graph.arrowHeight / 2}
	];

	var arrowTailPoints = [
		{"x": 0,				"y": 0},
		{"x": arrowTailWidth,	"y": 0},
	];

	var leftArrow = controlButtons.append("g");
	leftArrow.attr("id", "left-arrow");
	leftArrow.attr("class", "control-button");

	var leftArrowRectangle = leftArrow.append("rect");
	leftArrowRectangle.attr("class", "rectangle");
	leftArrowRectangle.attr("rx", 3);
	leftArrowRectangle.attr("ry", 3);
	leftArrowRectangle.attr("x", 0);
	leftArrowRectangle.attr("y", 0);
	leftArrowRectangle.attr("width", graph.rectangleWidth);
	leftArrowRectangle.attr("height", rectangleHeight);

	var leftArrowArrow = leftArrow.append("g");
	leftArrowArrow.attr("transform", "translate(" + (graph.rectangleWidth / 2 - arrowTailWidth / 2) + ", " + (rectangleHeight / 2) +")");

	var leftArrowArrowHead = leftArrowArrow.append("path");
	leftArrowArrowHead.datum(arrowHeadPoints);
	leftArrowArrowHead.attr("d", unscaledLine);
	leftArrowArrowHead.attr("class", "line");

	var leftArrowArrowTail = leftArrowArrow.append("path");
	leftArrowArrowTail.datum(arrowTailPoints);
	leftArrowArrowTail.attr("d", unscaledLine);
	leftArrowArrowTail.attr("class", "line");


	var rightArrow = leftArrow.node().cloneNode(true);
	rightArrow.id = "right-arrow";
	rightArrow.setAttribute("transform", "scale(-1, 1)");
	controlButtons.node().appendChild(rightArrow);
}
/**
 * @abstract creates a d3 line from every object in chart data
 */
function createLine(getDataX, getDataY) {
	let line = d3.line();
	line.x(function(data)
		{
			return getDataX(data);
		}
	);
	line.y(function(data)
		{
			return getDataY(data);
		}
	);
	return line
}


function initTime(start,end,days=42) {
	graph.daysPerGraph = days;
	graph.daysPerGroup = graph.daysPerGraph / 2; // this is being used to move the graph half way when shifting
	const startDate = new Date(start + "T12:00:00Z");
	graph.currentStartDate = new Date(startDate.getTime());
	let endDate = new Date(end + "T12:00:00Z")
	// move end date on graph to next sunday
	endDate.setDate(endDate.getDate() + (0+(7-endDate.getDay())) % 7) // change 0 to whatever day of week you want (0 = sunday)
	graph.endTime = endDate.getTime();
	graph.startTime = startDate.getTime();
	const daysDiff = dateDiff(startDate, endDate)

	if (daysDiff > (graph.daysPerGraph)) {
		// move start date forward to final set of days
		graph.currentStartDate.setTime((graph.endTime) - (days * MILLISECONDS_PER_DAY));
	}

	graph.currentStartTime = graph.currentStartDate.getTime();

	const formattedEndDate = new Date(graph.currentStartTime + (graph.daysPerGraph * MILLISECONDS_PER_DAY));
	graph.currentEndDate = new Date(formattedEndDate.getTime());
	graph.currentEndTime = graph.currentEndDate.getTime();

	graph.xScale = d3.scaleTime();
	graph.xAxis = d3.axisBottom(graph.xScale);
	graph.xAxis.tickFormat(d3.timeFormat("%b %d"));
}

async function fidelityShading(chart,yDomain,roundedDateLine,studentID) {
	let currentSunday = moment().day(0).hour(0).minute(0).second(0).toDate();
	const sundaysInRange = []
	while (currentSunday.getTime() >= graph.startTime) {
		let sundayCopy = new Date(currentSunday) // so setTime doesn't edit every sunday in array
		sundaysInRange.push(sundayCopy)
		currentSunday.setTime(currentSunday.getTime() - 7 * MILLISECONDS_PER_DAY)
	}

	const weeklySessionCounts = await getWeeklySessionCounts(studentID)

	await Promise.all(sundaysInRange.map(async (sunday) => {

		const daysInPast = (time,days) => new Date(time - days * MILLISECONDS_PER_DAY)

		const weekAgo = daysInPast(sunday.getTime(),7) // 1 week ago
		const sessionCount = weeklySessionCounts[weekAgo.toISOString().split("T")[0]] ?? 0
		const fidelityNotFollowed = sessionCount < 3
		const colors = ['rgb(253,94,83,0.2)', 'rgb(253,94,83,0.5)'];
	
		let grad = chart.append('defs')
			.append('linearGradient')
			.attr('id', 'grad')
			.attr('x1', '0%')
			.attr('x2', '0%')
			.attr('y1', '0%')
			.attr('y2', '100%');

		grad.selectAll('stop')
			.data(colors)
			.enter()
			.append('stop')
			.style('stop-color', function(d){ return d; })
			.attr('offset', function(d,i){
				return 100 * (i / (colors.length - 1)) + '%';
			})

		if(fidelityNotFollowed) {
			chart.append("rect")
			.datum([{ date: weekAgo }, { date: new Date(sunday) }])
			.attr('class', 'current-week-rect')
			.attr('y', 0)
			.attr('opacity', 0.5)
			.attr('fill', 'url(#grad)')
			.attr('pointer-events', 'none')
			.attr("clip-path", "url(#chart-area)");
		}
	}))
}

function addWeekLines(chart,yDomain,roundedDateLine) {
		let currentSunday = moment().add(1, 'weeks').day(0).hour(0).minute(0).second(0).toDate();
		const sundaysInRange = []
		while (currentSunday.getTime() >= graph.startTime) {
			let sundayCopy = new Date(currentSunday) // so setTime doesn't edit every sunday in array
			sundaysInRange.push(sundayCopy)
			currentSunday.setTime(currentSunday.getTime() - 7 * MILLISECONDS_PER_DAY)
		}
		sundaysInRange.map((sunday) => {
			const sundayDatum = [
				{"date": sunday, "y": yDomain[0]},
				{"date": sunday, "y": yDomain[1]}
			];
			const sundayPath = chart.append("path");
			sundayPath.datum(sundayDatum);
			sundayPath.attr("d", roundedDateLine);
			sundayPath.attr("class", "sunday line dashed");
				
			const colors = ['rgb(253,94,83,0.2)', 'rgb(253,94,83,0.5)'];
		
			let grad = chart.append('defs')
				.append('linearGradient')
				.attr('id', 'grad')
				.attr('x1', '0%')
				.attr('x2', '0%')
				.attr('y1', '0%')
				.attr('y2', '100%');
	
			grad.selectAll('stop')
				.data(colors)
				.enter()
				.append('stop')
				.style('stop-color', function(d){ return d; })
				.attr('offset', function(d,i){
					return 100 * (i / (colors.length - 1)) + '%';
				})
		const thisSunday = moment().day(0).hour(0).toDate()
		const nextSunday = moment().add(1,"weeks").day(0).hour(0).toDate()
		// move end date on graph to next sunday
		if (moment(sunday).isSame(new Date(),'week')) { // make current week grey
			chart.append("rect") // moment().add(1, 'weeks').day(0) is getting first sunday of next week
			.datum([{ date: thisSunday }, { date: nextSunday }])
			.attr('class', 'current-week-rect')
			.attr('y', 0)
			.attr('opacity', 0.08)
			.attr('fill', 'black')
			.attr('pointer-events', 'none')
			.attr("clip-path", "url(#chart-area)");
		}
	})
}

function drawGoalLines(chart,chartGoals,yDomain) {
	var goalTimes = [];
	let count = chartGoals.length;
	for (var index = 0; index < count; index++) {
		const goalDate = new Date(chartGoals[index]["date"] + "T12:00:00Z");
		goalTimes.push(goalDate.getTime());

		var goalDatum = [
			{"date": goalDate, "y": yDomain[0]},
			{"date": goalDate, "y": yDomain[1]}
		];

		var goalLine = chart.append("path");
		goalLine.datum(goalDatum);
		goalLine.attr("class", "goal line");
		goalLine.attr("d", graph.dateLine);
	}
	return goalTimes
}

function createChartContainer() {
	var svgContainer = d3.select("#progress-chart");
	var svg = svgContainer.append("svg");
	svg.attr("id", "svg-chart");

	var container = svg.append("g");
	container.attr("transform", "translate(" + String(graph.margin.left) + ", " + String(graph.margin.top) + ")");

	var chart = container.append("g");
	chart.attr("id", "chart");

	var xAxisGraphics = chart.append("g");
	xAxisGraphics.attr("class", "x axis");

	var yAxisGraphics = chart.append("g")
	yAxisGraphics.attr("class", "y axis")
	yAxisGraphics.attr("transform", "translate(-" + String(graph.separation) + ", 0)");

	return { chart: chart, svg: svg, container: container}
}

/**
*	@abstract Set up printable graphs
*/
graph.createPrintableCharts = function() {
	var svg = document.querySelector("#progress-chart > svg");
	var printableDiv = document.getElementById("printable-charts");
	var svgContainer = d3.select("#progress-chart");
	var rightArrowMain = document.getElementById("right-arrow");

	var printChartWidth = 650;
	var labelSize = 35;

	svgContainer.style("width", printChartWidth + "px");
	graph.animate = false;
	graph.updateExerciseGraph();

	var moveRight = 0;
	while(rightArrowMain?.getAttribute("opacity") === "1"){
		graph.shiftChartRight();
		moveRight++;
	}

	var moreCharts = true;
	var totalPages = 0;

	while(moreCharts) {
		if (svg.querySelector("#chart").querySelectorAll("circle.response:not([opacity='0'])").length !== 0 || totalPages === 0) {
			var svgCopy = svg.cloneNode(true);
			printableDiv.appendChild(svgCopy);

			var leftArrow = svgCopy.querySelector("#left-arrow");
			var rightArrow = svgCopy.querySelector("#right-arrow");

			leftArrow?.parentNode.removeChild(leftArrow);
			if(rightArrow) rightArrow.parentNode.removeChild(rightArrow);

			var height = parseFloat(svgCopy.getAttribute("height"));
			svgCopy.setAttribute("height", (height - labelSize) + "px");
		}

		if (svg.querySelector("#left-arrow")?.getAttribute("opacity") === "1") graph.shiftChartLeft();
		else moreCharts = false;

		//shift it twice if possible to remove graph repeats
		if (rightArrowMain?.getAttribute("opacity") === "1") {
			graph.shiftChartLeft();
		}

		if (totalPages >= 30) break; //worst case to prevent infinite loop
		totalPages++;
	}
	while(rightArrowMain?.getAttribute("opacity") === "1" && (totalPages - moveRight) > 0) {
		graph.shiftChartRight();
		totalPages--;
	}

	svgContainer.style("width", "");
	graph.updateExerciseGraph();
	graph.animate = true;
}

graph.filterInactivePaths = function(data) {
	return graph.filterInactivePoints(data[0]);
}
graph.filterInactivePoints = function(data) {
	
	if(!(data["date"] instanceof Date)) data["date"] = new Date(data["date"])
	var currentTime = data["date"].getTime();
	return currentTime < graph.currentStartTime || currentTime > graph.currentEndTime;
}

graph.shiftChartLeft = function() {
	if (graph.currentStartTime <= graph.startTime)
		return;

	graph.currentStartDate.setTime(graph.currentStartTime - graph.daysPerGroup * MILLISECONDS_PER_DAY);
	graph.currentStartTime = graph.currentStartDate.getTime();

	graph.currentEndDate.setTime(graph.currentStartTime + (graph.daysPerGraph - 1) * MILLISECONDS_PER_DAY);
	graph.currentEndTime = graph.currentEndDate.getTime();

	graph.updateExerciseGraph();
}

graph.shiftChartRight = function()
{
	if (graph.currentEndTime >= graph.endTime)
		return;

	graph.currentStartDate.setTime(graph.currentStartTime + graph.daysPerGroup * MILLISECONDS_PER_DAY);
	graph.currentStartTime = graph.currentStartDate.getTime();

	graph.currentEndDate.setTime(graph.currentStartTime + (graph.daysPerGraph - 1) * MILLISECONDS_PER_DAY);
	graph.currentEndTime = graph.currentEndDate.getTime();

	graph.updateExerciseGraph();
}

graph.drawReferenceLines = function(chart, startDate, endDate) {
	var yLines = [5, 50, 100];


	for (var index = 0; index < yLines.length; index++)
	{
		var lineDatum =
		[
			{"date": startDate, "y": yLines[index]},
			{"date": endDate, "y": yLines[index]}
		];
		var path = chart.append("path");
		path.datum(lineDatum);
		path.attr("class", "light line");
		path.attr("clip-path", "url(#chart-area)")
	}
}

function updateYAxis(yAxisTicks, height, chart) {
	graph.yScale.range([height, 0]);
	const svgContainer = d3.select("#progress-chart");
	if(!svgContainer) return
	try {
		const svgLineHeight = svgContainer.style("line-height")
		var yAxisTickSeparation = 1.5 * parseFloat(svgLineHeight);
	} 
	catch {
		// svg container style function results in error. Doing this to silence error console message since this happens when changing the route sometimes
	}


	if ((graph.yScale(1) - graph.yScale(2)) < yAxisTickSeparation)
	{
		var length = yAxisTicks.length;
		for (let index = 1; index < length; index += 2)
		{
			yAxisTicks.splice(index, 1);
		}

		if ((graph.yScale(2) - graph.yScale(5)) < yAxisTickSeparation)
		{
			for (let index = 2; index < length; index++)
			{
				yAxisTicks.splice(index, 1);
			}
		}
	}

	graph.yAxis.tickValues(yAxisTicks);
	yAxisGraphics = chart.select(".y.axis");
	yAxisGraphics.call(graph.yAxis);
}

function updateXAxis(chart,width,height,days=42, chartType="grade") {
	var xAxisTicks = [];
	const xAxisTickSeparation = 50 + 2 * graph.separation;
	var xAxisTickInterval = days/6;

	const daysInFuture = (days) => new Date(graph.currentStartTime + days * MILLISECONDS_PER_DAY)

	const sixthDays = daysInFuture(Math.round(days/6)) // checks if 3 labels can fit
	const quarterDays = daysInFuture(Math.round(days/4)) // checks if 2 labels can fit (screen shouldn't ever be too small for 2 labels)
	const xScaleRange = graph.xScale.range()[0]
	// if graph is crunched its scale will be smaller, so make ticks less frequent
	 if ((graph.xScale(quarterDays) - xScaleRange) < xAxisTickSeparation) {
		xAxisTickInterval = days/2;
	}
	else if ((graph.xScale(sixthDays) - xScaleRange) < xAxisTickSeparation) {
		xAxisTickInterval = Math.round(days/3);
	}

	const currentSunday = moment(graph.currentEndDate).add(1, 'weeks').day(0).hour(0).minute(0).second(0).toDate();
	let currentIntervalTime = currentSunday
	while (currentIntervalTime.getTime() >= graph.startTime) {
		// Checks to make sure the interval time is in range of the dates and to make sure there are no repeated dates
		if (currentIntervalTime <= graph.currentEndDate && currentIntervalTime >= graph.currentStartDate && !xAxisTicks.some(date => moment(date.getTime()).format("MMM-DD-YY") == moment(currentIntervalTime.getTime()).format("MMM-DD-YY"))) {
			let currentIntervalTimeCopy = new Date(currentIntervalTime.getTime())
			// Sets all dates to have a neutral time in order to keep the date ticks the distance apart
			currentIntervalTimeCopy.setHours(0,0,0,0)
			xAxisTicks.push(currentIntervalTimeCopy);
		}
		currentIntervalTime.setTime(currentIntervalTime.getTime() - xAxisTickInterval * MILLISECONDS_PER_DAY);
	}

	// Adds and extra first and last interval tick
	let firstDate
	let lastDate
	
	if(chartType === "grade") {
		firstDate = moment(xAxisTicks[xAxisTicks.length - 1]).subtract(xAxisTickInterval, "days").toDate()
		lastDate = moment(xAxisTicks[0]).add(xAxisTickInterval, "days").toDate()
	} else {
		firstDate = moment(graph.currentStartDate).hour(0).toDate()
		lastDate = moment(graph.currentEndDate).hour(0).toDate()
	}

	graph.currentStartDate = firstDate
	graph.currentEndDate = lastDate

	xAxisTicks.unshift(firstDate)
	xAxisTicks.push(lastDate)

	graph.xScale.domain([graph.currentStartDate, graph.currentEndDate]);
	graph.xScale.range([0, width]);
	graph.xAxis.tickValues(xAxisTicks);

	xAxisGraphics = chart.select(".x.axis");
	xAxisGraphics.attr("transform", "translate(0, " + String(height + graph.separation) + ")");
	xAxisGraphics.call(graph.xAxis);
}
/**
 * @abstract determines width and height based off available space
 */
function updateGraphSize() {
	const svgContainer = d3.select("#progress-chart");

	if (graph.animate === true) var transition = svgContainer.transition("resize");
	else transition = svgContainer;

	const margin = graph.margin;
	let width = svgContainer.node()?.offsetWidth ?? 400;
	width -= margin.left + margin.right;
	const height = width / graph.aspectRatio;

	var svg = transition.select("svg");
	svg.attr("width", String(width + margin.left + margin.right) + "px");
	svg.attr("height", String(height + margin.top + margin.bottom) + "px");
	return {width: width, height: height, transition: transition}
}

/**
 * @abstract updates all of the point positions on the graph
 */
function refreshPoints(chart,width,height) {
	var responsePaths = chart.selectAll(".line.response");
	responsePaths.attr("d", graph.responsesLine);

	var errorPaths = chart.selectAll(".line.error");
	errorPaths.attr("d", graph.errorsLine);

	var otherPaths = chart.selectAll(".line:not(.response):not(.error)");
	otherPaths.attr("d", graph.dateLine);

	const currentWeekXOffset = 0;
	const currentWeekYOffset = 10;
	d3.select("#chart-area rect")
	.attr("width", width)
	.attr("height", height + currentWeekYOffset)

	chart.selectAll(".current-week-rect")
	.attr('x', (data) => { return graph.xScale(data[0].date) - currentWeekXOffset })
	.attr('width', (data) => { return (graph.xScale(data[1].date) - graph.xScale(data[0].date)) + currentWeekXOffset })
	.attr('height', height + currentWeekYOffset);


	let paths = chart.selectAll(".line");
	paths.attr("opacity", "1");

	let inactivePaths = paths.filter(graph.filterInactivePaths);
	inactivePaths.attr("opacity", "0");

	let points = chart.selectAll(".point:not(.clicker)");
	points.attr("opacity", "1");

	let responsePoints = chart.selectAll(".point.response");
	responsePoints.attr("cx", graph.responsesLine.x());
	responsePoints.attr("cy", graph.responsesLine.y());

	if(graph.errorsLine) {
		let errorPoints = chart.selectAll(".point.error");
		errorPoints.attr("cx", graph.errorsLine.x());
		errorPoints.attr("cy", graph.errorsLine.y());
	}

	if (width <= 500) {
		var newPointSize = graph.pointSize + (1.5 - graph.pointSize) / 250 * (500 - width);
		if (newPointSize < 1.5) {
			newPointSize = 1.5;
		}

		points.attr("r", newPointSize);
	}
	else {
		points.attr("r", graph.pointSize);
	}

	var inactivePoints = points.filter(graph.filterInactivePoints);
	inactivePoints.attr("opacity", "0");
}
/**
 * @abstract re-renders arrows to see if they should be visible
 */
function updateArrows(transition,width,height) {
	if(!xAxisGraphics.node()) return
	let arrowY = height + xAxisGraphics.node().getBBox().height + graph.separation;
	controlButtons = transition.select("#control-buttons");
	controlButtons.attr("transform", "translate(0, " + (arrowY + graph.arrowHeight / 2) + ")");

	let leftArrow = transition.select("#left-arrow");
	leftArrow.attr("opacity", "1");

	let leftArrowNode = leftArrow.node();
	if(leftArrowNode) leftArrowNode.addEventListener("click", graph.shiftChartLeft);

	if (leftArrowNode && graph.currentStartTime <= graph.startTime) {
		leftArrow.attr("opacity", "0");
		leftArrowNode.removeEventListener("click", graph.shiftChartLeft);
	}

	let rightArrow = transition.select("#right-arrow");
	rightArrow.attr("transform", "translate(" + width + ", 0) scale(-1, 1)");
	rightArrow.attr("opacity", "1");

	let rightArrowNode = rightArrow.node();
	if(rightArrowNode) rightArrowNode.addEventListener("click", graph.shiftChartRight);

	if (rightArrowNode && graph.currentEndTime >= graph.endTime) {
		rightArrow.attr("opacity", "0");
		rightArrowNode.removeEventListener("click", graph.shiftChartRight);
	}
}
/**
 * @abstract when grade level progress chart option is selected and graph needs to be updated
 * this function will update the graph
 */
graph.updateGradeGraph = function(startDate,endDate) {
	const yAxisTicks = [1, 2, 3, 4, 5, 6, 7];
	const { width, height, transition } = updateGraphSize()
	const chart = transition.select("#chart");

	if(startDate && endDate) {
		startDate = new Date(startDate + "T12:00:00Z");
		endDate = new Date(endDate + "T12:00:00Z");
	
		graph.currentStartDate = new Date(startDate.getTime());
		graph.currentStartTime = startDate.getTime();
	
		const formattedEndDate = new Date(endDate.getTime());
		graph.currentEndDate = new Date(formattedEndDate.getTime());
		graph.currentEndTime = graph.currentEndDate.getTime();
	}
	function datediff(first, second) {        
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}
	const daysBetween = datediff(startDate,endDate)
	updateXAxis(chart,width,height,daysBetween)
	graph.yAxis.tickFormat(d3.format(".1f")) // allow decimals on y-axis
	updateYAxis(yAxisTicks, height, chart)
	refreshPoints(chart,width,height)
}
/**
 * @abstract when exercise progress is selected and the graph needs to be updated
 * this function will update the graph
 */
graph.updateExerciseGraph = function(startDate,endDate) {
	const yAxisTicks = [1, 2, 5, 10, 20, 50, 100, 200];
	const { width, height, transition } = updateGraphSize()
	const chart = transition.select("#chart");

	updateXAxis(chart,width,height,42, "exercise")
	updateYAxis(yAxisTicks, height, chart)
	refreshPoints(chart,width,height)
	updateArrows(transition,width,height)
}
/** 
 * @abstract initializes exercise responses/errors progress graph
*/
graph.initExerciseGraph = async function(chartData, program, studentID, type="phonics") {
	const { chartResponses, chartGoals, startDate: start, endDate: end } = chartData
	let progressChart = document.getElementById("progress-chart"); //check if the chart exists
	if (progressChart == null)
		return;

	//set some constants
	graph.animate = false; //do not animate during initialization (mostly due to print graph setup)
	graph.studentID = studentID // not the best way to do this, but need this variable for update graph which listens to window resize function
	const yDomain = [0, 250];

	initTime(start,end)
	const {chart, svg, container} = createChartContainer()

	d3.max(chartResponses, function(object) {
			return object["responses"]
		}
	);

	graph.yScale = d3.scaleLogisticLinearLog()
	graph.yScale.domain(yDomain)

	graph.yAxis = d3.axisLeft(graph.yScale)

	const getX = (data) => data["x"]
	const getY = (data) => data["y"]
	const unscaledLine = createLine(getX,getY)
	
	const getYScale = (data) => graph.yScale(data["y"])
	const getDate = (data) => graph.xScale(data["date"])
	graph.dateLine = createLine(getDate, getYScale)

	const getRoundedDateX = (data) => Math.round(graph.dateLine.x()(data))
	const getRoundedDateY = (data) => Math.round(graph.dateLine.y()(data))
	const roundedDateLine = createLine(getRoundedDateX, getRoundedDateY)

	const getResponses = (data) => graph.yScale(data["responses"])
	graph.responsesLine = createLine(getDate,getResponses)

	const getErrors = (data) => graph.yScale(data["errors"])
	graph.errorsLine = createLine(getDate,getErrors)

	const goalTimes = drawGoalLines(chart,chartGoals,yDomain)

	let responses = chart.append("g");
	let errors = chart.append("g");

	let count = chartResponses.length;
	const date = new Date(chartResponses[0]?.["date"] + "T12:00:00Z");
	if(!isNaN(Date.parse(date))) chartResponses[0]["date"] = date

	var div = d3.select("body").append("div")	
    .attr("class", "graph-point-data")				
    .style("opacity", 0);

	for (let index = 0; index < count; index++) {
		var responsesCircle = responses.append("circle");
		responsesCircle.datum(chartResponses[index]);
		responsesCircle.attr("class", "response point");
		responsesCircle.attr("r", 0);
		responsesCircle.attr("cx", 0);
		responsesCircle.attr("cy", 0);

		responses.append("circle")
		.datum(chartResponses[index])
		.attr("class", "response point clicker")
		.attr("r", 10)
		.style("cursor", "pointer")
		.attr("opacity", 0)
		.on("mouseover", function(d) {
			div.transition()	
				.duration(200)
				.style("opacity", .9);
				
			div.html(`
				${moment(d.date).format("MMM D")}
				<br/> ${program === "Reading" ? "Exercise" : "Skill Set"} ${d.probeNumber}
				<br/> ${d.responses} ${program === "Reading" ? "wpm" : "ppm"}
				`)
				.style("left", (d3.event.pageX) + "px")		
				.style("top", (d3.event.pageY - 28) + "px")
			})					
		.on("mouseout", function(d) {
            div.transition()		
                .duration(500)		
                .style("opacity", 0);	
        });

		var errorsCircle = errors.append("circle");
		errorsCircle.datum(chartResponses[index]);
		errorsCircle.attr("class", "error point");
		errorsCircle.attr("r", 0);
		errorsCircle.attr("cx", 0);
		errorsCircle.attr("cy", 0);

		responses.append("circle")
		.datum(chartResponses[index])
		.attr("class", "error point clicker")
		.attr("r", 10)
		.style("cursor", "pointer")
		.attr("opacity", 0)
		.on("mouseover", function(d) {
			div.transition()	
				.duration(200)
				.style("opacity", .9);
				div.html(`
				${moment(d.date).format("MMM D")}
				<br/> ${program === "Reading" ? "Exercise" : "Skill Set"} ${d.probeNumber}
				<br/> ${d.errors} errors
				`)
				.style("left", (d3.event.pageX) + "px")		
				.style("top", (d3.event.pageY - 28) + "px")
			})					
		.on("mouseout", function(d) {
				div.transition().duration(500).style("opacity", 0);	
		});

		if (index !== (count - 1)) {
			var currentDate = chartResponses[index]["date"];
			var currentTime = currentDate.getTime();

			const nextDate = new Date(chartResponses[index + 1]["date"] + "T12:00:00Z");
			if(!isNaN(Date.parse(date))) chartResponses[index + 1]["date"] = nextDate;
			
			const consecutiveDays = nextDate.getTime() - currentTime === MILLISECONDS_PER_DAY
			const nonGoal = goalTimes.indexOf(currentTime) === -1
			const sameProbe = chartResponses[index].probeNumber === chartResponses[index + 1].probeNumber
			const inSkillSet = chartResponses[index].skillSetNumber
			const sameSkillSet = inSkillSet && chartResponses[index].skillSetNumber === chartResponses[index + 1].skillSetNumber
			if (consecutiveDays && nonGoal && (sameProbe || sameSkillSet))
			{
				connectPoints(responses,chartResponses[index], chartResponses[index + 1])
				connectPoints(errors,chartResponses[index], chartResponses[index + 1], "error")
			}
		}
	}
	graph.drawReferenceLines(chart, start, graph.currentEndDate);

	// clips shading outside boundaries
	svg.append("clipPath")
	.attr("id", "chart-area")
	.append("rect")
	.attr("x", 0)
	.attr("y", 0)

	addWeekLines(chart,yDomain,roundedDateLine)
	await fidelityShading(chart,yDomain,roundedDateLine,studentID,svg)
	createArrows(container,unscaledLine)

	graph.updateExerciseGraph();
	graph.animate = true;
}

function dateDiff(first, second) {        
	return Math.round((second - first) / (MILLISECONDS_PER_DAY));
}
/**
 * @abstract initializes the reading grade progress graph
 */
graph.initGradeGraph = async function({chartData, studentID, type="high", startDate, endDate}) {
	const { chartResponses: submissions, startDate: start, endDate: end } = chartData
	var progressChart = document.getElementById("progress-chart"); //check if the chart exists
	if (progressChart == null)
		return;

	graph.animate = false; //do not animate during initialization (mostly due to print graph setup)
	//set some constants
	const yDomain = [0, 8];
	if(!startDate) startDate = start
	if(!endDate) endDate = end

	graph.currentStartDate = new Date(start + "T12:00:00Z");
	graph.currentEndDate = new Date(end + "T12:00:00Z");
	
	graph.startTime = graph.currentStartDate.getTime();
	graph.currentStartTime = graph.currentStartDate.getTime();
	graph.currentEndTime = graph.currentEndDate.getTime();

	graph.xScale = d3.scaleTime();
	graph.xAxis = d3.axisBottom(graph.xScale);
	graph.xAxis.tickFormat(d3.timeFormat("%b %d"));

	// make svg container and add chart
	const { container, svg, chart } = createChartContainer()

	// setup y axis
	const level = levels[type]
	d3.max(submissions, function(sub) {
			return level[sub["probeNumber"]-1]; // levels
		}
	);

	graph.yScale = d3.scaleLinear();
	graph.yScale.domain(yDomain);
	graph.yAxis = d3.axisLeft(graph.yScale);
	
	const getYScale = (data) => graph.yScale(data["y"])
	const getDate = (data) => graph.xScale(data["date"])

	graph.dateLine = createLine(getDate, getYScale)

	const getRoundedDateX = (data) => Math.round(graph.dateLine.x()(data))
	const getRoundedDateY = (data) => Math.round(graph.dateLine.y()(data))
	const roundedDateLine = createLine(getRoundedDateX, getRoundedDateY)

	const getResponses = (data) => graph.yScale(level[data["probeNumber"]])
	graph.responsesLine = createLine(getDate,getResponses)

	let responses = chart.append("g");

	var div = d3.select("body").append("div")	
    .attr("class", "graph-point-data")				
    .style("opacity", 0);

	for (let index = 0; index < submissions.length; index++) {
		var responsesCircle = responses.append("circle");
		responsesCircle.datum(submissions[index]);
		responsesCircle.attr("class", "response point");
		responsesCircle.attr("r", 0);
		responsesCircle.attr("cx", 0);
		responsesCircle.attr("cy", 0);

		// add hover for data point
		responses.append("circle")
		.datum(submissions[index])
		.attr("class", "response point clicker")
		.attr("r", 10)
		.style("cursor", "pointer")
		.attr("opacity", 0)
		.on("mouseover", function(d) {
			div.transition()	
				.duration(200)
				.style("opacity", .9);
				
			div.html(`
				${moment(d.date).format("MMM D")}
				<br/>Exercise: ${d.probeNumber}
				<br/>level: ${level[d.probeNumber - 1]}
			`)
				.style("left", (d3.event.pageX) + "px")		
				.style("top", (d3.event.pageY - 28) + "px")
			}).on("mouseout", function(d) {
					div.transition().duration(500).style("opacity", 0);	
			});


		if (index !== (submissions.length - 1)) {
			connectPoints(responses,submissions[index], submissions[index + 1])
		}
	}

	// add y axis
	svg.append("text")
	.attr("class", "y label")
	.attr("text-anchor", "end")
	.attr("y", 14) // y is x due to rotation of -90
	.attr("dx", "-2.8em")
	.attr("transform", "rotate(-90)")
	.text("Instructional Grade Level");
					
	graph.updateGradeGraph(startDate,endDate);
	graph.animate = true;
}

export default graph
