import React from "react"
import { MemoryRouter, Route, Switch } from "react-router"
import { Link } from "react-router-dom"
import SlidingPane from "../slide-panel/SlidingPanel"
import StudentsComponent from "../Students"
import MessageContainer from "../message/MessageContainer"
import { Instructor } from "../../shared/types/orgUserTypes"

/**
 * Students Pages
 */
import RemoveStudentShare from "../../pages/students/Share/Remove/Remove"
import ManagedSharedStudents from "../../pages/students/Share/Manage/Manage"
import EditSharedStudent from "../../pages/students/Share/Edit/EditShared"
import RevokeSharedStudent from "../../pages/students/Share/Revoke/Revoke"
import Records from "../../pages/records/RecordsPage"
import { readingSubjects, storyLevels } from "../../shared/routes/readingRoutes"

interface Props {
    isOpen: boolean
    onClose: () => void
    selectedInstructor: Instructor
    groupName: string
}

export default function StudentsPreview({ isOpen, onClose, selectedInstructor, groupName }: Props) {
    return (
        <SlidingPane
            isOpen={isOpen}
            onClose={onClose}
            title={`${selectedInstructor.name}'s Account`}
        >
            <MemoryRouter>
                <div className="row">
                    <div className="col-12">
                        <Route render={({ location }) => {
                            if (location.pathname !== '/' && location.pathname !== '/students') {
                                return <Link className="btn btn-secondary float-left" to="/students">Return to students</Link>
                            }
                        }} />
                    </div>
                </div>
                <Route component={MessageContainer} />
                <Switch>
                    {/*
                    * Students page, the '/' url must be included because that's the default
                    */}
                    <Route exact path={['/', '/students']} render={(props) => {
                        return <StudentsComponent {...props} customerUsername={selectedInstructor.username} groupName={groupName} />
                    }}/>

                    {/*
                    * Student Management Pages
                    */}
                    <Route exact path="/students/share/remove" component={RemoveStudentShare} />
                    <Route exact path="/students/share/manage" component={ManagedSharedStudents} />
                    <Route exact path="/students/share/edit" component={EditSharedStudent} />
                    <Route exact path="/students/share/revoke" component={RevokeSharedStudent} />
                    {/*
                    * Records Pages
                    */}
                    {readingSubjects.map((type) => {
                        return <Route exact path={`/students/records/phonics/${type}`} key={type} render={(props) => {
                            return <Records {...props} type={type} program="Reading" />
                        }}/>
                    })}

                    {
                        storyLevels.map((type) => {
                            return <Route exact path={`/students/records/stories/${type}`} key={type} render={(props) => {
                                return <Records {...props} type={type} program="Reading" />
                            }}/>
                        })
                    }
                </Switch>
            </MemoryRouter>
        </SlidingPane>
    )
}
