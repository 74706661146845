import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"
import Image from "react-bootstrap/Image"
import carolyn_testimonial from '../../content/images/carolyn-testimonial.png'
import galia_testimonial from '../../content/images/galia-testimonial.png'
import jessica_testimonial from '../../content/images/jessica-testimonial.png'
import john_testimonial from '../../content/images/john.jpg'
import grade_graph from '../../content/images/grade-graph.png'
import logoDark from '../../content/images/logo.svg'
import blobLeft from '../../content/images/blob_left.svg'
import homeBanner from "../../content/images/home_banner.png"
import blobRight from "../../content/images/blob_right.png"
import girlsStudying from "../../content/images/girls-studying.png"
import youngGirlLearning from "../../content/images/young-girl-learning.png"
import dgl_with_boy from '../../content/images/dgl-with-boy-circle.png'
import EnvironmentallyChallenged from "../../content/images/Icon-EnvironmentallyChallenged.png"
import ESLStudents from "../../content/images/Icon-ESLStudents.png"
import LearningChallenged from "../../content/images/Icon-LearningChallenged.png"
import StugglingReaders from "../../content/images/Icon-StugglingReaders.png"

function LandingHeader({line1,line2,children}) {
    return(<>
      <div className='overlayLandingTitle me-md-n3 text-md-start ps-md-5 word-nowrap'>
        <img src={logoDark} className="header-logo img-fluid d-none d-md-block mb-3" alt="Great Leaps logo of two dolphins leaping out of water"/>
        <h1 className="h1 mb-3">{line1}<br/>{line2}</h1>
        <p className="lead pb-3">{children}</p>
        <Link className='btn btn-success' to={"/account/sign-up"}>Let's Get Started!</Link>
      </div>
    </>)
}
export function PageSection(props) {
    return (<>
      <section className={`${props.sectionBreak && "section-break"} position-relative`}>
        <h2 className={`text-center ${!props.heading && "d-none"} pb-5`}>{props.heading}</h2>
        <Row className={`${props.reverse && "flex-row-reverse"} ${props.fullScreen && "full-screen"}  page-section row-cols-1 row-cols-md-2 justify-content-center`}>
          {props.children}
        </Row>
      </section>
    </>)
}

function ParentBlock({name, imgSrc, location, children}) {
  const [loaded,setLoaded] = useState(false)
  
  return (
      <div className="testimonial-item my-4 p-4">
        <Row>
          <p className="font-light text-start"><i className="fas fa-quote-left me-2" />{children}</p>
        </Row>
        <div className="crop-wrapper text-start px-2 pb-2">
          <img onLoad={() => setLoaded(true)} className="d-inline-block rounded-circle" src={imgSrc} alt={`${name}`} />
          {!loaded && 
            <Spinner animation="grow" variant="primary" aria-label="tutor profile is loading"/>
          }
          <Col className="d-inline-flex flex-column ">
            <span className='ms-3'>{name}</span>
            <span className='font-light ms-3'>{location}</span>
          </Col>
        </div>
      </div>
  )
}

function IconBlock({title, iconSrc, children, to=null, iconLabel}) {
    return (
      <Col md={4} className="icon-block move-forward">
        <div className="item mx-auto mb-5 mb-lg-3 h-100">
          {to !== null ?
            <a href={to}>
              <img src={iconSrc} className="icon" alt={iconLabel} />
              <h3 className="h5 pb-2 mt-4">{title}</h3>
              <p className="font-md">{children}</p>
            </a>
            :
            <>
              <img src={iconSrc} className="icon" alt={iconLabel} />
              <h3 className="h5 pb-2 mt-4">{title}</h3>
              <p className="font-md">{children}</p>
            </>
          }
        </div>
      </Col>
    )
  }

function Home() {
    return (<div id="home" data-testid="home-page-loaded">
        <link rel="stylesheet" type="text/css"/>
        <PageSection reverse>
            <Col lg={7} xl={6} className='d-flex mt-n2 g-0'>
                <Image src={blobRight}  className="blob right h-100 mt-n4" alt="green cartoon splash of water in background"/>
                <Image src={homeBanner}  className="img-fluid h-100 m-auto move-forward mt-n5 p-5" alt="dolphin with eye patch swimming above underwater book"/>
            </Col>
            <Col lg={4} className="m-auto pb-5">
                <LandingHeader 
                    line1={'Great Leaps Digital'} 
                    line2={'Reading and Math'} 
                    >
                    Speed. Accuracy. Comprehension.
                </LandingHeader>
            </Col>
        </PageSection>

        <h2 className='text-center mb-5'>Join over 600,000 students who have made <br/><span className='gl-blue'>Great Leaps</span> with our programs!</h2>
        <Image src={blobLeft} className="blob position-absolute img-fluid"/>

        <div className='content-section mx-auto mb-0'>
          <PageSection>
              <Col lg={6} className='d-flex align-items-center'>
                  <Image src={girlsStudying}  className="img-fluid m-auto move-forward" alt="girl studyinging with her tutor using the platform"/>
              </Col>
              <Col lg={6} className='d-flex flex-column justify-content-center'>
                <h2 className="h3 fw-bold mx-2 section-title">Digital Reading Intervention</h2>
                <ul className="check-mark-list text-start">
                    <li>For schools, tutors or parents teaching K-12 students that read below the 5th Grade Level who may have learning disabilities such as dyslexia or ADHD</li>
                    <li><span>Well recognized for over 20 years as a premier </span>
                        <a href="https://greatleaps.com/pages/research">research and evidence</a> 
                        <span> based reading intervention</span>
                    </li>
                    <li>All it takes is 15 minutes a day, three to five days per week with one on one instruction</li>
                </ul>
              </Col>
          </PageSection>      
        </div>

        <div className='content-section mx-auto'>
          <PageSection>
              <Col lg={6} className='d-flex flex-column justify-content-center'>
                <h2 className="h3 fw-bold mx-2 section-title">Math Facts Program</h2>
                <ul className="check-mark-list text-start">
                    <li>Teaches addition, subtraction, multiplication and division to automaticity with concrete understanding</li>
                    <li>Simple implementation one-to-one with an instructor and student</li>
                    <li>One minute, timed exercises that move step by step from the simple to more complex</li>
                </ul>
              </Col>
              <Col lg={6} className='d-flex align-items-center'>
                  <Image src={dgl_with_boy}  className="img-fluid m-auto move-forward" alt="Boy being tutored using the Great Leaps program"/>
              </Col>
          </PageSection>      
        </div>

        <div className='content-section mx-auto'>
          <PageSection>
            <Row className='w-100 mb-5'>
              <h2 className='mb-4 fw-bold'>WHO WE HELP</h2>
              <p>We are dedicated to transforming education through technology, and our mission is to empower learners of all ages and backgrounds. From students seeking personalized learning experiences to educators striving for innovative teaching tools, we are here to support and elevate everyone on their educational journey.</p>
            </Row>
            <Row className='w-100'>
              <Col lg={4} className='d-flex flex-column justify-content-between'>
                <Row className='d-flex justify-content-around align-items-start h-50'>
                  <Col xs={6} className='text-end mb-5'>
                    <h5 className='fw-bold'>Struggling Readers</h5>
                    <p style={{color: "grey"}}>Readers that have fallen behind K-12 students reading below the 5th grade level</p>
                  </Col>
                  <Col lg={4} sm={2} xs={2} className='mb-5'>
                    <Image src={StugglingReaders} className='img-fluid'/>
                  </Col>
                </Row>
                <Row className='d-flex justify-content-around align-items-end h-50'>
                  <Col xs={6} className='text-end h-100'>
                    <h5 className='fw-bold'>ESL Students</h5>
                    <p style={{color: "grey"}}>Students lacking phonics, comprehension, or fluency in English</p>
                  </Col>
                  <Col lg={4} sm={2} xs={2} className='h-100'> 
                    <Image src={ESLStudents} className='img-fluid'/>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} xs={7} className='d-flex align-items-center mx-auto my-5'>
                <Image src={youngGirlLearning} className='img-fluid mx-auto' alt="young girl with headphones using the platform"/>
              </Col>
              <Col lg={4} className='d-flex flex-column justify-content-between'>
                <Row className='d-flex justify-content-around align-items-start h-50'>
                  <Col lg={4} sm={2} xs={2} className='h-100'>
                    <Image src={LearningChallenged} className='img-fluid'/>
                  </Col>
                  <Col xs={6} className='mb-5 h-100'>
                    <h5 className='fw-bold'>Learning Challenged</h5>
                    <p style={{color: "grey"}}>Dyslexia, ADHD, and other common learning disabilities</p>
                  </Col>
                </Row> 
                <Row className='d-flex justify-content-around align-items-start h-50'>
                  <Col lg={4} sm={2} xs={2} className='h-100'>
                    <Image src={EnvironmentallyChallenged} className='img-fluid'/>
                  </Col>
                  <Col xs={6}>
                    <h5 className='fw-bold'>Environmentally Challenged</h5>
                    <p style={{color: "grey"}}>Students, who due to a challenging environment or situation, missed out basic comprehension</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PageSection>      
        </div>

        <div className='content-section mx-auto'>
          <PageSection>
              <Col lg={6} className='d-flex flex-column justify-content-center'>
                  <h2 className="section-title">Data and Results</h2>
                  <ul className="check-mark-list text-start">
                      <li>Our students have averaged a full year of reading growth with just 22 total hours of instruction</li>
                      <li>Parents and teachers report a decrease in behavioral referrals and academic frustration in the home</li>
                      <li>Fluent reading generalizes to improvements in all other areas of academics and standardized testing</li>
                  </ul>
                  <div>
                      <a href='https://greatleaps.com/pages/research' className="btn btn-success mb-5" data-testid="research-evidence-link">Research & Evidence</a>
                  </div>
              </Col>
              <Col lg={6} className='d-flex align-items-center'>
                  <Image src={grade_graph}  id="home-grade-graph" className='img-fluid w-100 m-auto move-forward' alt="grade graph"/>
              </Col>
          </PageSection>      
        </div>
        
        <div className='content-section mx-auto w-75'>
          <PageSection heading={"Student Success Stories"}>
            <div className="content-section-w section-mid youtube-section">
                <Row className='border rounded mb-4'>
                  <Col lg={5} className="p-0">
                      <div className="embed-responsive embed-responsive-16by9 w-100 h-100">
                          <iframe className='w-100 h-100' src="https://www.youtube.com/embed/Ndg6vSW5m-g?start=0&autoplay=0&loop=0&mute=0&controls=0&enablejsapi=1" allowFullScreen title="An IEP Teacher Using Great Leaps"></iframe>
                      </div>
                  </Col>
                  <Col lg={7} className="p-3">
                      <h4 className='mb-4'>An IEP Teacher Using Great Leaps:</h4>
                      <p className="gl-blue fw-bold">“He Said, I Want To Work On This Through The Summer And When We Get Back To School I Want To Work With You. I Am Reading Because Of You.”</p>
                      <p>I said no sweetheart you are reading because of YOU… You’re doing the effort. And when I got on the Great Leaps chat we had to do, I think it was universal. All of the teachers on that thread said were all talking about the fact that their kids were fanatic about getting back onto your website. </p>
                      <p className="text-end fw-bold">Allesandra B. Teacher, New York</p>
                  </Col>
                </Row>
                <Row className='border rounded mb-4'>
                  <Col lg={5} className="p-0">
                      <div className="embed-responsive embed-responsive-16by9 w-100 h-100">
                          <iframe className='w-100 h-100' src="https://www.youtube.com/embed/eJWN95QEU4M?start=0&autoplay=0&loop=0&mute=0&controls=0&enablejsapi=1" allowFullScreen title="An IEP Teacher Using Great Leaps"></iframe>
                      </div>
                  </Col>
                  <Col lg={7} className="p-3">
                      <h4 className='mb-4'>Laim’s Remarkable Reading Growth:</h4>
                      <p className="gl-blue fw-bold">“We Got Him Evaluated… The Psychologist Said He Would Probably Always Need Accommodations Like Someone To Dictate And Write For Him.”</p>
                      <p>We’ve been doing Great Leaps for 4 months now and he’s gained I think 2.4 grade levels in reading. It makes homeschooling way easier. It’s allowing him to learn new words without having to drill and kill. I’ve been recommending this to my friends… It’s opened up his world and his life.</p>
                      <p className="text-end fw-bold">Catherine M. Parent, Ohio</p>
                  </Col>
                </Row>
                <Row className='border rounded'>
                  <Col lg={5} className="p-0">
                      <div className="embed-responsive embed-responsive-16by9 w-100 h-100">
                          <iframe className='w-100 h-100' src="https://www.youtube.com/embed/i_1pWnQKE4w?start=0&autoplay=0&loop=0&mute=0&controls=0&enablejsapi=1" allowFullScreen title="An IEP Teacher Using Great Leaps"></iframe>
                      </div>
                  </Col>
                  <Col lg={7} className="p-3">
                      <h4 className='mb-4'>From Our Partners At PEAK Literacy:</h4>
                      <p className="gl-blue fw-bold">“I Wanted My Boys To Do Better Than I Did.”</p>
                      <p>I found PEAK Literacy for free reading tutoring. It’s 15 minutes, 5 days a week. My 12 year old, he actually picks up books now and walks around the neighborhood reading.</p>
                      <p className="text-end fw-bold">Yazira G. Parent, Florida</p>
                  </Col>
                </Row>
              </div>
          </PageSection>
        </div>

        <div className='content-section mx-auto w-50'>
          <PageSection>
            <Row className="alt-background-color mx-auto w-100 rounded">
              <div className="d-flex flex-column  align-items-center my-4"> 
                  <p className='h5 fw-bold text-center'>Want to hear more?</p>
                  <p className='h5 fw-bold text-center'>Take a look at our Testimonials</p>
                  <a href='https://greatleaps.com/pages/customer-testimonials' className="btn btn-lg btn-success" data-testid="testimonials">View Testimonials</a>
              </div>
            </Row>
          </PageSection>
        </div>

        <div className='mx-auto mb-0'>
          <PageSection>
            <Col xs={10} md={5} xl={4} className="me-md-3">
              <ParentBlock imgSrc={carolyn_testimonial} name="Carolynn K." location="Parent">
                  &quot;My child is excelling, and looks forward to his next steps in education
                  in middle school. To say Great Leaps has changed my son&apos;s life and
                  future is an UNDERSTATEMENT... Just think, a child&apos;s success in life
                  can be directly impacted by this program in only fifteen minutes a day!&quot;
              </ParentBlock>
              <ParentBlock imgSrc={galia_testimonial} name="Galia M." location="Executive Director, A.H.A.V.A. English Literacy Program">
              &#x201C;I must reiterate to you how wonderful I think your program is.
                  I have close to 30 years of teaching behind me and this is a program that
                  I think is really worth every bit of hype it gets, and does what it sets
                  out to do.&#x201D;            
              </ParentBlock>
            </Col>
            <Col xs={10} md={5} xl={4} className="mt-md-6 mb-4 ms-md-3">
              <ParentBlock imgSrc={jessica_testimonial} name="Jessica H." location="Parent">
                  &#x201C;My 8 year old has been doing Great Leaps for 7 weeks now. We started
                  at the end of the school year, had lots of family in town and went on a
                  month long vacation as a family. Through all of that it has been so easy
                  and fun to continue Great Leaps. It&#x2019;s even more fun because of the
                  results I am seeing in Elsie. At 3 weeks I was seeing her self correct.
                  I was ecstatic!&#x201D;
              </ParentBlock>
              <ParentBlock imgSrc={john_testimonial} name="John M." location="Parent">
                Asher quite literally changed the course of our son’s life. He was a tutor and a role model, switching struggles from frustrations into challenges, and challenges into proud moments. Catching up on skill and confidence in reading has shifted his relationship to school and all classes, and online tutoring has helped deepen his investment and understanding of remote learning. Without this experience, there’s really no telling how much farther behind he would’ve fallen, or how upset with himself he might have become.
              </ParentBlock>
            </Col>
          </PageSection>
        </div>
        
    </div>);
};

export default Home;
