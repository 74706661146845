import React from 'react'
import Sections from '../components/probe-list/Sections'
import ReadingLinks from "../components/subject-navigation/ReadingLinks"
import getURLParameters from '../helpers/getURLParams'
import BeatLoader from 'react-spinners/BeatLoader'
import redirect from '../components/redirect/redirect'
import message from '../components/message/message'
import { Link } from 'react-router-dom'
import buildQuery from '../helpers/buildQuery'
import { ProbeListSection, ProbeListSuggestedProbe} from '../shared/types/probeListTypes'
import * as probeListModel from "../shared/routes/probeListRoutes"
import * as suggestedModel from '../shared/routes/suggestedRoutes'
import * as studentsModel from '../shared/routes/studentRoutes'
import { ProgramsType, Student } from '../shared/types/studentTypes'
import { SuggestedPhonicsType, SuggestedStoriesType } from '../shared/types/suggestedTypes'
import { readingSubjects, ReadingSubjects, storyLevels, StoryLevels } from '../shared/routes/readingRoutes'
import { MathSubjects, mathSubjects } from '../shared/types/mathTypes'
import MathGroupHeader from '../components/probe-list/MathGroupHeader'
import ProgramHeader from '../components/subject-navigation/ProgramHeader'
import Header from '../components/probe-list/Header'
import { Button, Container } from 'react-bootstrap'
import { ProbeList } from '../shared/types/probeListTypes'
import { SuggestedResponse } from '../shared/types/suggestedTypes'
import { EmergentReaderSubjects, emergentReaderSubjects } from '../shared/types/emergentReaderTypes'
import EmergentReaderGroupHeader from '../components/probe-list/EmergentReaderGroupHeader'
import * as statusModel from '../shared/routes/statusRoutes'
import { SubscriptionStatus } from '../shared/types/subscriptionTypes'
import LicenseModal from '../components/licensing/LicenseModal'


//TODO: This eventually should be moved to app.js or index.js
declare global {
    interface Window {
        $: any;
    }
    /*
        Override the generic include due to how narrow it is.
        See issue: https://github.com/microsoft/TypeScript/issues/26255
        Good article: https://fettblog.eu/typescript-array-includes/
    */
    interface Array<T> {
        includes(searchElement: any, fromIndex?: number): searchElement is T;
    }
    interface ReadonlyArray<T> {
        includes(searchElement: any, fromIndex?: number): searchElement is T;
    }
}

class ProbesList extends React.Component<
    {
        type: ReadingSubjects | StoryLevels | MathSubjects | EmergentReaderSubjects,
        story: StoryLevels,
        program: ProgramsType
    }, {
        studentName: string,
        studentLicenses: Student["licenses"]
        studentCode: string,
        studentID: string,
        sections: ProbeListSection[],
        suggestedStory: SuggestedStoriesType,
        suggestedPhonicsType: SuggestedPhonicsType,
        suggestedProbe: ProbeListSuggestedProbe,
        loading: boolean,
        selectedProbe: number,
        isNewReadingStudent: boolean,
        knowTheirLetterNames: boolean
        statusLoading: boolean
        status: SubscriptionStatus
        licenseModal: ProgramsType
        hideCallback: () => void
        assignCallback: () => void
    }>
{
    state =
        {
            studentName: null,
            studentCode: null,
            studentLicenses: [],
            studentID: null,
            sections: [],
            suggestedStory: null,
            suggestedPhonicsType: null,
            suggestedProbe: null,
            loading: true,
            selectedProbe: null,
            //Related to alphabet questions
            isNewReadingStudent: null,
            knowTheirLetterNames: null,
            statusLoading: true,
            status: null,
            licenseModal: null,
            hideCallback: () => this.setState({ licenseModal: null }),
            assignCallback: () => {},
        }

    probeClick = (event, probeNumber) => {
        if (this.state.suggestedProbe?.number !== probeNumber) {
            event.preventDefault()
            window.$("#warning-modal").modal("show")
            this.setState({ selectedProbe: probeNumber })
        }
    }

    updateList = () => {
        var params = getURLParameters(this.props)
        this.setState({
            loading: true,
            studentID: params.id
        })

        Promise.all([
            probeListModel.get({
                studentID: params.id,
                type: this.props.type
            }),
            suggestedModel.get({
                studentID: params.id
            }),
            studentsModel.getOne(params.id)
        ]).then(([probeList, suggested, student]: [ProbeList,SuggestedResponse,any]) => {
            let suggestedProbe
            if (params.overrideSuggestedProbe && params.overrideSuggestedSection) {
                suggestedProbe = {
                    number: parseInt(params.overrideSuggestedProbe),
                    section: parseInt(params.overrideSuggestedSection),
                    skillSet: null
                }
            }
            else {
                suggestedProbe = probeList.suggestedProbe
            }
            this.setState({
                suggestedProbe: suggestedProbe,
                studentName: student.name,
                studentLicenses: student.licenses,
                studentCode: probeList.studentCode,
                sections: probeList.sections,
                suggestedPhonicsType: suggested.suggestedPhonicsType,
                suggestedStory: suggested.suggestedStory,
                isNewReadingStudent: this.props.program === "Reading" && !probeList.hasDoneProbe,
                loading: false
            })
        }).catch((err) => {
            return redirect.send('/dashboard', this.props, () => {
                message.error(err)
            })
        }).finally(() => this.setState({loading: false}))
    }

    getStatus = async () => {
        this.setState({ statusLoading: true })
        const status = await statusModel.get()
        this.setState({
            statusLoading: false,
            status: status,
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type) {
            this.updateList()
        }
    }

    componentDidMount() {
        window.$("#warning-modal").modal("hide")
        window.$("#warning-modal2").modal("hide")

        this.updateList()
        this.getStatus()
    }

    render() {
        var { type, story, program } = this.props
        var { studentID, studentCode, studentLicenses, studentName, sections, suggestedProbe, suggestedStory, suggestedPhonicsType, loading, isNewReadingStudent } = this.state

        let query = { id: studentID }
        const standardVariables = buildQuery(query)

        const _type: "phonics" | "alphabet" | "awareness" | "phrases" | "stories" = ["high", "middle", "elementary"].includes(type) ? "stories" : type as any

        const emergentReaderActive = !!studentLicenses.find((license) => license.program.toLowerCase() === "emergent reader" && license.status === "Active")

        return (<Container>
            <Header
                studentID={studentID}
                studentCode={studentCode}
                studentName={studentName}
                active={_type}
                story={story}
            />

            <ProgramHeader
                status={this.state.status}
                statusLoading={this.state.statusLoading}
                refreshProgramStatus={this.updateList}
                studentID={getURLParameters(this.props).id}
                studentName={studentName}
                refreshDonuts={loading}
                initialProgram={program}
                mathActive={!!studentLicenses.find((license) => license.program.toLowerCase() === "math" && license.status === "Active")}
                emergentReaderActive={emergentReaderActive}
                readingActive={!!studentLicenses.find((license) => license.program.toLowerCase() === "reading" && license.status === "Active")}
                basePath="/probes"
            />

            {!this.state.statusLoading && <LicenseModal
                extraText='Students who have not mastered their letter names and sounds should begin in the Emergent Reader program to work through Alphabet and Sound Awareness exercises'
                program={this.state.licenseModal}
                status={this.state.status}
                studentId={studentID}
                onHideCallback={this.state.hideCallback}
                assignCallback={this.state.assignCallback}
            />}

            {loading ?
                <div style={{ margin: "0 auto", display: "table", height: "70vh" }}>
                    <div className="text-center" style={{ display: "table-cell", verticalAlign: "middle" }}>
                        <BeatLoader
                            size={15}
                            color={"#123abc"}
                            loading={true}
                        />
                    </div>
                </div>
                :
                isNewReadingStudent && type === "phonics" ? <div style={{ height: "300px" }}>
                    <h2 className="text-center mt-5 pt-5">
                        {!this.state.knowTheirLetterNames ? <React.Fragment>
                            Does your student know their letter <strong>names</strong>?
                        </React.Fragment>
                            :
                            <React.Fragment>Does your student know their letter <strong>sounds</strong>?</React.Fragment>
                        }
                    </h2>

                    <div className="text-center pt-2">
                        <Button
                            variant="secondary"
                            className="btn-secondary btn-lg mx-2"
                            onClick={() => {
                                if (!this.state.knowTheirLetterNames) {
                                    this.setState({ knowTheirLetterNames: true })
                                }
                                else {
                                    //set sounds and isolation
                                    this.setState({ isNewReadingStudent: false })
                                    redirect.send(`/probes/phonics?${standardVariables}`, this.props)
                                }
                            }}>Yes</Button>
                        <Button
                            variant="secondary"
                            className="btn-secondary btn-lg mx-2"
                            onClick={() => {
                                let redirectUrl: string

                                if (!this.state.knowTheirLetterNames) {
                                   redirectUrl = `/probes/alphabet?${standardVariables}`
                                    //set sounds and isolation
                                } else {
                                    redirectUrl = `/probes/alphabet?${standardVariables}&overrideSuggestedProbe=41&overrideSuggestedSection=2`
                                }

                                if (!emergentReaderActive) {
                                    this.setState({
                                        licenseModal: "Emergent Reader",
                                        assignCallback: () => {
                                            redirect.send(redirectUrl, {})
                                        }
                                    })
                                } else {
                                    redirect.send(redirectUrl, {})
                                }
                             }}
                        >No or Not Sure
                        </Button>
                    </div>
                </div>

                    : <>
                        {[...readingSubjects, ...storyLevels].includes(type) &&
                            <ReadingLinks
                                studentID={studentID}
                                active={type}
                                suggestedStory={suggestedStory}
                                suggestedPhonicsType={suggestedPhonicsType}
                                basePath={"/probes"}
                            />
                        }

                        {mathSubjects.includes(type) && <>
                            <MathGroupHeader
                                studentID={studentID}
                                active={type}
                                basePath={"/probes"}
                            />
                        </>}
                        
                        {emergentReaderSubjects.includes(type) &&
                            <EmergentReaderGroupHeader
                                studentID={studentID}
                                active={type}
                                suggestedPhonicsType={suggestedPhonicsType}
                            />
                        }
                        <Sections
                            type={type}
                            studentID={studentID}
                            sections={sections}
                            suggested={suggestedProbe}
                            suggestedStory={suggestedStory}
                            probeBaseURL={story ?? type}
                            probeClick={this.probeClick}
                        />
                    </>
            }

            <div className="modal fade" id="warning-modal" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">STOP!</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <p className="text-center">Please select the highlighted exercise to do with your student!</p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <Link to={`${this.props.type}/${suggestedProbe?.number}?${standardVariables}${suggestedStory ? `&suggestedStory=${suggestedStory}` : ""}`}
                                onClick={() => { window.$("#warning-modal").modal("hide") }}
                                className="btn modal-btn btn-primary">Go to Suggested Exercise</Link>
                            <button type="button"
                                className="btn modal-btn btn-danger"
                                data-dismiss="modal"
                                data-toggle="modal"
                                data-target="#warning-modal2">Continue to Selected</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="warning-modal2" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content text-center">
                        <div className="modal-header">
                            <h4 className="modal-title">Are you sure you want to override the Great Leaps Digital Program?</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <p className="offset-md-1 col-md-10">Selecting this exercise is not following the Great Leaps Digital Program with fidelity and can adversely effect your student's growth.</p>
                        </div>
                        <div className="modal-footer m-auto">
                            <Link to={`${this.props.type}/${suggestedProbe?.number}?${standardVariables}${suggestedStory ? `&suggestedStory=${suggestedStory}` : ""}`}
                                onClick={() => { window.$("#warning-modal2").modal("hide") }}
                                className="btn modal-btn btn-primary">
                                Go to Suggested Exercise
                            </Link>
                            <Button type="button"
                                data-dismiss="modal"
                                className="modal-btn btn-danger"
                                id="override-link"
                                onClick={() => {
                                    redirect.send(`${this.props.type}/${this.state.selectedProbe}?${standardVariables}${suggestedStory ? `&suggestedStory=${suggestedStory}` : ""}`, this.props)
                                }}>Override program
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Container>)
    }
}

export default ProbesList
