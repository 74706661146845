import React from 'react'
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

function FrequentlyAskedQuestions() {
    
    return (
        <Container>
            <h1 className="section-header text-center mt-3">Frequently Asked Questions</h1>

            <div className="row">
                <div className="col-sm-10 offset-sm-1" id="accordion">

                    <h2>Program Information</h2>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#differences">
                            <div className="card-header">
                                <h6 className="card-title mb-0">What sets <em>Great Leaps Digital</em> apart from other reading programs?</h6>
                            </div>
                        </a>

                        <div id="differences" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p><em>Great Leaps</em> is a research-based reading intervention that is designed and proven to generate significant and lasting gains in a student's overall reading that generalizes into a student's day-to-day requirements.</p>
                                <p><em>Great Leaps</em> takes less than fifteen minutes a day at least 3 times per week, all while working within the attention span of your student.</p>
                                <p><em>Great Leaps Digital</em> utilizes the advantages of a one-to-one tutor. Many competitors' products rely mainly on a student working with a machine, using no immediate correction or human interaction. We firmly believe that a caring instructor is an essential element of building up a student's academics, self-confidence, and motivation. Present data validates this position. At risk students have not been traditionally self-motivated in reading and most do not have the focus necessary to be the driving force behind their own remediation and success.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#student-benefit">
                            <div className="card-header">
                                <h6 className="card-title mb-0">What type of student would benefit from using the <em>Great Leaps Digital</em> program?</h6>
                            </div>
                        </a>

                        <div id="student-benefit" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>Great Leaps is a great choice for your student if any of these can be said:</p>
                                <ul>
                                    <li>You have a K - 12 student reading below the 5th grade level</li>
                                    <li>Your student may be learning disabled or dyslexic</li>
                                    <li>Your K - 5 student is on track but wants to get ahead</li>
                                    <li>You find that he/she easily frustrated and unmotivated</li>
                                    <li>Your student has difficulty sounding out words</li>
                                    <li>Your student reads without any expression (robot reading)</li>
                                    <li>Your student often skips words and lines while reading</li>
                                    <li>Your student makes errors while reading</li>
                                    <li>Your student has trouble with comprehension of stories</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#progress">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How will I know if a student is making progress?</h6>
                            </div>
                        </a>

                        <div id="progress" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>Each student is unique. The data on the student's chart will indicate progress. If the student's speed continues to increase and the error count decreases, while goals are appearing regularly after 4-8 attempts, your student is progressing.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#content-ed">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Why doesn't <em>Great Leaps</em> use content education in the stories?</h6>
                            </div>
                        </a>

                        <div id="content-ed" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>The goal of <em>Great Leaps</em> is to quickly take a non-fluent reader to fluency, meaning the student's reading is approaching the rate of conversation with deep comprehension. Evidence indicates that processing academic content slows down reading and fluency. With only a one-minute daily reading timing, efficiency is imperative. Great Leaps stories are written at the conversational level allowing the written words on the page to come alive as language.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#ratio-chart">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Why does <em>Great Leaps</em> use an equal ratio chart?</h6>
                            </div>
                        </a>

                        <div id="ratio-chart" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>The equal ratio chart is a multiply-divide chart that shows constant rates of growth as straight lines, which gives the most relevant picture for monitoring the progress of reading growth. As the number of responses increases (charted with the top line) and the errors decrease (illustrated with the bottom line) you can detect and project growth patterns intuitively. This can be beneficial for writing IEP's and projecting future growth. Reading growth is best shown via percentage rather than incremental gains.</p>
                            </div>
                        </div>
                    </div>

                    <h2>Program Usage</h2>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#administer">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Who may administer <em>Great Leaps Digital</em>?</h6>
                            </div>
                        </a>

                        <div id="administer" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p><em>Great Leaps</em> does not require highly trained professionals to generate its impressive results. Educators, therapists, paraprofessionals, tutors, parents, volunteers, and peers can, with proper guidance and oversight, use the program to work with students and generate significant long-lasting results.</p>
                                <p>While <em>Great Leaps Digital</em> makes it easier than ever to implement the program in person, it can also be done using a video chat application to interact with the student remotely. Instructors can work with their students and achieve significant reading growth, regardless of their geographic location - all they need is an Internet connection.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#settings">
                            <div className="card-header">
                                <h6 className="card-title mb-0">In which settings can <em>Great Leaps</em> be used?</h6>
                            </div>
                        </a>

                        <div id="settings" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p><em>Great Leaps</em> can be used successfully in a variety of settings: general education classrooms, reading labs, resource rooms, self-contained special education classrooms, independent tutoring labs, after school centers and in homes, public libraries, or even coffee shops. The digital program just requires an Internet connection wherever you choose to work with your student.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#dok-questions">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How do I use the Comprehension Development questions?</h6>
                            </div>
                        </a>

                        <div id="dok-questions" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>Comprehension Development questions allow you to stimulate expressive language and to engage the student in the story and creative thought process. Each story contains a number of questions designed to build critical thinking and comprehension. It is not necessary for the student to complete the passage prior to answering DOK questions. The answers are not charted. Not every question must be asked to every student. Questions should be selected at the instructor's discretion. These are never to be used to determine grades.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#errors">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Can I change the errors or word count once an exercise has been submitted?</h6>
                            </div>
                        </a>

                        <div id="errors" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>No, you are only able to change the errors, word count, and time prior to submission. Once submitted, the data is automatically charted and can be viewed in your student's <em>Records</em>.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#childs-chart">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Can an instructor give a parent access to their child's chart?</h6>
                            </div>
                        </a>

                        <div id="childs-chart" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>Yes, the Share feature allows different levels of permissions within an account. The parent will have to create an account on <em>Great Leaps Digital</em> prior to access. For further instructions go to the <em>Instructor's Guide</em> under the <em>Tutorial</em> tab in the menu and navigate to the <em>Additional Functions</em> section.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#shared-chart">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Can charts be shared?</h6>
                            </div>
                        </a>

                        <div id="shared-chart" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>Yes, charts can be shared with anyone who has a <em>Great Leaps Digital</em> account. For further instructions go to the <em>Instructor's Guide</em> under the <em>Tutorial</em> tab in the menu and navigate to the <em>Additional Functions</em> section.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#instructor-permissions">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Once someone other than the instructor has been given permission to look at a student's record, can they access any other student's information?</h6>
                            </div>
                        </a>

                        <div id="instructor-permissions" className="panel-collapse collapse">
                            <div className="card-body text-center">
                                <p>No, only the specific student accounts that have been shared can be viewed.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#student-code">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Do I have to write down the Student Code?</h6>
                            </div>
                        </a>

                        <div id="student-code" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>No, a different Student Code is generated with each session to link the student and teacher devices. Once an instructor completes a session with a student, they can select another student or log off. If they select another student a new code will be generated and the process is repeated.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#student-moves">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How do I enter the Student Code?</h6>
                            </div>
                        </a>

                        <div id="student-moves" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>To begin you will log in with the instructor's device. Once you are logged in, you will select the <em>Exercises</em> tab. Then select a student's name and the code will appear at the top of the screen.</p>
                                <p>Next, on the student's device, go to the website and select the <em>Log in</em> tab on the upper right. Then select the <em>Student</em> tab.</p>
                                <p>Enter your teacher username and the 5 character code that was given on the teacher's exercises page and select <em>Log in</em>. Now the devices are linked and you are ready to begin.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#student-session">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How do I start the session once the Student Code has been entered?</h6>
                            </div>
                        </a>

                        <div id="student-session" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>Once the student device is logged in, the instructor will select a exercise in <em>Phonics</em>, <em>Sight Phrases</em> or <em>Stories</em> on the teacher device that will populate the corresponding exercise on the student device. The student taps the exercise and awaits teacher instruction.</p>
                                <p>Once the timing is complete, the student will tap the <em>back</em> button on the bottom of their screen. They will continue their timings in the next section by tapping on the exercise their instructor has selected.</p>
                                <p>See the <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Great Leaps Digital Instructor's Guide</a> for more information.</p>
                            </div>
                        </div>
                    </div>

                    <h2>Subscription information</h2>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#activate-subscription">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How do I activate a subscription?</h6>
                            </div>
                        </a>

                        <div id="activate-subscription" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>After purchasing a subscription, you will receive an activation email from us that will allow you to activate your subscription. Click the link in the email and log in if prompted to do so.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#subscription-expire">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How will I know when my subscription is about to expire?</h6>
                            </div>
                        </a>

                        <div id="subscription-expire" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>You will be notified by email 30 days before your subscription will expire and again 24 hours before expiration. If you would like to extend your subscription you can go to the <Link to="/subscribe">Subscribe page</Link> to renew.</p>
                                <p>Our customer service line 877-475-3277 is available 9:30 - 4:30 EST M-F for additional questions.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#additional-students">
                            <div className="card-header">
                                <h6 className="card-title mb-0">If my student moves can I use that subscription for another student?</h6>
                            </div>
                        </a>

                        <div id="additional-students" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>If a student moves to another school you can either <em>Transfer</em> or <em>Archive</em> that student. If you <em>Transfer</em> the student it will remove them from your account and open up that subscription for a new student. This will allow the first student to continue <em>Great Leaps Digital</em> at another location (provided the other location has purchased a <em>Great Leaps Digital</em> subscription). If you choose to Archive the student it will end your ability to perform exercises with that student, although you can still view their past data.</p>
                                <p>For instructions on how to do this, open up our <a href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Instructor's Guide</a> under the <em>Tutorial</em> tab in the menu and navigate to the <em>Additional Functions</em> section.</p>
                            </div>
                        </div>
                    </div>

                    <h2>My Account</h2>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#change-username">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How do I change my username?</h6>
                            </div>
                        </a>

                        <div id="change-username" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>If you would like to change the username for your account, please contact us using our <a href="https://greatleaps.com/index.php?main_page=contact_us">contact form</a>. Include your current username and your desired new username.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#password-change">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How do I change my password?</h6>
                            </div>
                        </a>

                        <div id="password-change" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>To change your password, visit the <a href="/account/password">account password page</a>. Enter your current password and new password in the appropriate fields, then click "Change Password". Please note that anyone else currently logged in to that account will be logged out when you change the password.</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#password-reset">
                            <div className="card-header">
                                <h6 className="card-title mb-0">How do I recover my password?</h6>
                            </div>
                        </a>

                        <div id="password-reset" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>If you've forgotten your password, visit the <Link to="/account/recover/username">account recovery page</Link>. Enter the username and/or email address associated with your <em>Great Leaps Digital</em> account, and click "Search". An email will be sent to you that includes a link to reset your password. If you do not receive an email within 15 minutes, check your email's spam folder.</p>
                            </div>
                        </div>
                    </div>

                    <h2>Technical</h2>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#system-requirements">
                            <div className="card-header">
                                <h6 className="card-title mb-0">What are the minimum system requirements?</h6>
                            </div>
                        </a>

                        <div id="system-requirements" className="panel-collapse collapse">
                            <div className="card-body text-justify">
                                <p>To ensure that you have the best experience possible with <em>Great Leaps Digital</em>, we recommend the following browser specifications. Please note that some aspects of <em>Great Leaps Digital</em> may not work if your browser does not meet these minimum requirements:</p>

                                <h3>Desktop</h3>

                                <ul>
                                    <li>Chrome 40 or later</li>

                                    <li>Firefox 35 or later</li>

                                    <li>Edge 12 or later (requires Windows 10 or later)</li>

                                    <li>Internet Explorer 11 (requires Windows 7 or later)</li>

                                    <li>Safari 8 or later (requires OS X Yosemite or later)</li>
                                </ul>

                                <h3>Mobile Devices</h3>

                                <ul>
                                    <li>Android Browser 5 or later</li>

                                    <li>Safari (requires iOS 8 or later)</li>

                                    <li>Chrome 40 or later (requires iOS 8 or Android 4.1 or later)</li>

                                    <li>Firefox 35 or later (requires Android 2.2 or later)</li>

                                    <li>Firefox 2.0 or later (requires iOS 8.2 or later)</li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-2">
                        <a data-toggle="collapse" data-parent="#accordion" href="#two-devices">
                            <div className="card-header">
                                <h6 className="card-title mb-0">Do you have to be in the same location for the two devices to connect?</h6>
                            </div>
                        </a>

                        <div id="two-devices" className="panel-collapse collapse">
                            <div className="card-body text-center">
                                <p>No, both devices simply have to be connected to the Internet and signed in for it to work properly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Container>
    )
}

export default FrequentlyAskedQuestions;
