import React from "react"
import ReactGA from "react-ga4"
import { Link } from "react-router-dom"
import Form from "react-bootstrap/Form"
import { getURLParams } from "@stem-sims/great-leaps-react-common"
import message from "../../../components/message/message"
import redirect from "../../../components/redirect/redirect"
import * as countries from "../../../shared/routes/countriesModelRoutes"
import * as signUp from "../../../shared/routes/signUpRoutes"
import * as zipcode from "../../../shared/routes/zipcodeRoutes"
import * as statesModel from "../../../shared/routes/statesRoutes"
import { initializeFormValidation, validForm } from "../../../helpers/forms/form-validation"
import FormInput from "./components/FormInput"
import { Formik, Form as FormikForm } from 'formik'
import FormSelect from "./components/FormSelect"
import SubmitButton from "../../../components/forms/SubmitButton"
import { StateResponse } from "../../../shared/types/accountTypes"

export default function SignUpTemplate(props) {
    const params = getURLParams()
    const { updatePermissions } = props
    const [countriesOptions, setCountriesOptions] = React.useState([])
    const [states, setState] = React.useState<StateResponse[]>([])
    const [postalCodeTimeout, setPostalCodeTimeout] = React.useState(null)

    const signUpForm = React.useRef()
    const privacyPolicy = React.useRef<HTMLInputElement>()

    const initialValues = {
        name: ((params?.firstName ?? "") + " " + (params?.lastName ?? "")).trim(),
        username: "",
        emailAddress: params?.emailAddress ?? "",
        referral: "",
        entity: "",
        role: "",
        postalCode: "",
        city: "",
        country: "",
        state: "",
        phoneNumber: "",
        notes: "",
        organizationInvite: params.invite,
        samlID: params?.id
    }

    const onSubmit = async (values) => {
        const blurEvent = document.createEvent("Event")
        blurEvent.initEvent("blur", false, true)
        if (!validForm(signUpForm.current, blurEvent)) {
            return
        }

        if (!privacyPolicy?.current?.checked) {
            return message.error("You must agree to the privacy policy to use Great Leaps.")
        }

        try {
            await signUp.post(values)

            ReactGA.event({
                category: "samlSignUp",
                action: "click",
                label: "button"
            })
            await updatePermissions()
            redirect.send("/account/sign-up/success", props)
        }
        catch (error) {
            message.error(error.message ?? "There has been an error signing up. Please refresh the page and try again.")
        }
    }

    React.useEffect(() => {
        const returnClientError = (response) => {
            message.error(response?.message ?? "There has been an error loading the locations. Please refresh the page and try again.")
        }

        /**
         * Legacy sign up functions
         */
        initializeFormValidation()

        countries.get().then((response) => {
            if (!response.countries) {
                return returnClientError(response)
            }
            setCountriesOptions(response.countries)
        }).catch((err) => {
            returnClientError(err)
        })
    }, [])

    const getStates = async (country: string, formik) => {
        try {
            const states = await statesModel.get(country)
            setState(states)
            formik.setFieldValue("state", "")
        }
        catch (error) {
            message.error(error?.message ?? "There has been an error loading your location. Please refresh the page and try again.")
        }
    }

    const countryOnChange = (event, formik) => {
        formik.handleChange(event)
        getStates(event.target.value, formik)
    }

    const postalCodeOnChange = (event, formik) => {
        formik.handleChange(event)
                        
        if (postalCodeTimeout) {
            clearTimeout(postalCodeTimeout)
            setPostalCodeTimeout(null)
        }

        setPostalCodeTimeout(
            setTimeout(async () => {
                setPostalCodeTimeout(null)
                const location = await zipcode.get(event.target.value)

                if (!location?.city) {
                    return
                }

                formik.setFieldValue("city", location.city)
                formik.setFieldValue("country", location.country)
                await getStates(location.country, formik)
                formik.setFieldValue("state", location.state)
            }, 1000)
        )
    }

    return <>
    <div className="row subheader-text">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 mt-4">
            <h1 className="mt-2">Sign Up to Get Started with Great Leaps</h1>
            <p>Fill out this form with your information, click the "Create Account" Button, and activate your account by following the instructions we send to your email address.</p>

            <h2 className="h5"><strong>All new accounts come with a 14-day free trial. Register a student and get started!</strong></h2>
        </div>
    </div>
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting }) => <FormikForm id="sign-up-form" className="form-horizontal form-validate" method="post" ref={signUpForm} noValidate>
            <FormInput fieldName="name" groupText="First and Last Name" required/>
            <FormInput fieldName="username" required/>
            <FormInput fieldName="emailAddress" required/>
            <FormInput fieldName="entity" label="School/Company/Entity" groupText={"optional"} />
            <FormSelect fieldName="role" required>
                <option disabled value="">Role:</option>
                {["Parent", "Tutor", "Teacher", "Administrator", "Other"].map((role) => {
                    return <option value={role} key={role}>{role}</option>
                })}
            </FormSelect>
            <FormInput fieldName="postalCode" onChange={postalCodeOnChange} required/>
            <FormInput fieldName="city" required/>
            <FormSelect fieldName="country" onChange={countryOnChange} required>
                <option value="" disabled>Country:</option>
                {countriesOptions?.map((item) => {
                    return (<option value={item} key={item}>{item}</option>)
                })}
            </FormSelect>
            <FormSelect fieldName="state" required>
                <option value="" disabled>State or Province:</option>
                {states.length === 0 ? <option value="" disabled >Please select a country first.</option> : ""}
                {states?.map((item) => {
                    return (<option value={item.State} key={item.State}>{item.State}</option>)
                })}
            </FormSelect>
            <FormInput fieldName="phoneNumber" required/>
            <FormInput fieldName="notes" groupText={"optional"}/>
            <div className="d-flex justify-content-center text-muted">
                <Form.Check
                    id="agree-policy"
                    name="agree-policy"
                    value="yes"
                    required
                    label={<label htmlFor="agree-policy">I agree to GreatLeaps's <Link to="/legal/terms-of-use" target="_blank">Terms of Use</Link> and <Link to="/legal/privacy-policy" target="_blank">Privacy Policy</Link>.</label>}
                    style={{maxWidth: "500px"}}
                    data-description="agree to the privacy policy and terms of use."
                    ref={privacyPolicy}
                />
            </div>
            <div className="text-center">
                <SubmitButton submitting={isSubmitting} disabled={false}>Create Account</SubmitButton>
            </div>
        </FormikForm>}
    </Formik>
</>
}
