import React, { Dispatch, SetStateAction } from 'react'
import { Modal, Button } from 'react-bootstrap'
import SignUpModal from '../account/sign-up/SignUpModal'
import LoginModal from '../account/log-in/LoginModal'

type AuthModelProps = {
    authenticationModal: boolean
    setAuthenticationModal: Dispatch<SetStateAction<boolean>>
    submitting: boolean
    licenses: {}
}

function AuthenticationModal(props: AuthModelProps) {
    const [authViewState, setAuthViewState] = React.useState(false)

    return (
        <Modal show={props.authenticationModal} size='xl'>
            <Modal.Header className='justify-content-end'>
                <Button variant='success' onClick={() => props.setAuthenticationModal(false)}>Close</Button>
            </Modal.Header>
            <Modal.Body>
                {authViewState ?
                    <SignUpModal licenses={props.licenses} setAuthViewState={setAuthViewState} /> :
                    <LoginModal licenses={props.licenses} setAuthViewState={setAuthViewState} />
                }
            </Modal.Body>
        </Modal>
    )
}

export default AuthenticationModal