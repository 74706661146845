import React from 'react'
import qs from 'qs'
import Button from "react-bootstrap/Button"
import { initializeFormValidation } from '../../../../helpers/forms/form-validation'
import message from '../../../../components/message/message'
import redirect from '../../../../components/redirect/redirect'
import ClipLoader from 'react-spinners/ClipLoader'
import { modelRequest } from '@stem-sims/nexus'

class Revoke extends React.Component {
    state = {
        permissions: [],
        submitting: false
    }
    componentDidMount() {
        var params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

        if (!params.permissions) {
            redirect.send('/students/share/manage', this.props, () => {
                message.error('Select a permission to revoke using the checkboxes on the left of the permissions.')
            })
        }

        modelRequest.get('/api/students/share').then((data) => {
            var shared = data.permissions
            var permissions = []
            //The format for the permissions is a bit odd so I'll attempt to explain it
            //Params should look like this:
            //{
            //  customerUsername: [student1, student2],
            //  customerUsername2: [Ray, ...],
            //  ...
            //{
            for (var customer in params.permissions) {
                var students = params.permissions[customer]
                for (var student of students) {
                    for (var permission of shared) {
                        if (permission.studentID === student && permission.customer === customer) {
                            permissions.push(permission)
                        }
                    }
                }
            }

            this.setState({ permissions: permissions })


        }).catch((err) => {
            message.error(err)
        }).finally(() => this.setState({ submitting: false }))

        initializeFormValidation(this.onSubmit);
    }

    onSubmit = () => {
        this.setState({ submitting: true }, async () => {
            const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
            const promises = []

            for (const customerUsername in params.permissions) {
                const studentsIds = params.permissions[customerUsername]
                for (const studentID of studentsIds) {
                    promises.push(modelRequest.delete('/api/students/share', {
                        data: {
                            studentID,
                            customerUsername
                        }
                    }))
                }
            }
            Promise.all(promises).then((responses) => {
                for (var response of responses) {
                    if (response.data === false) {
                        return redirect.send('/students/share/manage', this.props, () => {
                            message.error('There has been an internal error.')
                        })
                    }
                }
                redirect.send('/students', this.props, () => {
                    message.success('Permissions have been successfully revoked.')
                })
            }).catch((err) => {
                message.error(err)
            }).finally(() => this.setState({ submitting: false }))

        })
    }

    render() {
        var { permissions } = this.state
        return (
            <React.Fragment>
                <h1 className='text-center mt-3'>Revoke Student Sharing Setting{permissions.length > 1 ? "s" : ""}</h1>
                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Please confirm revoking existing sharing settings below.</p>
                    </div>
                </div>

                <form className="form-horizontal form-validate text-center" noValidate method="post">
                    <label className="h5 mb-3">Setting{permissions.length > 1 ? "s" : ""}</label>
                    {
                        permissions.map((permission) => {
                            var permissionName
                            if (permission.read && permission.write)
                                permissionName = "all"
                            else if (permission.read)
                                permissionName = "read"
                            else if (permission.write)
                                permissionName = "write"

                            return (
                                <React.Fragment>
                                    <p className="form-control-static text-center-xs break-words">
                                        <strong>{permission.student}</strong> shared to <strong>{permission.customer}</strong> with <strong>{permissionName}</strong> permissions
                                    </p>
                                </React.Fragment>
                            )
                        })
                    }

                    <Button type="submit" className="btn-lg">{this.state.submitting ?
                        <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.submitting}
                        /> : "Revoke"}
                    </Button>
                </form>
            </React.Fragment>
        )
    }
}

export default Revoke
