import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from '../../../components/forms/Form'
import SubmitButton from '../../../components/forms/SubmitButton'
import message from '../../../components/message/message'
import * as organizationUsers from '../../../shared/routes/organizationUsersRoutes'
import * as organizationJoinGroup from '../../../shared/routes/organizationJoinGroupRoutes'
import { Instructor } from '../../../shared/types/orgUserTypes'

interface InstructorWithGroup extends Instructor {
    groupName: string
}

interface IState {
    instructors: InstructorWithGroup[]
}

export default class AssignUser extends React.Component<{ groupName: any, submitCallback: any }, IState> {

    readonly state: IState = {
        instructors: []
    }

    componentDidMount() {
        let name: string = this.props.groupName;
        organizationUsers.get().then((users) => {
            let instructors = []
            let usernameSet = new Set<string>()

            let addToInstructors = (groupName: string) => {
                for (let instructor of groupMap[groupName]) {
                    if (!usernameSet.has(instructor.username)) {
                        instructors.push({
                            name: instructor.name,
                            username: instructor.username,
                            permission: instructor.permissions,
                            groupName: groupName
                        })
                        usernameSet.add(instructor.username)
                    }
                }
            }

            //Put groups in hashmap
            //groupMap[GROUP_NAME] = INSTRUCTORS ARRAY
            let groupMap = new Map<string, Instructor[]>()
            for (let group of users.groups) {
                groupMap[group.name] = group.instructors
            }
            //First add the instructors that are in the group in question
            addToInstructors(name)

            //add rest of groups to instructor list
            for (let group of users.groups) {
                if (group.name !== name) {
                    addToInstructors(group.name)
                }
            }


            this.setState({ instructors: instructors })
        })
    }

    submit = (values) => {
        let name: string = this.props.groupName
        let instructors = []
        let managers = []
        let licensees = []

        let usernames = values['usernames[]']
        let permissions = values['permissions[]']
        if (!NodeList.prototype.isPrototypeOf(usernames)) {
            usernames = [usernames]
        }

        if (!NodeList.prototype.isPrototypeOf(permissions)) {
            permissions = [permissions]
        }

        for (let i = 0; i < usernames.length; i++) {
            let username = usernames[i]
            let permission = permissions[i]
            if (username.checked) {
                if (permission.value === 'Instructor') {
                    instructors.push(username.value)
                }
                else if (permission.value === "Manager") {
                    managers.push(username.value)
                }
                else {
                    licensees.push(username.value)
                }
            }
        }

        let promises = []

        if (instructors.length > 0) {
            promises.push(organizationJoinGroup.post(name, instructors, 'Instructor'))
        }

        if (managers.length > 0) {
            promises.push(organizationJoinGroup.post(name, managers, 'Manager'))
        }

        if (licensees.length > 0) {
            promises.push(organizationJoinGroup.post(name, licensees, 'Licensee'))
        }

        return Promise.all(promises).then((response) => {
            this.props.submitCallback()
        }).catch((err) => {
            return message.error(err)
        })
    }

    render() {
        let { instructors } = this.state
        let name: string = this.props.groupName
        return (
            <Col lg={10} className='mx-auto'>
                <Form submit={this.submit}>
                    {submitting => {
                        return (<>
                            <ul id="checkbox-list" className="list-group text-start mb-3">
                                {instructors.map((instructor) => {
                                    return <li className="list-group-item action-list student-list" key={instructor.name} >
                                        <input
                                            className="checkbox-list-item"
                                            type="checkbox"
                                            id={"checkbox-" + instructor.name}
                                            name="usernames[]"
                                            value={instructor.username}
                                            defaultChecked={name === instructor.groupName} />
                                        <label className="break-words student-name" role="button">{instructor.name} ({instructor.username})</label>
                                        <select className="m-3" name="permissions[]" defaultValue={instructor.permission}>
                                            <option value="Instructor" selected={instructor.permission === 'Instructor'}>Instructor</option>
                                            <option value="Manager" selected={instructor.permission === 'Manager'}>Manager</option>
                                            <option value="Licensee" selected={instructor.permission === 'Licensee'}>Licensee</option>
                                        </select>
                                    </li>
                                })}
                            </ul>
                            {/*
                            <div className = "row">
                                <div className = "col-12 text-start">
                                    <input className = "checkbox-shortcut" type = "checkbox" id = "checkbox-shortcut" name = "shortcut" />

                                    <label role = "button">Select all</label>
                                </div>
                            </div> */}
                            <Row className="justify-content-center">
                                <SubmitButton submitting={submitting}>Assign</SubmitButton>
                            </Row>
                        </>)
                    }}
                </Form>
            </Col>
        )
    }

}
