import React from "react"
import { Link } from "react-router-dom"
import { useModel, FormikControl } from "@stem-sims/nexus"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { Formik, Form } from "formik"
import { ClipLoader } from "react-spinners"
import { toast } from "react-toastify"
import * as Yup from "yup"
import * as statusModel from '../../shared/routes/statusRoutes'
import ExpirationMessage, { ExpiringMessage } from "../../components/ExpirationMessage"
import StudentRoster from "../../components/StudentRoster"
import DashboardRoster from "./components/DashboardRoster"
import { modelRequest } from '@stem-sims/nexus'

export default function Dashboard({ org }: { org: string }) {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { response: status, loading: statusLoading, error } = useModel({
        model: statusModel.get
    })
    const readingStatus = status?.reading
    const mathStatus = status?.math
    const validLicenses = []
    if (readingStatus?.available >= 0) {
        validLicenses.push("Reading")
    }
    if (mathStatus?.available >= 0) {
        validLicenses.push("Math")
    }
    if (status?.emergentReader?.available >= 0) {
        validLicenses.push("Emergent Reader")
    }
    return <>
        <ExpirationMessage status={mathStatus} program="math" />
        <ExpirationMessage status={readingStatus} program="reading" />
        {status && <ExpiringMessage expiringSoon={status.expiringSoon} />}
        <Container id="dashboard" fluid="lg" className='align-self-center pt-3 px-xl-3'>
            <Row className="mb-4 align-items-center mt-4">
                <Col md={4} xl={6}>
                    <h1 className=" mb-0">
                        <span className="d-none d-xl-inline">Great Leaps </span>
                        <span>Dashboard</span>
                    </h1>
                </Col>
                <Col md={8} xl={6} className="text-end gx-0">
                    <Link to="/help/frequently-asked-questions" className="btn btn-outline-success px-5">FAQ</Link>
                    <a href="https://sites.google.com/greatleaps.com/helpcenter/" target="_blank" rel="noreferrer" className= "btn btn-outline-success mx-3">
                        Help Center
                    </a>
                </Col>
            </Row>
            <Row>
                <h2 className="section-header h3">Welcome {org}!</h2>
            </Row>
            <Row id="student-roster" className="align-items-center text-center pt-3 px-xl-5">
                <Col md={5}>
                    <p id="student-search-text" className="mb-0">To begin an exercise, select student from the list below.</p>
                </Col>
                <StudentRoster Roster={DashboardRoster} validLicenses={validLicenses} />
            </Row>
            <p className="text-center w-100">
                To add or edit students, please proceed to your <Link to="/students">students page</Link>.
            </p>

            <h2 className="section-header text-start">Helpful Videos</h2>

            <div id="home-carousel" className="carousel slide indicators-bottom center-block" data-ride="carousel" data-interval="false">
                <div className="carousel-inner" role="listbox">
                    <div className="carousel-item active">
                        <Row>
                            <Col sm={4}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube.com/embed/BKXw9CJdebk?si=Wcu6_cLb92GNYCZW" title="video1" allowFullScreen></iframe>
                                </div>
                            </Col>

                            <Col sm={4}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube.com/embed/YOWlwu7AfYU?si=n1PIqW9IygCP32gn" title="video2" allowFullScreen></iframe>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube.com/embed/rOeuLQ0Gc60?si=3-nNLjRh6qRnKSVl" title="video3" allowFullScreen></iframe>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="carousel-item">
                        <Row>
                            <Col sm={4}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube-nocookie.com/embed/h51TfIYyZ1A?rel=0" title="video4" allowFullScreen></iframe>
                                </div>
                            </Col>

                            <Col sm={4}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube-nocookie.com/embed/zU0xDgUO5aI?rel=0" title="video5" allowFullScreen></iframe>
                                </div>
                            </Col>

                            <Col sm={4}>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe src="https://www.youtube-nocookie.com/embed/PVqHt6r1Wjw?rel=0" title="video6" allowFullScreen></iframe>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="carousel-controls carousel-controls-logged-in">
                    <Row>
                        <Col sm={4}>
                            <a className="carousel-left carousel-control" href="#home-carousel" data-slide="prev">
                                <span className="fas fa-chevron-left video-arrow"></span>
                            </a>
                        </Col>
                        <Col sm={4}>
                            <ol className="carousel-indicators carousel-indicators-logged-in indicators-bottom">
                                <li data-target="#home-carousel" data-pause="true" data-slide-to="0" className="active"></li>

                                <li data-target="#home-carousel" data-slide-to="1"></li>
                            </ol>
                        </Col>
                        <Col sm={4}>
                            <a className="carousel-right carousel-control" href="#home-carousel" data-slide="next">
                                <span className="fas fa-chevron-right video-arrow"></span>
                            </a>
                        </Col>
                    </Row>
                </div>
            </div>
            <ReferralForm />
            <UpdateModal />
        </Container>
    </>
}

function ReferralForm() {
    return (<>
        <h2 className="share-title-container text-center">
            <span className="share-title d-none d-sm-inline">Share Great Leaps with a student in need!</span>
            <span className="d-block d-sm-none">Share Great Leaps with a student in need!</span>
        </h2>
        <p className="text-center">Share Great Leaps Digital with your fellow parents, teachers, and tutors by entering their emails below and we'll send them an invitation.</p>
        <Formik
            initialValues={{
                referralEmails: ""
            }}
            validationSchema={
                Yup.object().shape({
                    referralEmails: Yup.string().label("Emails").required()
                })
            }
            onSubmit={async (values, actions) => {
                modelRequest.post("/api/account/referral", {
                    emails: values.referralEmails
                }).then(() => {
                    actions.resetForm()
                    toast.success("Your referral has been sent! Thank you.")
                }).catch((err) => {
                    toast.error(err)
                })
            }}
        >
            {({ isSubmitting }) => (
                <Form className="form-horizontal form-validate">
                    <Row className="mb-3">
                        <Col className="offset-3 col-6">
                            <FormikControl
                                type="text"
                                className="form-control"
                                id="referralEmails"
                                name="referralEmails"
                                placeholder="Enter their emails separated with a comma"
                            />
                        </Col>
                        <Col>
                            <Button
                                type="submit"
                                variant="secondary"
                                className="btn-md text-center mt-0"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ?
                                    <ClipLoader size={20} color={"#123abc"} />
                                    :
                                    "Send"
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>

    </>)
}

function UpdateModal() {
    const [showUpdate, setShowUpdate] = React.useState(false)
    React.useEffect(() => {
        modelRequest.get("/api/update").then((response) => {
            setShowUpdate(!response.seen)
        }).catch((err) => {
            
        })
    })

    return (
        <Modal show={showUpdate} size={"lg"}>
            <Modal.Header className="d-flex flex-column">
                <h2 className="modal-title text-center w-100" id="stop-modal-label">Exciting Updates to Great Leaps Digital</h2>
                <h2 className="modal-title text-center w-100">NEW PHONOLOGICAL AWARENESS PROGRAM</h2>
            </Modal.Header>

            <Modal.Body className="ps-5">
                <div className="text-start">
                    <p>Introducing the Great Leaps Emergent Reader program! This includes our Alphabet and Sound Awareness sections.</p>
                    <b>What it Teaches:</b>
                    <ul>
                        <li>Letter Names</li>
                        <li>Letter Sounds</li>
                        <li>Phonological Awareness (Phonemic and Morphemic)</li>
                        <li>Vocabulary</li>
                        <li>Expressive Language Development</li>
                    </ul>
                    <b>Who It's For:</b>
                    <ul>
                        <li>All Students Ages 3-8</li>
                        <li>English Language Learners</li>
                        <li>Students With Deficits in Phonemic Awareness</li>
                    </ul>
                </div>
                <p>All users will be given Emergent Reader licenses until the end of your current reading subscription. Go to the “Manage Students” to assign the licenses to the appropriate students.</p>
                <p>As always if you have any questions feel free to contact our customer service department at <a href="mailto:glsupport@greatleaps.com">glinfo@greatleaps.com</a> or give us a call at <span className="text-center">(877)&nbsp;475-3277</span></p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" className="center-block" data-dismiss="modal" onClick={() => {
                    void modelRequest.post("/api/update")
                    setShowUpdate(false)
                }}>Okay, got it.</Button>
            </Modal.Footer>
        </Modal>
    )
}
