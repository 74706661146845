import React from 'react';
import { toast } from 'react-toastify'
import ReactGA from 'react-ga4'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import getPriceData, { PriceData } from '../../helpers/getPriceData'
import { loadStripe } from '@stripe/stripe-js'
import Common from '../../context/Common'
import PriceCard from './PriceCard'
import LoggedOutLinks from './LoggedOutLinks'
import LoggedInLinks from './LoggedInLinks'
import dolphin from '../../content/images/dolphin_surface_full.svg'
import fishLeft from '../../content/images/left_fish.svg'
import fishRight from '../../content/images/right_fish.svg'
import { Form as FormikForm, useFormik, FormikProvider } from 'formik';
import { FormikControl } from '@stem-sims/nexus'
import stringToFloat from '../../helpers/stringToFloat';
import { getURLParams } from '@stem-sims/great-leaps-react-common';
import { Header } from './Header';
import MathCard from './MathCard';
import ReadingCard from './ReadingCard';
import { modelRequest } from '@stem-sims/nexus'
import EmergentReaderCard from './EmergentReaderCard';

declare const window: any;

type SubscribeProps = {
    purchaseView: "add" | "extend" | "default",
    updatePermissions: () => any
}
const trainingFeatures = [
    'Equip your instructors to implement the Great Leaps Digital Reading program with fidelity',
    'Instructors receive certificate of completion after meeting the requirements of the final assessment',
    'Instructors have 60 days to work through the asynchronous training',
]

function Subscribe({ purchaseView, updatePermissions }: SubscribeProps) {
    const { math, reading, emergentReader, start, created, exp, discount } = getURLParams() as { math?: string, reading?: string, emergentReader: string, start?: string, exp?: string, created?: string, discount?: string }
    const initialMathLicenses = math ? parseInt(math) : 0
    const initialReadingLicenses = reading ? parseInt(reading) : 0
    const initialEmergentReaderLicenses = emergentReader ? parseInt(emergentReader) : 0
    const formik = useFormik({
        initialValues: {
            math: initialMathLicenses,
            reading: initialReadingLicenses,
            emergentReader: initialEmergentReaderLicenses,
            discountCode: discount ?? ""
        },
        onSubmit: async (values) => {
            try {
                const [started, stripe] = await Promise.all([
                    modelRequest.post('/api/transaction/started', {
                        'products': {
                            'Reading Subscription': values.reading,
                            'Math Subscription': values.math,
                            'Emergent Reader Subscription': values.emergentReader,
                            'Training': 0,
                            'Verification': 0,
                        },
                        'discountCode': values.discountCode,
                        'extendCreated': {
                            created: created
                        }
                    }),
                    loadStripe(process.env.REACT_APP_STRIPE_PUBLICKEY)
                ])
                ReactGA.event({
                    category: 'digitalCheckout',
                    action: 'begin_checkout',
                    label: 'button'
                })

                stripe.redirectToCheckout({
                    sessionId: started['checkoutID']
                })
            } catch (error) {
                toast.error(error ?? "There has been an error starting your transaction.")
            }
        }
    })

    const context = React.useContext(Common)

    const [totalPrice, setTotalPrice] = React.useState("0")
    const [subtotals, setSubtotals] = React.useState<{ math?: number, reading?: number, emergentReader?: number }>({})
    const [trainingPrice, setTrainingPrice] = React.useState(null)
    const [discountCodeMessage, setDiscountCodeMessage] = React.useState<PriceData["discountCodeMessage"]>({})
    const [hasScrolled, setHasScrolled] = React.useState(false)
    const [authenticationModal, setAuthenticationModal] = React.useState(false)

    const syncPrice = () => {
        return getPriceData({
            // Until the onBlur processing happens, the fields are either strings or numbers
            reading: Math.ceil(stringToFloat(formik.values.reading as string | number)),
            math: Math.ceil(stringToFloat(formik.values.math as string | number)),
            emergentReader: Math.ceil(stringToFloat(formik.values.emergentReader as string | number)) | 0,
            discountCode: formik.values.discountCode
        }).then((response) => {
            const rounding = Number.isInteger(response.price) ? 0 : 2
            setTotalPrice(response.price.toFixed(rounding))
            setSubtotals(response.subTotals)
            setTrainingPrice(response.prices.training)
            setDiscountCodeMessage(response.discountCodeMessage)
        })
    }

    React.useEffect(() => {
        syncPrice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.math, formik.values.reading, formik.values.emergentReader])

    //Sync the price after the user has stopped typing
    React.useEffect(() => {
        const timeOut = setTimeout(() => {
            syncPrice()
        }, 600)
        return () => {
            clearTimeout(timeOut)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.discountCode])

    React.useEffect(() => {
        const onScroll = () => {
            setHasScrolled(true)
        }
        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])


    return (
        <div id='subscribe'>
            {hasScrolled &&
                <>
                    <div className="bubble x1"></div>
                    <div className="bubble x2"></div>
                    <div className="bubble x3"></div>
                    <div className="bubble x4"></div>
                    <div className="bubble x5"></div>
                    <div className="bubble x6"></div>
                    <div className="bubble x7"></div>
                    <div className="bubble x8"></div>
                </>
            }
            <link rel="stylesheet" type="text/css" href="/styles/subscribe.css?version=8-28-2021" />
            <Row className={'col-md-11 col-lg-9 col-xl-8 justify-content-between align-items-center mx-auto my-5'}>
                <Col sm='7' md='6' lg='5' className={'justify-content-start text-start mt-3'}>
                    {
                        purchaseView === "extend" && start !== null ?
                            <Header.Extend
                                mathLicenses={initialMathLicenses}
                                readingLicenses={initialReadingLicenses}
                                exp={exp}
                            />
                            : purchaseView === "add" ?
                                <Header.Add />
                                :
                                <Header.Default />
                    }
                </Col>
                <Col sm='5' className={'dolphin-pricing d-flex justify-content-end'}>
                    <img src={dolphin} className={'img-fluid '} alt={"A dolphin rising and falling in the waves"} />
                </Col>
            </Row>
            <br />
            <FormikProvider value={formik}>
                <FormikForm className="form-horizontal" id="form-subscribe">
                    <div className="water-surface d-flex flex-column mt-4">
                        <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none">
                            <defs>
                                <linearGradient id="gradient">
                                    <stop offset="5%" stopColor="#9ACFFF1A" />
                                    <stop offset="95%" stopColor="#9ACFFF11" />
                                </linearGradient>
                                <path id="gentle-wave" fill="url(#gradient)" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" />
                                <use href="#gentle-wave" x="48" y="3" />
                                <use href="#gentle-wave" x="48" y="5" />
                                <use href="#gentle-wave" x="48" y="7" />
                            </g>
                        </svg>
                        <img src={fishLeft} className={'fish-left img-fluid'} alt="Cartoon fish swimming across screen in background" />
                        <h2 className="h4 text-center pt-5"><strong>Annual Subscription Programs</strong></h2>
                        <Row className='col-md-11 col-lg-10 col-xl-8 justify-content-around mx-auto'>
                            <ReadingCard readingInput={Math.ceil(stringToFloat(formik.values.reading as string | number))} />
                            <MathCard mathInput={Math.ceil(stringToFloat(formik.values.math as string | number))} />
                            <EmergentReaderCard emergentReaderInput={Math.ceil(stringToFloat(formik.values.emergentReader as string | number))} />
                        </Row>
                        <img src={fishRight} className={'fish-right img-fluid align-self-end'} alt="Cartoon fish swimming across screen from the right side" />
                    </div>
                    <h2 className="h4 text-center my-3"><strong>Extras</strong></h2>
                    <Row className={'justify-content-center'}>
                        <PriceCard
                            title={'Training'}
                            features={trainingFeatures}
                            price={trainingPrice}
                            priceUnit={'/Per 3 Instructors'}
                            color={'gray'}
                            linkTo={"https://greatleaps.com/products/gl-digital-reading-instructor-training"}
                        />
                    </Row>
                    <Card className='col-8 offset-2 my-5'>
                        <Card.Body className='text-center justify-content-center'>
                            <div className={"mb-3 text-center justify-content-center mt-3"}>
                                <h2><strong>Checkout</strong></h2>
                                <Row className="pt-5"></Row>
                                {subtotals.math > 0 &&
                                    <Row className="mb-3">
                                        <label className="col-6 control-label text-end radio bold text-end"><strong>Math Subtotal:</strong></label>
                                        <div id="price-group" className="col-6 text-start">
                                            ${subtotals.math}
                                        </div>
                                    </Row>
                                }
                                {subtotals.reading > 0 &&
                                    <Row className="mb-3">
                                        <label className="col-6 control-label text-end radio bold text-end"><strong>Reading Subtotal:</strong></label>
                                        <div id="price-group" className="col-6 text-start">
                                            ${subtotals.reading}
                                        </div>
                                    </Row>
                                }
                                {subtotals.emergentReader > 0 &&
                                    <Row className="mb-3">
                                        <label className="col-6 control-label text-end radio bold text-end"><strong>Emergent Reader Subtotal:</strong></label>
                                        <div id="price-group" className="col-6 text-start">
                                            ${subtotals.emergentReader}
                                        </div>
                                    </Row>
                                }
                                <Row className="justify-content-center">
                                    <Col md="4" className=" control-label text-md-right">
                                        <label htmlFor="discount-code" ><strong>Discount Code</strong></label>
                                    </Col>
                                    <Col md="4" className='mb-3'>
                                        <FormikControl
                                            type="text"
                                            className="form-control string"
                                            id="discount-code"
                                            name="discountCode"
                                            placeholder="Code"
                                            minLength={1}
                                            maxLength={175}
                                            data-description="enter a discount code"
                                            data-amount="0"
                                            data-code-type="fixed"
                                            data-minimum="0"
                                            data-maximum="0"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <div id="status-group" className="col-xxs-12 col-xxs-offset-0 col-6 offset-3 offset-sm-3 offset-md-3 col-md-6">
                                        <label className={discountCodeMessage.messageType}>
                                            {discountCodeMessage.message}
                                        </label>
                                    </div>
                                </Row>
                            </div>
                            <Row className="mb-3">
                                <label className="col-6 control-label text-end radio bold text-end"><strong>Total:</strong></label>
                                <div id="price-group" className="col-6 text-start">
                                    ${totalPrice}
                                </div>
                            </Row>
                            <Row className="justify-content-center">
                                <hr className='col-10 col-md-6' />
                            </Row>
                            <Row>
                                <div className="subscribe-submit col-12">
                                    <div className="mb-3" id="offline-purchase">
                                        <div>
                                            <p>Purchase orders are accepted.</p>

                                            <p>Contact us using our <a href="https://greatleaps.com/index.php?main_page=contact_us">contact form</a> for a quote and full payment options.</p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            {context?.auth?.includes("customer") ?
                                <LoggedInLinks
                                    submitting={formik.isSubmitting}
                                    purchaseView={purchaseView}
                                    readingLicenses={formik.values.reading}
                                    extendSubscription={{
                                        mathLicenses: initialMathLicenses,
                                        readingLicenses: initialReadingLicenses,
                                        exp
                                    }} />
                                :
                                <LoggedOutLinks
                                    purchaseView={purchaseView}
                                    authenticationModal={authenticationModal}
                                    setAuthenticationModal={setAuthenticationModal}
                                    submitting={formik.isSubmitting} />
                            }
                        </Card.Body>
                    </Card>
                    <div className="mb-3 prefooter-text p-5 alt-background-color mx-auto w-50 rounded">
                        <div className="col-12  text-center">
                            <strong className='h5'>Contact us for pricing at <span className='gl-blue'>877-475-3277</span></strong>
                            <p className='mt-3'>If you have any problems purchasing a Great Leaps Digital subscription, <br/>please reach out with our <a className='text-decoration-none' href="https://greatleaps.com/index.php?main_page=contact_us">contact form</a>.</p>
                        </div>
                    </div>
                </FormikForm>
            </FormikProvider>
        </div>
    )
}

export default Subscribe;
