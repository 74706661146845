import React from 'react'
import redirect from '../../../../components/redirect/redirect'
import message from '../../../../components/message/message'
import { initializeFormValidation } from '../../../../helpers/forms/form-validation'
import { modelRequest } from '@stem-sims/nexus'

class Share extends React.Component {
    state = {
        loading: true,
        submitting: false,
        maxStudents: null
    }
    componentDidMount() {
        modelRequest.get("/api/status").then((res) => {

            if (res.available === 0) {
                return redirect.send("/account/subscription", this.props, () => {
                    message.error("You don't have any subscriptions available to share.")
                })
            }

            this.setState({ loading: false, maxStudents: res.available })
        }).catch((err) => {
            return redirect.send("/account/subscription", this.props, () => {
                message.error("There has been an error loading share students. Please try again.")
            })
        })

        initializeFormValidation(this.onSubmit)
    }

    onSubmit = () => {
        this.setState({ submitting: true }, () => {
            modelRequest.post("/api/subscription/share", {
                'students': document.getElementById('students').value,
                'username': document.getElementById('username').value
            })
                .then((res) => {
                    message.clear()
                    return redirect.send("/account/subscription", this.props, () => {
                        message.success("Successfully shared.")
                    })
                }).catch((err) => {
                    message.error(err)
                }).finally(() => this.setState({ submitting: false }))
        })
    }

    render() {
        return (
            <React.Fragment>

                <h1>Share Subscription</h1>

                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Please enter the username of the person with whom you wish to share your subscription, along with the number of students you wish to give them.</p>
                    </div>
                </div>

                <form className="form-horizontal form-validate" noValidate method="post">
                    <div className="mb-3 row">
                        <label for="username" className="d-none d-sm-inline col-sm-3 control-label text-end">Username</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" disabled={this.state.loading || this.state.submitting} id="username" name="username" placeholder="Username" required autofocus data-description="enter a username" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="students" className="col-sm-3 control-label text-end">Students</label>

                        <div className="col-sm-6">
                            <input type="number" className="form-control" disabled={this.state.loading || this.state.submitting} id="students" name="students" defaultValue="1" min="1" step="1" max={this.state.maxStudents} required data-description="enter the number of students to share" />
                        </div>
                    </div>

                    <button type="submit" className="btn btn-secondary btn-lg" disabled={this.state.loading || this.state.submitting}>Share</button>
                </form>
            </React.Fragment>
        )
    }
}

export default Share
