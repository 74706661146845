import React from 'react'
import redirect from '../../../components/redirect/redirect'
import message from '../../../components/message/message'
import { initializeFormValidation } from '../../../helpers/forms/form-validation'
import ClipLoader from 'react-spinners/ClipLoader'
import { modelRequest } from '@stem-sims/nexus'

class Transfer extends React.Component {
    state = {
        studentNames: [],
        studentIDs: [],
        loading: false
    }
    componentDidMount() {
        var params = this.props?.location?.state

        if (!params.students) {
            redirect.send("/students", this.props, () => {
                message.error("Please select a student to be transferred.")
            })
            return
        }

        let names = []
        let ids = []

        for (let student of params.students) {
            names.push(student.name)
            ids.push(student.id)
        }


        this.setState({ studentIDs: ids, studentNames: names })
        initializeFormValidation(this.onSubmit)
    }

    onSubmit = () => {
        this.setState({ loading: true }, () => {
            modelRequest.post('/api/students/transfer/send', {
                students: this.state.studentIDs,
                email: document.getElementById('email-address').value
            }).then((response) => {
                message.clear()
                redirect.send("/students", this.props)
                message.success("Your student transfer has been successfully completed. Please ask the recipient to check their email for a transfer request.")
            }).catch((err) => {
                message.error(err)
            }).finally(() => this.setState({ loading: false }))
        })
    }

    render() {
        var { studentNames } = this.state
        return (
            <React.Fragment>
                <h1 className='text-center'>Transfer Student{studentNames.length > 1 ? "s" : ""}</h1>
                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Please enter the username of the person to whom you wish to transfer students.</p>

                        <p>Please note that transferring a student is <strong>permanent</strong>, and will result in the student and all of their records being removed from your account and added to the other user's.</p>
                    </div>
                </div>

                <form className="form-horizontal form-validate" noValidate method="post">
                    <div className="mb-3 row">
                        <label className="text-center-xs bold-xs col-sm-3 control-label text-end">Student{studentNames.length > 1 ? "s" : ""}</label>

                        <div className="col-sm-6">
                            {
                                studentNames.map((student) => {
                                    return <p className="form-control-static text-start text-center-xs break-words">{student}</p>
                                })
                            }
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="username" className="d-none d-sm-inline col-sm-3 control-label text-end">Email Address</label>

                        <div className="col-sm-6">
                            <input type="text" disabled={this.state.loading} className="form-control" id="email-address" name="email-address" placeholder="Email Address" required autofocus data-description="enter an email address" />
                        </div>
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">{this.state.loading ?
                        <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Transfer"}</button>
                </form>
            </React.Fragment>
        )
    }
}

export default Transfer
