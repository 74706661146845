import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import { useFormikContext } from 'formik'
import { camelCaseToCapital } from "../../../../shared/commonHelpers"
import { InputGroup } from "react-bootstrap"
import { FormikControl } from "@stem-sims/nexus"

type FormInputProps = {
    fieldName: string
    groupText?: string
    label?: string
    onChange?: (event, formik) => void
    disabled?: boolean
    required?: boolean
    children: any
}

export default function FormSelect({fieldName, label, onChange, children, ...rest}: FormInputProps) {
    const formik = useFormikContext<unknown>()

    const onChangeWithFormik = (event) => {
        formik.handleChange(event)
        if(onChange) onChange(event, formik)
    }

    return <>
        <Row className="mb-3">
            <Form.Label htmlFor={fieldName} className="d-none d-sm-inline col-sm-3 text-end">{label ?? camelCaseToCapital(fieldName)}</Form.Label>
            <Col sm={6}>
                <InputGroup>
                    <FormikControl 
                        as="select" 
                        name={fieldName} 
                        onChange={onChangeWithFormik} 
                        data-description={`select your ${camelCaseToCapital(fieldName)?.toLowerCase()}`}
                        {...rest}
                    >
                        {children}
                    </FormikControl>
                </InputGroup>
            </Col>
        </Row>
    </>
}
