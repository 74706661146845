import React from 'react';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';

import ClipLoader from "react-spinners/ClipLoader";
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import getURLParams from '../../../helpers/getURLParams';
import { modelRequest } from '@stem-sims/nexus';

class SendSalesCodeEmail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            "email-address": "",
            code: "",

            loading: false
        }
    }

    componentDidMount() {
        initializeFormValidation();

        initializeAutosubmit();
        initializeTextAreaSubmit();
        initializeSubmissionPrevention();

        let params = getURLParams(this.props);

        if (params.name) {
            this.setState({ name: params.name })
        }

        if (params["email-address"]) {
            this.setState({ "email-address": params["email-address"] })
        } // or get email from API instead?

        if (params.code) {
            this.setState({ code: params.code })
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        this.setState({ [name]: value });
    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var generateCodeForm = document.getElementById("email-sales-code-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(generateCodeForm, blurEvent) === false) {
            return
        }

        this.setState({ loading: true }, () => {
            modelRequest.post("" /* todo */, {
                name: this.state.name,
                emailAddress: this.state["email-address"],
                code: this.state.code
            }).then(({ data }) => {
                redirect.send("/admin/sales-code/send-email/success")
                message.success("The sales code email was successfully sent.")
            }).catch((error) => {
                return message.error(error ?? "There has been an internal error.")
            }).finally(() => this.setState({ loading: false }))
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1>Send Sales Code Email</h1>

                <form id="email-sales-code-form" className="form-horizontal form-validate" noValidate onSubmit={this.submitForm} method="post">
                    <div className="mb-3 row">
                        <label for="name" className="hidden-xs col-sm-3 control-label text-end">Name</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="name" name="name" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.name} placeholder="Name" required data-description="enter the customer's name" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="email-address" className="hidden-xs col-sm-3 control-label text-end">Email&nbsp;Address</label>

                        <div className="col-sm-6">
                            <input type="email" className="form-control" id="email-address" name="email-address" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state['email-address']} placeholder="Email Address" required data-description="enter the customer's email address" />
                        </div>
                    </div>


                    <div className="mb-3 row">
                        <label for="code" className="hidden-xs col-sm-3 control-label text-end">Code</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="code" name="code" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.code} placeholder="Code" required data-description="enter the sales code" />
                        </div>
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">
                        {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Send"}
                    </button>
                </form>
            </React.Fragment>
        )
    }

}

export default SendSalesCodeEmail;
