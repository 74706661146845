export function camelCaseToCapital(text: string): string {
  if(!text) return ""
  const result = text.replace(/([A-Z])/g, " $1");
  const capitalCase = result.charAt(0).toUpperCase() + result.slice(1)
  return capitalCase
}

export function programFormatter(str) {
  if(str[0].toLowerCase() !== "e") return str.toLowerCase()
  return "emergentReader"
}
