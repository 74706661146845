import React from 'react'
import { Link } from 'react-router-dom'
import { LicenseStatus } from '../shared/types/subscriptionTypes'
import { Subscription } from '../shared/types/subscriptionTypes' 

type SubByDate = {
    expirationDate: number,
    subs: Subscription[]
}

export function ExpirationMessage({ status, program }: { status: LicenseStatus, program: "math" | "reading" | "emergent reader" }) {
    const activeStudentsExist = status?.active > 0
    return <>
        {status?.available < 0 && <>
            {activeStudentsExist ?
                <div className ="alert alert-danger">
                    Due to your <strong>{program}</strong> subscription expiring, you have exceeded the number of students allowed for {program}. 
                    Your student data has been preserved, but you will not have access to start {program} exercises with your students until you
                    <Link to="/students"> delete or archive {Math.abs(status.available)} {program} licenses </Link> below or
                    <Link to="/subscribe/choose"> purchase more {program} licenses </Link> 
                    to continue using the platform.
                </div>
                :
                <div className ="alert alert-danger">
                    Your <strong>{program} </strong> 
                    <span>subscription has expired you must </span>
                    <Link to="/subscribe/choose">purchase more students </Link> 
                    <span>to continue using the platform for {program}.</span>
                </div>
            }
        </>}
    </>
}
export function ExpiringMessage({ expiringSoon }: { expiringSoon: Subscription[] }) {

    function groupByDate(subscriptions, key) { 
        return subscriptions?.reduce(function (acc: SubByDate[], subscription) { 
            const expirationDate = new Date(subscription[key]).getTime()
            const existingDate = acc.find((r) => r && r.expirationDate === expirationDate)
            if (existingDate) existingDate.subs.push(subscription)
            else acc.push({ expirationDate: expirationDate, subs: [subscription] })
            return acc
        }, [])
    }
    
    const expiringSubsByDate = groupByDate(expiringSoon,"expiration")
    const extendSearchParams = (sub: Subscription) => new URLSearchParams({
        math: sub.mathLicenses.toString(),
        reading: sub.readingLicenses.toString(),
        start: sub.start.toString(),
        exp: sub.expiration.toString(),
        created: sub.created.toString(),
    })
    return <>
        {expiringSubsByDate?.map(({expirationDate, subs}) => <>
            <div className ="alert alert-warning">
                <span>Your subscription will expire on {new Date(expirationDate).toLocaleDateString()}. </span>
                {subs.length === 1 && subs[0].extended !== "Yes" ? // if there is only one subscription and it has not been extended 
                    <Link to={`/subscribe/extend?${extendSearchParams(subs[0])}`}>Extend your subscription soon </Link> 
                : 
                    <Link to={`/subscribe/choose`}>Extend your subscription soon </Link>
                }
                <span>to continue using the platform.</span>
            </div>
        </>)}
    </>
}

export default ExpirationMessage
