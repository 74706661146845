import React, { useEffect } from 'react'
import PriceCard from "./PriceCard"
import emergentReaderIcon from '../../content/images/emergent-reader.png'
import getPriceData from '../../helpers/getPriceData'

type EmergentReaderProps = {
    emergentReaderInput?: number
}

export default function EmergentReaderCard({emergentReaderInput}: EmergentReaderProps) {
    const [emergentReaderPrice, setEmergentReaderPrice] = React.useState(null)

    useEffect(() => {
        getPriceData({emergentReader: emergentReaderInput}).then((response) => {
            setEmergentReaderPrice(response.prices.emergentReader)
        })
    },[emergentReaderInput])

    const emergentReaderFeatures = [
        'Includes Phonological Awareness and language development activities in addition to the Alphabet program to teach letter names and sounds in isolation',
        'Develops vocabulary and world knowledge through the daily expressive language activities',
        'Rooted in the science of reading and precision teaching to bring phonemic awareness skills to mastery'
    ]
    return (<>
        <PriceCard
            title={'Emergent Reader'}
            icon={emergentReaderIcon}
            features={emergentReaderFeatures}
            price={emergentReaderPrice}
            name="emergentReader"
            priceUnit={'/student'}
            color={'#327BF6'}
            hasInput={emergentReaderInput !== undefined}
            linkTo='https://greatleaps.com/products/digital-emergent-reader-program-bulk-licenses'
        />
    </>)
}
