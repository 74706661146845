import React from 'react'
import * as organizationGroups from '../../../shared/routes/organizationGroupsRoutes'
import Form from '../../../components/forms/Form'
import Input from '../../../components/forms/Input'
import SubmitButton from '../../../components/forms/SubmitButton'
import message from '../../../components/message/message'
import redirect from '../../../components/redirect/redirect'
import getURLParams from '../../../helpers/getURLParams'


export default class EditGroup extends React.Component<
{
    updatePermissions: () => Promise<void>
}, {
    studentName: string,
    studentLimit: number | null
}> {
    state = {
        studentName: '',
        studentLimit: null
    }
    componentDidMount() {
        let params: { name: string, studentLimit: number | null } = getURLParams(this.props)
        this.setState({ studentName: params.name, studentLimit: params.studentLimit })
    }

    submit = (values) => {
        // let studentLimit = values['Student Limit'].value === 'No Limit' ? null : values['Student Limit'].value
        let studentLimit = null
        return organizationGroups.edit(this.state.studentName, values.Name.value, studentLimit)
        .then((response) => {
            redirect.send('/manage', this.props)
        }).catch((err) => {
            return message.error(err)
        })
    }

    render() {

        return (
            <div className="text-center m-5">
                <h1 className="h2">Edit Group</h1>
                <Form submit={this.submit}>
                    {submitting => {
                        return (
                            <React.Fragment>
                                <Input name="Name" longName="Group Name" placeholder="Name of Group" submitting={submitting} defaultValue={this.state.studentName} />

                                {/* <StudentLimitSelection submitting={submitting} value={this.state.studentLimit} /> */}
                                <SubmitButton submitting={submitting}>Edit</SubmitButton>
                            </React.Fragment>
                        )
                    }}
                </Form>
            </div>
        )
    }

}
