import React, { useState } from 'react'
import getURLParameters from '../../../helpers/getURLParams'
import redirect from '../../../components/redirect/redirect'
import message from '../../../components/message/message'
import { modelRequest } from '@stem-sims/nexus'
export default function Unsubscribe(props) {
    const [submitting, setSubmitting] = useState(false)

    let params = getURLParameters(props)

    if (!params.email) {
        redirect.send("/", props, () => {
            message.error("There was an error subsubscribing your email. Please navigate back to the email and click the unsubscribe link again.")
        })
    }

    return (
        <React.Fragment>
            <h1>Unsubscribe {params.email}</h1>

            <div className="row subheader-text">
                <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                    <p>Please click the button below to unsubscribe the following email address from our email listing: {params.email}</p>
                </div>
            </div>

            <form className="form-horizontal form-validate" novalidate method="post">
                <button disabled={submitting}
                    type="submit"
                    className="btn btn-secondary btn-lg"
                    onClick={() => {
                        setSubmitting(true)
                        modelRequest.post("/api/account/unsubscribe", { email: params.email }).then((res) => {
                            redirect.send("/", props, () => {
                                message.success("Your email has been removed from our email list.")
                            })
                        }).catch(() => {
                            message.error("There has been an error processing your request. Please try again later.")
                        }).finally(() => setSubmitting(false))
                    }}>Unsubscribe</button>
            </form>
        </React.Fragment>
    )

}

