import { modelRequest } from "@stem-sims/nexus"
import { Error } from "../types/error"
import { OrganizationPermission } from "../types/organizationTypes"

/**
 * Joins a group
 * 
 * @param groupName name of group
 * @param usernames usernames of who to join
 * @param permission permission of user
 */
export function post(groupName: string, usernames: string[], permission: OrganizationPermission = 'Instructor'): Promise<Error> {
    return modelRequest.post('/api/organizations/groups/join', {
        name: groupName,
        usernames: usernames,
        permission: permission
    })
}
