import { modelRequest } from "@stem-sims/nexus"

function remove({ orgId, customerUsername }: { orgId: string, customerUsername: string }) {
    return modelRequest.delete("/api/organizations/remove-instructor", {
        data: {
            id: orgId,
            customerUsername
        }
    })
}

function removeFromGroup({ groupName, customerUsername }: { groupName: string, customerUsername: string }) {
    return modelRequest.delete("/api/organizations/groups/join", {
        data: {
            username: customerUsername,
            groupName: groupName,
        }
    })
}

const organizationRemoveInstructor = {
    remove,
    removeFromGroup,
}

export default organizationRemoveInstructor
