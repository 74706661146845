import React from 'react';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';

import ClipLoader from "react-spinners/ClipLoader";
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import getURLParams from '../../../helpers/getURLParams';
import { modelRequest } from '@stem-sims/nexus'

class SendCodesEmail extends React.Component {

    // todo:    - submit logic
    //          - hidden created field in old html?? might be for API call, not used anywhere else

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            "email-address": "",
            codes: [],
            "send-codes": [],

            codesRemaining: 0,
            loading: false
        }
    }

    componentDidMount() {
        initializeFormValidation();

        initializeAutosubmit();
        initializeTextAreaSubmit();
        initializeSubmissionPrevention();

        let emptySendArr = Array(10).fill(false);
        let emptyCodeArr = Array(10).fill("");

        let params = getURLParams(this.props);

        if (params.name) {
            this.setState({
                name: params.name
            })
        }

        if (params['email-address']) {
            this.setState({
                name: params['email-address']
            })
        } // get email from API instead?

        this.setState({
            "send-codes": emptySendArr,
            codes: emptyCodeArr
        }, () => {
            if (params.codes) {
                emptySendArr[0] = true

                for (let i = 0; i < params.codes.length; ++i) {
                    emptyCodeArr[i] = params.codes[i]
                }

                this.setState({
                    codes: emptyCodeArr,
                    "send-codes": emptySendArr
                })
            }
        })

    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        // custom handling of inputs that are represented by an array in the state
        if (name.includes("codes")) {
            let arrName = name.substring(0, name.length - 3)
            let arrCopy = this.state[arrName].slice() // creates the clone of the array from state
            let arrIndex = parseInt(name.substr(name.length - 2, 1))
            arrCopy[arrIndex] = value
            this.setState({ [arrName]: arrCopy })
        } else {
            this.setState({ [name]: value });
        }

    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var generateCodeForm = document.getElementById("send-email-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(generateCodeForm, blurEvent) === false) {
            return
        }

        //todo: check if there is at least one code to be sent (send error message if not at least 1 selected)

        this.setState({ loading: true }, () => {
            // todo: api request
            modelRequest.post("", {

            }).then(({ data }) => {
                // todo: if a specific code failed, show error message (do not remove from list)

                // todo: if code(s) remaing, remove codes sent (no success message), and check first box
                //          not loading anymore

                // todo: redirect to success page (only after last code has been sent)
                redirect.send("/admin/activation-codes/send-email/success")
                message.success("The activation codes email was successfully sent.")
            }).catch((error) => {
                this.setState({ loading: false })
                return message.error(error)
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <h1>Send Activation Code Email</h1>

                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>This form will send one or more activation codes to a provided email address.</p>

                        <p>Please note that any codes not selected will be shown again after hitting submit. This allows for a set of codes to be distributed to multiple users by submitting this form until no codes remain.</p>
                    </div>
                </div>

                <form id="send-email-form" className="form-horizontal form-validate" noValidate onSubmit={this.submitForm} method="post">
                    <div className="mb-3 row">
                        <label for="name" className="hidden-xs col-sm-3 control-label text-end">Name</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="name" name="name" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.name} placeholder="Name" required data-description="enter the customer's name" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="email-address" className="hidden-xs col-sm-3 control-label text-end">Email&nbsp;Address</label>

                        <div className="col-sm-6">
                            <input type="email" className="form-control" id="email-address" name="email-address" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["email-address"]} placeholder="Email Address" required data-description="enter the customer's email address" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="hidden-xs col-sm-3 control-label text-end">Codes</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control" id="code-0" name="codes[0]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[0]} placeholder="Code" required data-description="enter or select at least one activation code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-0" name="send-codes[0]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][0]} checked={this.state["send-codes"][0]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-1" name="codes[1]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[1]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-1" name="send-codes[1]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][1]} checked={this.state["send-codes"][1]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-2" name="codes[2]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[2]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-2" name="send-codes[2]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][2]} checked={this.state["send-codes"][2]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-3" name="codes[3]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[3]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-3" name="send-codes[3]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][3]} checked={this.state["send-codes"][3]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-4" name="codes[4]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[4]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-4" name="send-codes[4]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][4]} checked={this.state["send-codes"][4]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-5" name="codes[5]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[5]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-5" name="send-codes[5]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][5]} checked={this.state["send-codes"][5]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-6" name="codes[6]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[6]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-6" name="send-codes[6]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][6]} checked={this.state["send-codes"][6]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-7" name="codes[7]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[7]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-7" name="send-codes[7]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][7]} checked={this.state["send-codes"][7]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-8" name="codes[8]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[8]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-8" name="send-codes[8]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][8]} checked={this.state["send-codes"][8]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>

                            <div className="input-group">
                                <input type="text" className="form-control" id="code-9" name="codes[9]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.codes[9]} placeholder="Code" />

                                <div className="input-group-addon checkbox">
                                    <label>
                                        <input type="checkbox" id="send-code-9" name="send-codes[9]" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["send-codes"][9]} checked={this.state["send-codes"][9]} />

                                        <span>Send</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">
                        {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Send"}
                    </button>
                </form>

            </React.Fragment>
        )
    }

}

export default SendCodesEmail;
