import React, { useContext } from "react"
import * as Yup from "yup"
import ReactGA from "react-ga4"
import { Link } from "react-router-dom"
import Button from "react-bootstrap/Button"
import ModalTemplate from "../../probes/components/ModalTemplate"
import Form from "react-bootstrap/Form"
import { getURLParams } from "@stem-sims/great-leaps-react-common"
import message from "../../../components/message/message"
import redirect from "../../../components/redirect/redirect"
import * as countries from "../../../shared/routes/countriesModelRoutes"
import * as signUp from "../../../shared/routes/signUpRoutes"
import * as zipcode from "../../../shared/routes/zipcodeRoutes"
import * as statesModel from "../../../shared/routes/statesRoutes"
import { StateResponse } from "../../../shared/types/accountTypes"
import { initializeFormValidation, validForm } from "../../../helpers/forms/form-validation"
import passwordValidator from "../../../validators/passwordValidator"
import FormInput from "./components/FormInput"
import { Formik, Form as FormikForm } from 'formik'
import FormSelect from "./components/FormSelect"
import SubmitButton from "../../../components/forms/SubmitButton"
import { toast } from 'react-toastify';
import Common from "../../../context/Common"

export function SignUpTemplate(props) {
    const params = getURLParams()
    const Context = useContext(Common)
    const { updatePermissions } = Context
    const [countriesOptions, setCountriesOptions] = React.useState([])
    const [states, setState] = React.useState<StateResponse[]>([])
    const [postalCodeTimeout, setPostalCodeTimeout] = React.useState(null)
    const [usePwnedPassword, setUsePwnedPassword] = React.useState(false)
    const [pwndedModal, setPwnedModal] = React.useState(false)

    const signUpForm = React.useRef()
    const privacyPolicy = React.useRef<HTMLInputElement>()
    const messages = props?.useToast ? toast : message

    const initialValues = {
        name: ((params?.firstName ?? "") + " " + (params?.lastName ?? "")).trim(),
        username: "",
        emailAddress: params?.emailAddress ?? "",
        password: "",
        confirmPassword: "",
        referral: "",
        entity: "",
        role: "",
        postalCode: "",
        city: "",
        country: "",
        state: "",
        phoneNumber: "",
        notes: "",
        organizationInvite: params.invite,
    }

    const validationSchema = () => Yup.object().shape({
        password: passwordValidator()
    })

    const onSubmit = async (values) => {
        var blurEvent = document.createEvent("Event")
        blurEvent.initEvent("blur", false, true)
        if (!validForm(signUpForm.current, blurEvent)) {
            return
        }

        if (!privacyPolicy?.current?.checked) {
            return messages.error("You must agree to the privacy policy to use Great Leaps.")
        }

        const submittedValues = {
            ...values,
            usePwnedPassword: usePwnedPassword
        }

        try {
            await signUp.post(submittedValues)

            ReactGA.event({
                category: "digitalSignUp",
                action: "click",
                label: "button"
            })
            await updatePermissions()
            props.handleAfterLogin()
        }
        catch (error) {
            if (error === "The password you entered may have been exposed in a data breach. Are you sure you want to use this password?") {
                setPwnedModal(true)
            } else {
                messages.error(error ?? "There has been an error signing up. Please refresh the page and try again.")
            }
        }
    }

    const getStates = async (country: string, formik) => {
        try {
            const states = await statesModel.get(country)
            setState(states ?? [])
            formik.setFieldValue("state", "")
        }
        catch (error) {
            messages.error(error ?? "There has been an error loading your country's states. Please refresh the page and try again.")
        }
    }

    const countryOnChange = (event, formik) => {
        getStates(event.target.value, formik)
    }

    const postalCodeOnChange = (event, formik) => {                        
        if (postalCodeTimeout) {
            clearTimeout(postalCodeTimeout)
            setPostalCodeTimeout(null)
        }

        setPostalCodeTimeout(
            setTimeout(async () => {
                setPostalCodeTimeout(null)
                const location = await zipcode.get(event.target.value)

                if (!location?.city) {
                    return
                }

                formik.setFieldValue("city", location.city)
                formik.setFieldValue("country", location.country)
                await getStates(location.country, formik)
                formik.setFieldValue("state", location.state)
            }, 1000)
        )
    }

    React.useEffect(() => {
        const returnClientError = (response) => {
            message.error(response ?? "There has been an error loading the locations. Please refresh the page and try again.")
        }

        /**
         * Legacy sign up functions
         */
        initializeFormValidation()

        countries.get().then((response) => {
            if (!response.countries) {
                return returnClientError(response)
            }
            setCountriesOptions(response.countries)
        }).catch((err) => {
            returnClientError(err)
        })
    }, [])

    return <>
        <div className="row subheader-text">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 mt-4">
                {props.header}
                <p>Fill out this form with your information, click the "Create Account" Button, and activate your account by following the instructions we send to your email address.</p>
                <p className="h5">{props.subHeader}</p>
            </div>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ handleSubmit, isSubmitting }) => <FormikForm id="sign-up-form" className="form-horizontal form-validate" method="post" ref={signUpForm} noValidate>
                <FormInput fieldName="name" groupText="First and Last Name" required/>
                <FormInput fieldName="username" className="unique" required/>
                <FormInput fieldName="emailAddress" className="unique" required/>
                <FormInput fieldName="password" type="password"/> {/* adding required will double error message by Yup password validator */}
                <FormInput fieldName="confirmPassword" type="password" data-description="confirm your password" className="confirmation" data-confirms="password" data-type="passwords" required/>
                <FormSelect fieldName="referral" label={"How did you hear about us?"} groupText={"optional"}>
                    <option disabled value="">Referral:</option>
                    <option value="Web">Web</option>
                    <option value="Conference">Conference</option>
                    <option value="Social Media" >Social Media</option>
                    <option value="School">School</option>
                    <option value="SEEN Magazine/Email">SEEN Magazine/Email</option>
                    <option value="Other">Other</option>
                </FormSelect>
                <FormInput fieldName="entity" label="School/Company/Entity" groupText={"optional"} />
                <FormSelect fieldName="role" required>
                    <option disabled value="">Role:</option>
                    {["Parent", "Tutor", "Teacher", "Administrator", "Other"].map((role) => {
                        return <option value={role} key={role}>{role}</option>
                    })}
                </FormSelect>
                <FormInput fieldName="postalCode" onChange={postalCodeOnChange} required/>
                <FormInput fieldName="city" required/>
                <FormSelect fieldName="country" onChange={countryOnChange} required>
                    <option value="" disabled>Country:</option>
                    {countriesOptions?.map((item) => {
                        return (<option value={item} key={item}>{item}</option>)
                    })}
                </FormSelect>
                <FormSelect fieldName="state" data-description="select your state or province" required>
                    <option value="" disabled>State or Province:</option>
                    {states.length === 0 ? <option value="" disabled >Please select a country first.</option> : ""}
                    {states?.map((item) => {
                        return (<option value={item.State} key={item.State}>{item.State}</option>)
                    })}
                </FormSelect>
                <FormInput fieldName="phoneNumber" required/>
                <FormInput fieldName="notes" groupText={"optional"}/>
                <div className="d-flex justify-content-center text-muted">
                    <Form.Check
                        id="agree-policy"
                        name="agree-policy"
                        value="yes"
                        required
                        label={<label htmlFor="agree-policy">I agree to GreatLeaps's <Link to="/legal/terms-of-use" target="_blank">Terms of Use</Link> and <Link to="/legal/privacy-policy" target="_blank">Privacy Policy</Link>.</label>}
                        style={{maxWidth: "500px"}}
                        data-description="agree to the privacy policy and terms of use."
                        ref={privacyPolicy}
                    />
                </div>
                <ModalTemplate show={pwndedModal} onHide={() => setPwnedModal(false)} header={"Password Warning"}>
                    <p>
                        The password you entered may have been exposed in a data breach. Are you sure you want to use this password?
                    </p>
                    <div className="d-flex justify-content-around">
                        <Button onClick={() => {
                            setPwnedModal(false)
                        }} variant="primary">Go Back</Button>
                        <Button onClick={() => {
                            setUsePwnedPassword(true)
                            handleSubmit()
                        }} variant="primary">Yes</Button>
                    </div>
                </ModalTemplate>
                <div className="text-center">
                    <SubmitButton submitting={isSubmitting} disabled={false}>Create Account</SubmitButton>
                </div>
            </FormikForm>}
        </Formik>
    </>
}

export default function SignUp(props) {

    const handleAfterLogin = () => {
        redirect.send("/account/sign-up/success", props)
    }
    
  return (
    <SignUpTemplate
        {...props}
        handleAfterLogin={handleAfterLogin}
        header={<h1 className="mt-2">Sign Up to Get Started with Great Leaps</h1>}
        subHeader={"All new accounts come with a 14-day free trial. Register a student and get started!"}
    />
  )
}
