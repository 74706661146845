/*

    ex: GET /api/subscriptions/

    returns BOTH expired and non-expired subscriptions
    all subscriptions should be ordered by expiration date with first expiration date first

    Subscriptions Example
    {
        total_students: Number (only count active subasciptions)
        valid_subscriptions:
        [
            {
                count: Number of students for specific subscription
                startDate: String
                expirationDate: String
                trial: Boolean of whether subscription is a trial or not
                future: Subscription object that will replace the parent subscription when parent expires
            }
        ],
        expired_subscriptions:
        [
            {
                count: Number of students for specific subscription
                startDate: String
                expirationDate: String
                trial: Boolean of whether subscription is a trial or not
            }
        ]
    }
    
*/

import { SubscriptionResponse } from "../types/subscriptionTypes"

export default async function subscriptionResponse() {
    const rawResponse = await fetch("/api/subscriptions", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
    return rawResponse.json() as Promise<SubscriptionResponse>
}
