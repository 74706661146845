import React from "react"
import { Field, useFormikContext } from 'formik';
import Form from 'react-bootstrap/Form'
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { ProgramsType, Programs } from "../../../shared/types/studentTypes"

const notAvailableTooltip = "To add this program, please purchase more licenses."
const programAlreadyUsedTooltip = "To remove this program, please archive the license from the manage students page."
interface Props {
    disabledPrograms?: ProgramsType[] // Programs that have been used for a student can not be disabled, they must be archived separately
    checkedDisabledTooltip?: string
    uncheckedDisabledTooltip?: string
}

export default function EditLicenses({ disabledPrograms=[], checkedDisabledTooltip=programAlreadyUsedTooltip, uncheckedDisabledTooltip=notAvailableTooltip }: Props) {
    const { handleChange, setFieldTouched, values, errors, touched, isSubmitting, handleBlur } = useFormikContext<{ programs: any[]}>()
    
    return (<>
        {Programs.map((program, index) => {
            const isChecked = values.programs.includes(program)
            const disableProgram = disabledPrograms?.includes(program)
            let toolTipText = program.toString()
            if (!isChecked && disableProgram) {
                toolTipText = uncheckedDisabledTooltip
            }
            else if (isChecked && disableProgram) {
                toolTipText = checkedDisabledTooltip
            }

            return (<ToolTip key={program} title={toolTipText}>
                <Form.Check
                    as={Field}
                    id={`add-student-program-${program}`}
                    name="programs"
                    className="d-inline-block check-bg"
                    type="checkbox"
                    value={program}
                    disabled={disableProgram || isSubmitting}
                    isInvalid={!!errors.programs && !!touched.programs}
                    label={program}
                    onChange={e => {
                        setFieldTouched("programs", true, true)
                        handleChange(e)
                    }}
                    feedback={
                        // Only show the error below the last checkbox
                        errors.programs && index === Programs.length -1
                    }
                    key={program}
                    onBlur={handleBlur}
                />
        </ToolTip>)
        })}
    </>)
}

export function ToolTip({title, disabled=false, disabledTip="not allowed", children}) {
    return (
        <OverlayTrigger
            overlay={(props) => (
                disabled ? 
                    <Tooltip id="tooltip-disabled" className="tooltip-disabled" {...props}>
                        {disabledTip}
                    </Tooltip>
                    :
                    <Tooltip id="" className="tooltip-enabled" {...props}>
                        {title}
                    </Tooltip>
            )}
        >
            {disabled ? <span>{children}</span> : children}
        </OverlayTrigger>
    )
}
