import React from 'react'
import redirect from '../../../../components/redirect/redirect'
import message from '../../../../components/message/message'
import ClipLoader from "react-spinners/ClipLoader"
import { initializeFormValidation } from '../../../../helpers/forms/form-validation'
import { modelRequest } from '@stem-sims/nexus'

class ResendEmail extends React.Component {

    state = {
        loading: true,
        submitting: false,
        emailAddress: null
    }

    componentDidMount() {
        modelRequest.get("/api/account/info").then((response) => {
            if (response.verifiedEmail) {
                return redirect.send("/dashboard", this.props, () => {
                    message.error("Your email is already verified.")
                })
            }

            this.setState({ loading: false, emailAddress: response.email })
        }).catch((err) => {
            return redirect.send("/dashboard", this.props, () => {
                message.error(err)
            })
        })

        initializeFormValidation(this.onSubmit)
    }

    onSubmit = () => {
        this.setState({ submitting: true }, () => {
            modelRequest.put("/api/account/verify-email").then((response) => {
                this.setState({ submitting: true })

                return redirect.send("/dashboard", this.props, () => {
                    message.success("Your verification link has been resent to your email address. If you do not receive this email within 15 minutes, please check your spam folder.")
                })
            }).catch((err) => {

                return redirect.send("/dashboard", this.props, () => {
                    message.error(err)
                })
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="alert alert-info">If you have not yet received your verification email, <strong>please check that your email address is correct below and that our email is not in your spam folder</strong> before resending your verification link.</div>
                <h1 className="section-header">Resend Email Verification Link</h1>

                <form className="form-horizontal form-validate" method="post">
                    <div className="mb-3 row">
                        <label className="d-none d-sm-inline col-sm-6 control-label text-end">Email&nbsp;Address</label>

                        <div className="col-sm-6">
                            {this.state.loading ?
                                ""
                                :
                                <p className="form-control-static text-start text-center-xs">{this.state.emailAddress}</p>
                            }
                        </div>
                    </div>

                    <button type="submit" className="btn btn-secondary btn-lg" disabled={this.state.loading || this.state.submitting}>
                        {this.state.submitting ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.submitting}
                        /> :
                            "Resend Verification Link"
                        }</button>
                </form>
            </React.Fragment>
        )
    }
}

export default ResendEmail
