
import React, { useContext, useEffect, useState } from 'react';
import { Route, Link } from "react-router-dom";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CustomerLogin from '../pages/account/log-in/CustomerLogin'
import redirect from '../components/redirect/redirect'
import message from '../components/message/message'
import Common from '../context/Common'
import { StudentLogin, models } from '@stem-sims/great-leaps-react-common'
import LoginContext from '../context/LoginContext';

function LogIn(props) {
    const [submitting, setSubmitting] = useState(false)
    const context = useContext(Common)

    const submitStudent = () => {
        setSubmitting(true)
        const usernameInput = document.getElementById("username") as HTMLInputElement
        const passwordInput = document.getElementById("password") as HTMLInputElement

        models.login.post(usernameInput.value, passwordInput.value, props.type).then((data) => {
            props.updatePermissions().then(() => {
                redirect.send("/student/dashboard", props)
            })
        }).catch((err) => {
            message.clear()
            message.error(err)
            checkAuth();
            (document.getElementById("password")as HTMLInputElement).value = ""

        }).finally(() => {
            setSubmitting(false)
        })
    }

    const checkAuth = () => {
        context.updatePermissions().then(() => {
            let auth = context?.auth
            if (auth && auth.includes("customer")) {
                redirect.send("/student/dashboard", props, () => {
                    message.error("You are already logged in.")
                })
            }
        }).catch((err) => {
            message.error(err)
        })
    }

    const handleAfterLogin = () => {
        redirect.send("/dashboard", props)
    }

    useEffect(() => {
        if (!submitting) {
            checkAuth()
        }
    }, [])

    return (
        <Col lg={10} className='align-self-center align-items-center text-center'>
            <h1 className='section-header my-4'>Log in to Great Leaps Digital</h1>
            <Row className="justify-content-center subheader-text">
                <Col sm={4} xl={3} className="">
                    <Link to="/account/log-in/teacher" className={`w-100 btn btn-secondary btn-lg ${props.type === "customer" ? "active" : ""}`}>Teacher</Link>
                </Col>
                <Col sm={4} xl={3} className="">
                    <Link to="/account/log-in/student" className={`w-100 btn btn-secondary btn-lg ${props.type === "student" ? "active" : ""}`}>Student</Link>
                </Col>
            </Row>
            <LoginContext.Provider value={{ handleAfterLogin }}>
                <Route exact path="/account/log-in/student" render={(props) => <StudentLogin  {...props} submitting={submitting} submit={submitStudent} />} />
                <Route exact path="/account/log-in/teacher" render={(props) => <CustomerLogin  {...props} type={"customer"} submitting={submitting} setSubmitting={setSubmitting} signUpLink={<Link to="/account/sign-up">Need an Account? <strong>Sign up here!</strong></Link>} />} />
            </LoginContext.Provider>
        </Col>
    )
}

export default LogIn