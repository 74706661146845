import { Field, useFormikContext } from 'formik'
import React from 'react'
import StudentModal from './StudentModal'
import { useRoster } from './StudentsContext'
import { modelRequest } from '@stem-sims/nexus'

type ShareProps = {
    show: boolean
}

function ShareForm() {
    const roster = useRoster()
    const { values } = useFormikContext<any>()
    return (<>
        <div className="row subheader-text">
            <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                <p>Please enter the username of the person to whom you wish to delegate permissions.</p>
            </div>
        </div>
        <div className="mb-3 row">
            <label className="text-center-xs bold-xs col-sm-3 control-label text-end">Student{roster?.selectedStudents.length > 1 ? "s" : ""}</label>
            <div className="col-sm-6">
                {roster.selectedStudents.map((student) => {
                    return (<div key={student.id}>
                        <p className="form-control-static text-start text-center-xs break-words" >{student.name}</p>
                    </div>)
                })}
            </div>
        </div>
        <div className="mb-3 row">
            <label htmlFor="username" className="d-none d-sm-inline col-sm-3 control-label text-end">Username</label>
            <div className="col-sm-6">
                <Field type="text" className="form-control" id="username" name="username" value={values?.username} placeholder="Username" data-description="enter a username" required />
            </div>
        </div>
    </>)
}

function Share(props: ShareProps) {
    const roster = useRoster()
    const shareStudent = () => {
        const studentIDs = roster.selectedStudents.map(student => student.id)
        return modelRequest.post('/api/students/share', {
            students: studentIDs,
            permissions: ["read", "write"],
            shareToCustomer: document.getElementById('username')['value']
        })
    }
    return (<>
        <StudentModal show={props.show} hide={roster.hideModals} action="Share" submitAction={shareStudent}>
            <ShareForm />
        </StudentModal>
    </>)
}


export default Share