import React from 'react'
import getURLParameters from '../../../../helpers/getURLParams'
import redirect from '../../../../components/redirect/redirect'
import message from '../../../../components/message/message'
import BeatLoader from 'react-spinners/BeatLoader'
import { modelRequest } from '@stem-sims/nexus'

class Accept extends React.Component {

    componentDidMount() {
        var params = getURLParameters(this.props)
        if (this.props.type === null) {
            return
        }

        if (this.props.type === "guest") {
            return redirect.send('/account/log-in/teacher', this.props, () => {
                message.error("You must be logged in to accept a student transfer. Please login and click the link again.")
            })
        }

        if (!params.code) {
            return redirect.send('/students', this.props, () => {
                message.error("We weren't able to transfer your student, please try again.")
            })
        }

        modelRequest.post('/api/students/transfer/accept', {
            code: params.code
        }).then((response) => {
            redirect.send('/students', this.props, () => {
                message.success("Your student was successfully transferred.")
            })
        }).catch((err) => {
            redirect.send('/students', this.props, () => {
                message.error(err)
            })
        })
    }

    render() {
        return (
            <div style={{ margin: "0 auto", display: "table", height: "80vh" }}>
                <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                    <BeatLoader
                        size={15}
                        color={"#123abc"}
                        loading={true}
                    />
                </div>
            </div>
        )
    }
}

export default Accept
