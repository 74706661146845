import React from 'react'
import StudentsComponent from '../../components/Students'


class Students extends React.Component {

    render()
    {
       return <StudentsComponent orgId={this.props.orgId} />
    }
}

export default Students
