import React from 'react'
import { ReadingSubjects, StoryLevels } from '../../shared/routes/readingRoutes'
import { MathSubjects } from '../../shared/types/mathTypes'
import { ProgramsType, Student } from '../../shared/types/studentTypes'
import { ChartType } from './components/Chart'
import { ChartData, ChartResponse, RawChartResponse } from '../../shared/types/recordTypes'
import { EmergentReaderSubjects } from '../../shared/types/emergentReaderTypes'

export const RecordsContext = React.createContext<{
    type: ReadingSubjects | StoryLevels | MathSubjects | EmergentReaderSubjects,
    program: ProgramsType,
    studentID: string
    studentName: string
    customerUsername: string
    adjacent: {
        next: any
        prev: any
    }, //adjacent students
    donutData: any
    suggestedPhonicsType: "phonics" | "alphabet"
    loading: boolean,
    closeRoster: boolean,
    buttonsDisabled: boolean,
    currentChart: ChartType,
    updateChartType: (type: ChartType) => any,
    chartData: ChartData,
    savedDateRange: { startDate: Date, endDate: Date},
    filteredStudents: Student[],
    setFilteredStudents: (students: Student[]) => void,
    filteredInstructor: string,
    setFilteredInstructor: (instructor: string) => void,
    filteredStatus: string,
    setFilteredStatus: (status: string) => void,
    gradeStartDate: string,
    gradeEndDate: string, 
    gradeResponses: ChartResponse[],
    rawGradeResponses: RawChartResponse[]
}>(undefined)
export default RecordsContext
