import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'
import ClipLoader from 'react-spinners/ClipLoader'
import { Form, Formik } from 'formik'

type StudentModalProps = {
    show: boolean,
    hide: () => void,
    size?: "sm" | "lg" | "xl",
    action: string,
    submitAction: () => Promise<{
        message: string
    }>,
    children: React.ReactNode
}

function StudentModal(props: StudentModalProps) {
    const [submitting, setSubmitting] = useState<boolean>(false)

    const onSubmit = async () => {
        setSubmitting(true)
        props.submitAction().then((res) => {
            toast.success(`${props.action} was successful`)
        }).catch((err) => {
            toast.error(err)
        }).finally(() => {
            props.hide()
            setSubmitting(false)
        })
    }

    useEffect(() => {
        if (!props.show) return
    }, [props])

    return (<>
        {props.show &&
            <Modal className="student-modal" show={props.show} onHide={props.hide} size={props.size ?? "lg"}>
                <Modal.Header closeButton>
                    <h2>{props.action} Student</h2>
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <Formik initialValues={{}} onSubmit={onSubmit}>
                        <Form>
                            {props.children}
                            <Button type="submit" variant="secondary" className="btn-lg mt-4" disabled={submitting}>
                                {submitting ?
                                    <ClipLoader
                                        size={20}
                                        color={"#123abc"}
                                        loading={submitting} />
                                    : props.action
                                }
                            </Button>
                        </Form>
                    </Formik>
                </Modal.Body>
            </Modal>
        }
    </>)
}

export default StudentModal