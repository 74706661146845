import { modelRequest } from '@stem-sims/nexus'
import { ChartData } from '../types/recordTypes'

/**
 * Gets the subscription status of the account
 */
export function get(studentID: string, type: string) : Promise<ChartData> {
    return modelRequest.get('/api/records', {
        params: {
            studentID: studentID,
            type: type
        }
    })
}
export function getWeeklySessionCounts(studentID: string) : Promise<any> {
    return modelRequest.get('/api/fidelity', {
        params: {
            studentID: studentID,
        }
    })
}
