import { modelRequest } from '@stem-sims/nexus'

/**
* Validate a usernames availability
 * 
 * returns a boolean that is true if the username does not exist
 * 
 * @param username 
 */
export function get(username: string): Promise<boolean> {
    return modelRequest.get('/api/username-available', {
        params: {
            username: username
        }
    }).then((response) => {
        return Boolean(response)
    }).catch((err) => {
        return err
    })
}


/**
 * Check if a student name is available in the organization
 *
 * @returns a boolean that is true if the student name is available
 *
 * @param name the student name to check
 */
export async function studentNameAvailabilityCheck(name: string): Promise<boolean> {
    try {
        const response = await modelRequest.get("/api/students/name-available", {
            params: {
                name: name
            }
        })
        return response.exists === "false"
    } catch (error) {
        return error
    }
}
