import React from "react"
import { SignUpTemplate } from "./SignUp"

export default function SignUpModal(props) {

    const handleAfterLogin = () => {
        window.scrollTo(0, document.body.scrollHeight)
    }

    return <>
        <SignUpTemplate
            useToast={true}
            header={<h1>Sign Up to Checkout</h1>}
            handleAfterLogin={handleAfterLogin}
            subHeader={<p onClick={() => props.setAuthViewState(false)} className="text-center mb-2 cursor-pointer">Already Have an Account? <strong><span className="link-primary">Log in </span> to proceed with your checkout!</strong></p>}
        />
    </>
}