import { modelRequest } from "@stem-sims/nexus"
import { StudentMoreInfo, StudentInfo, ProgramsType } from "../types/studentTypes"

export function getAll(customerUsername?: string): Promise<StudentMoreInfo[]> {
    return modelRequest.get('/api/students', {
        params: {
            customerUsername: customerUsername
        }
    }).then((response) => response.data)
}

export function getOne(studentId): Promise<StudentMoreInfo> {
    return modelRequest.get("/api/student", {
        params: { studentId }
    }).then((response) => response.data)
}
export function updateOne(student: StudentInfo, programs: ProgramsType[]): Promise<StudentMoreInfo> {
    return modelRequest.post("/api/students/edit", {
        programs: programs,
        student: student
    }).then((response) => response.data)
}
