import React from 'react'
import redirect from '../../../../../components/redirect/redirect'
import message from '../../../../../components/message/message'
import buildQuery from '../../../../../helpers/buildQuery'
import { modelRequest } from '@stem-sims/nexus'

class Manage extends React.Component
{
    state = {
        loading: true,
        delegations: [
            {
                used: 2,
                username: "username",
                delegated: 1
            }
        ]
    }
    componentDidMount() {
        modelRequest.get('/api/subscription/share').then((delegations) => {

            if (delegations.length === 0) {
                return redirect.send("/account/subscription", this.props, () => {
                    message.error("You do not currently have any student licenses you are sharing to other users.")
                })
            }

            this.setState({loading: false, delegations: delegations})
        }).catch((err) => {
            return redirect.send("/account/subscription", this.props, () => {
                message.error(err)
            })
        })

    }

    render()
    {
        var { delegations } = this.state

        return (
            <React.Fragment>
                <h1 className="subheader-text">Manage Subscription Sharing</h1>
                <form className="form-horizontal form-validate" noValidate method = "get" action = "../revoke">
                    <ul id = "checkbox-list" className="list-group text-start">
                        {delegations.map((delegation) =>
                        {
                            return <li className="list-group-item action-list">
                                <input className="checkbox-list-item" type = "checkbox" id = {`checkbox-${delegation.username}`} name = "delegations[]" value = {delegation.user} />
                                
                                <label className="break-words" role = "button" for = {`checkbox-${delegation.user}`}><strong>{delegation.delegated} student{delegation.delegated > 1 ? "s" : ""}</strong> delegated to <strong>{delegation.username}</strong> ({delegation.used} Used)</label>
                                
                                <span className="float-right">
                                    <a href = {`edit?${buildQuery({'user': delegation.username})}`} className="action-link" ref="noreferrer">Edit</a>
                                </span>
                            </li>
                        })
                        }
                    </ul>
                    
                    <div className="row mt-3">
                        <div className="col-12 text-start">
                            <input className="checkbox-shortcut" type = "checkbox" id = "checkbox-shortcut" />
                            
                            <label role = "button" for = "checkbox-shortcut">Select all</label>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 subheader-text">
                            <p>To revoke a subscription, please <a href="https://greatleaps.com/pages/contact-us">contact us</a></p>
                        </div>
                    </div>
                </form>

            </React.Fragment>
        )
    }
}

export default Manage
