import React from 'react'
import * as organizationInstructorLimit from '../../../shared/routes/organizationInstructorLimitRoutes'
import Form from '../../../components/forms/Form'
import SubmitButton from '../../../components/forms/SubmitButton'
import Selection from '../../../components/forms/Selection'
import message from '../../../components/message/message'
import redirect from '../../../components/redirect/redirect'
import getURLParams from '../../../helpers/getURLParams'


export default class LimitInstructor extends React.Component<{ updatePermissions: () => Promise<void> }, { maxLimit: number, currentLimit: number }> {
    state = {
        maxLimit: null,
        currentLimit: null
    }

    componentDidMount() {
        let params: { name: string, customerUsername: string, groupName: string } = getURLParams(this.props)
        organizationInstructorLimit.get(params.customerUsername, params.groupName).then((response) => {
            this.setState({ maxLimit: response.maxLimit, currentLimit: response.studentLimit })
        }).catch((err) => {
            redirect.send('/manage', () => {
                message.error("There has been an error adding a limit to the instructor. Please try again.")
            })
        })
    }
    submit = (values) => {
        let studentLimit = values['Student Limit'].value === 'No Limit' ? null : values['Student Limit'].value
        let params: { name: string, customerUsername: string, groupName: string } = getURLParams(this.props)
        return organizationInstructorLimit.post(params.customerUsername, params.groupName, studentLimit).then((response) => {
            redirect.send('/manage', () => {
                message.success("Successfully added a limit to the instructor.")
            })
        }).catch(() => {
            redirect.send('/manage', () => {
                message.error("There has been an error adding a limit to the instructor. Please try again.")
            })
            return
        })
    }

    render() {

        let opts = ['No Limit']

        for (let i = 0; i < this.state.maxLimit ?? 0; i++) {
            opts.push(String(i + 1))
        }

        return (
            <React.Fragment>
                <h1>Limit Instructors</h1>
                <Form submit={this.submit}>
                    {submitting => {
                        return (
                            <React.Fragment>
                                <Selection name="Student Limit" longName="Student Limit" submitting={submitting} options={opts} defaultValue={String(this.state.currentLimit)} />
                                <SubmitButton submitting={submitting}>Edit</SubmitButton>
                            </React.Fragment>
                        )
                    }}
                </Form>
            </React.Fragment>
        )
    }

}
