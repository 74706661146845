import React, { useEffect, useState } from "react"
import { FormikControl } from '@stem-sims/nexus';
import { Formik, Form as FormikForm, FormikConfig } from 'formik';
import moment from "moment"
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/Row"
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import InputGroup from "react-bootstrap/InputGroup"
import { StudentInfo, ProgramsType } from "../../../shared/types/studentTypes"
import * as Yup from 'yup'
import { studentNameAvailabilityCheck } from "../../../shared/routes/usernameAvailableRoutes";
import * as orgGroupModel from "../../../shared/routes/organizationGroupsRoutes"
import * as orgModel from "../../../shared/routes/organizationsRoutes"
import EditLicenses from "./EditLicenses"

interface StudentFormPrograms extends StudentInfo {
    programs: ProgramsType[]
}

interface Props {
    initialValues?: Partial<StudentFormPrograms>
    onSubmit: FormikConfig<StudentFormPrograms>["onSubmit"]
    /**
     * Label text for the submission button
     */
    submitLabel: string
    /**
     * Programs that have been used for a student can not be disabled, they must be archived separately
     */
     disabledPrograms?: ProgramsType[]
     orgId: string
}
const defaultInitValues = {
    gender: "" as any,
    grade: "",
    name: "",
    birthDay: "",
    id: "",
    ethnicity: "" as any,
    currentSchoolEnrollment: "" as any,
    iep: "" as any,
    retention: "",
    programs: [],
    instructors: [],
    englishLanguageLearner: "",
    group: "Default"
}
export default function StudentForm({ initialValues=defaultInitValues, onSubmit, submitLabel, disabledPrograms, orgId }: Props) {
    const [groups,setGroups] = useState<any[]>([])
    const [org,setOrg] = useState<any>({})
    const notAvailableTooltip = "To add this program, please purchase more licenses."
    const programAlreadyUsedTooltip = "To remove this program, please archive the license from the manage students page."

    useEffect(() => {
        orgModel.get().then((orgs) => {
            if (orgId) {
                setOrg(orgs.find((o) => o.id === orgId))
            } else {
                setOrg(orgs[0])
            }
        })
        orgGroupModel.get().then((orgGroups) => {
            setGroups(orgGroups)
        })
    },[initialValues])

    return (<>
        <Modal.Header closeButton>
            <h2>{submitLabel} Student</h2>
        </Modal.Header>
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={
                Yup.object().shape({
                    name: Yup
                        //TODO: Check if two names are provided and the name DNE
                        .string()
                        .label("Student name")
                        .matches(/\w \w/, "Please enter both a first and last name.")
                        .required("Name is required"),
                    birthDay: Yup
                        .date()
                        .nullable()
                        .label("Birth day")
                        .required("Birthday is required"),
                    programs: Yup
                        .array()
                        .min(1, "At least one program must be selected.")
                        .required(),
                })
            }
        >
            {({ isSubmitting, errors, touched }) => (
            <FormikForm>
                <Modal.Body className="g-3">
                <Row className="mb-2">
                    <Form.Label htmlFor="student-name" className="d-inline-block col-sm-3 text-sm-end">Name</Form.Label>
                    <Col sm={8} className='d-inline-block'>
                        <InputGroup className="">
                            <FormikControl defaultValue=""
                                name="name"
                                placeholder="Name"
                                isInvalid={!!errors.name && touched.name}
                                aria-describedby="student-name-label"
                                groupText="First and Last"
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Form.Label className="d-inline-block col-sm-3 text-sm-end">Birthday</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <FormikControl defaultValue=""
                                type="date"
                                name="birthDay"
                                isInvalid={!!errors.birthDay && touched.birthDay}
                                max={moment().format("YYYY-MM-DD")}
                                min={"1905-01-01"}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Form.Label className="d-inline-block col-sm-3 text-sm-end">Programs</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <EditLicenses disabledPrograms={disabledPrograms} checkedDisabledTooltip={programAlreadyUsedTooltip} uncheckedDisabledTooltip={notAvailableTooltip}/>
                        </InputGroup>
                        {!!errors.programs && touched.programs &&
                            <Form.Control.Feedback type="invalid" className="d-block">{errors.programs as any}</Form.Control.Feedback>
                        }
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Form.Label className="d-inline-block col-sm-3 text-sm-end">Grade</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <FormikControl defaultValue="" as="select" id = "grade" name = "grade">
                                <option disabled value = "None">Grade:</option>
                                <option value = "Kindergarten">Kindergarten</option>
                                <option value = "1st">1st</option>
                                <option value = "2nd">2nd</option>
                                <option value = "3rd">3rd</option>
                                <option value = "4th">4th</option>
                                <option value = "5th">5th</option>
                                <option value = "6th">6th</option>
                                <option value = "7th">7th</option>
                                <option value = "8th">8th</option>
                                <option value = "9th">9th</option>
                                <option value = "10th">10th</option>
                                <option value = "11th">11th</option>
                                <option value = "12th">12th</option>
                            </FormikControl>
                            <span className="input-group-text">optional</span>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Form.Label className="d-inline-block col-sm-3 text-sm-end">Gender</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <FormikControl defaultValue="" as="select" id = "gender" name = "gender">
                                <option disabled value = "">Gender:</option>
                                <option value = "Male">Male</option>
                                <option value = "Female">Female</option>
                                <option value = "Other">Other</option>
                                <option value = "Decline to Answer">Decline to Answer</option>
                            </FormikControl>
                            <span className="input-group-text">optional</span>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Form.Label htmlFor="ethnicity" className="d-inline-block col-sm-3 text-sm-end">Ethnicity</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <FormikControl defaultValue="" as="select" name = "ethnicity">
                                <option disabled value = "">Ethnicity:</option>
                                <option value = "White">White</option>
                                <option value = "Hispanic Origin">Hispanic Origin</option>
                                <option value = "Mideastern/Arab Descent">Mideastern/Arab Descent</option>
                                <option value = "Black">Black</option>
                                <option value = "Asian/Pacific Islander">Asian/Pacific Islander</option>
                                <option value = "South Asian">South Asian</option>
                                <option value = "American Indian/Alaskan Native">American Indian/Alaskan Native</option>
                                <option value = "Multi-Racial">Multi-Racial</option>
                                <option value = "Decline to Answer">Decline to Answer</option>
                            </FormikControl>
                            <span className="input-group-text">optional</span>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Form.Label htmlFor="enrollment" className="d-inline-block col-sm-3 text-sm-end">Current School Enrollment</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <FormikControl defaultValue="" as="select" name = "currentSchoolEnrollment">
                                <option disabled value = "">Enrollment:</option>
                                <option value = "Public">Public</option>
                                <option value = "Private">Private</option>
                                <option value = "Cyber">Cyber</option>
                                <option value = "Homeschooling">Homeschooling</option>
                                <option value = "Not Applicable">Not Applicable</option>
                                <option value = "Decline to Answer">Decline to Answer</option>
                            </FormikControl>
                            <span className="input-group-text">optional</span>
                        </InputGroup>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Form.Label htmlFor="iep" className="d-inline-block col-sm-3 text-sm-end">IEP related to reading</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <FormikControl defaultValue="" as="select" id = "iep" name = "iep">
                                <option disabled value = "">IEP:</option>
                                <option value = "Yes">Yes</option>
                                <option value = "No">No</option>
                                <option value = "Decline to Answer">Decline to Answer</option>
                            </FormikControl>
                            <span className="input-group-text">optional</span>
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Form.Label htmlFor="englishLanguageLearner" className="d-inline-block col-sm-3 text-sm-end">English Language Learner</Form.Label>
                    <Col sm={8} className="d-inline-block">
                        <InputGroup>
                            <FormikControl defaultValue="" as="select" id="english-language-learner" name="englishLanguageLearner">
                                <option disabled value = "">English Language Learner:</option>
                                <option value = "Yes">Yes</option>
                                <option value = "No">No</option>
                                <option value = "Decline to Answer">Decline to Answer</option>
                            </FormikControl>
                            <span className="input-group-text">optional</span>
                        </InputGroup>
                    </Col>
                </Row>
                
                {groups && groups.length > 0 && org?.personal === "No" && 
                    <Row className="mb-2">
                        <Form.Label className="d-inline-block col-sm-3 text-sm-end">Group</Form.Label>
                        <Col sm={8} className='d-inline-block'>
                            <InputGroup>
                                <FormikControl as="select" name="group" className="w-100">
                                    {groups.map((group) => <option value={group.name} key={group.name}>{group.name}</option>)}
                                </FormikControl>
                            </InputGroup>
                        </Col>
                    </Row>
                }
                <Button variant="secondary" type="submit" size="lg" disabled={isSubmitting} className="d-block m-auto">
                    {submitLabel}
                </Button>
                </Modal.Body>
            </FormikForm>
            )}
        </Formik>
    </>)
}
