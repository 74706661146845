import React, { useContext, useMemo } from 'react'
import { Link } from "react-router-dom"
import moment from 'moment'
import BeatLoader from 'react-spinners/BeatLoader'
import { ReactTable, useModel } from '@stem-sims/nexus'
import { RecordsContext } from '../RecordsContext'
import { ToolTip } from '../../students/components/EditLicenses'
import { levels } from '../../../shared/records'

const HIGH_PROBE = "high"
const MIDDLE_PROBE = "middle"
const ELEMENTARY_PROBE = "elementary"

export default function ExerciseRecords({studentId, probeType, program}) {
    const context = useContext(RecordsContext)
    const dateStringSort = useMemo(() => ((rowA, rowB) => {
        const dateA = new Date(rowA.date)
        const dateB = new Date(rowB.date)
        const diff = dateA.getTime() - dateB.getTime()
        return diff > 0 ? 1 : diff < 0 ? -1 : 0
    }), [])

    const filteredRecords = React.useMemo(() => {
        let records
        if (!context.chartData?.rawRecords) {
            return []
        }
        if(context.currentChart !== "performanceChart") {
            records = context.rawGradeResponses
        } else {
            records = context.chartData?.rawRecords
        }
        
        let instructionalLevelFound = false
        return records.map((record,i) => {
            const recordDate = new Date(record.date)
            const deployDate = new Date(process.env.REACT_APP_PHONICS_V2_DEPLOY_DATE)
            const revertDate = new Date(process.env.REACT_APP_PHONICS_V2_REVERT_DATE)
            const version = ((recordDate < deployDate && recordDate > revertDate) ? 1 : 2)
            let formattedRecord = {version: version, id: i,  ...record, number: record.number.toString()}
            formattedRecord.date = moment(record.date).local().format('MMM D, YYYY hh:mm A') as any
            record["id"] = i // react table needs id field

            //Only show the asterisk on stories probes
            //This still allows a elementary placement to show up on a middle probe
            //Should think about how to handle this when we deal with students jumping story types in GL-60
            const isStoryProbe = probeType === HIGH_PROBE || probeType === MIDDLE_PROBE || probeType === ELEMENTARY_PROBE
            if (!instructionalLevelFound && record.number === context.donutData?.stories.instructionalLevelProbe && isStoryProbe){
                formattedRecord.number = "*" + record.number
                instructionalLevelFound = true
            }
            return formattedRecord
        })
    }, [context.chartData?.rawRecords, context.donutData, probeType, context.rawGradeResponses, context.currentChart])

    /* Setup the columns for record table */
    const hasOtherTeacherRecord = context.chartData?.rawRecords?.some(record => record.teacherName)
    function goalRow(row) {
        const record = row.original
        if(record.errorGoal && record.responseGoal) {
            return "goal-row"
        }
        return ""
    }

    const columns = [
        {
            Header: 'Date',
            accessor: 'date',
            sortType: dateStringSort,
            minWidth: 190
        },
        {
            Header: 'Exercise #',
            accessor: 'number',
            disableSortBy: true,
            Cell: ({ row }) => {
                return <ToolTip title={row.original.name}>
                    <span className={`cursor-pointer`}>{row.original.number}</span>
                </ToolTip>
            },
            maxWidth: 90,
        },
        ...(probeType === "high" || probeType === "middle" || probeType === "elementary" ? [{
            Header: 'Grade Level',
            disableSortBy: true,
            Cell: ({ row }) => {
                let probeNumber = row.original?.number
                if(probeNumber[0] === "*") probeNumber = probeNumber.slice(1)
                return <span>{levels[probeType][probeNumber]}</span>;
            },
            maxWidth: 100,
        }] : []),
        {
            Header: 'Responses',
            Cell: ({ row }) => {
                return <span className={`${row.original?.responseGoal ? "goal-cell px-3 py-1" : ""}`}>
                    {row.original.responses} / {row.original.maxResponses}
                </span>
            },
            disableSortBy: true,
        },
        {
            Header: 'Time (sec)',
            accessor: 'time',
            disableSortBy: true,
            maxWidth: 90,
        },
        {
            accessor: 'wpm',
            Header: ({ row }) => {
                return <span>{program === "Reading" ? "Words" : "Problems"}/Min</span>
            },
            disableSortBy: true,
            maxWidth: 90,
        },
        {
            Header: 'Errors',
            accessor: 'errors',
            Cell: ({ row }) => {
                return <span className = {row.original?.errorGoal ? "goal-cell px-3 py-1" : ""}>{row.original?.errors}</span>
            },
            disableSortBy: true,
            maxWidth: 80,
        },
    ]
    if(hasOtherTeacherRecord) {
        const teacherColumn = {
            Header: 'Teacher',
            accessor: 'teacherName',
            maxWidth: 90,
        } as any
        columns.push(teacherColumn)
    }
    if(probeType === "phonics") {
        columns.push({
            Header: "Version",
            accessor: "version",
            maxWidth: 80,
            disableSortBy: true,
        })
    }

    return(<>
        {!context.chartData ?
            <BeatLoader size={10} color={"#123abc"}/>
            :
            (filteredRecords.length === 0 ?
                <tr className='d-inline'>
                    <td className="text-center">
                        No data yet. <Link to = {`/dashboard`}>Start an exercise with this student</Link> to record exercise data.
                    </td>
                </tr>
                : <>
                <div className = "col-6 col-lg-6 offset-lg-1">
                    <h3 className = "h4 mb-n5 dont-print">Raw Exercise Records</h3>
                </div>
                <h3 className = "h4 d-none d-print-block">Raw Exercise Records</h3>
                <ReactTable columns={columns} bulkActions={[]} data={filteredRecords} sortBy={[{ id: "date", desc: true }]} customClass={goalRow} hidePagination/>
            </>)
        }
    </>)
}
