import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { responseTypes } from "../../shared/types/emergentReaderTypes";
import { AwarenessProps, buildResponseList } from "../../helpers/AwarenessBuilder";



export default function Awareness({ responses, exerciseNumber, title, onClick = () => { }, onDoubleClick = () => { } }: AwarenessProps) {
    const exercise = responses.find(page => responseTypes.includes(page.pageType as any))
    const responseList = buildResponseList(exercise, onClick, onDoubleClick)

    return <React.Fragment>
        {responses.length === 0 ?
            <div style={{ margin: '0 auto', display: 'table', height: '50vh' }}>
                <div
                    style={{
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                    }}
                >
                    <BeatLoader size={15} color={'#123abc'} loading={true} />
                </div>
            </div> :
            <React.Fragment>
                <h1 className={'h3 mb-4'}>
                    Phonological Awareness Exercise {exerciseNumber} - {title} {exercise.pageType === "Untimed Activity" ? `(${exercise.pageType})` : `(1-Minute ${exercise.pageType})`}
                </h1>
                <div className="row content-block">
                    {responseList}
                </div>
            </React.Fragment>}
    </React.Fragment>;
}
