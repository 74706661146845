import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import redirect from '../redirect/redirect'
import message from '../message/message'
import readabilityPDF from '../../content/files/readability-of-stories.pdf'
import logo from '../../content/images/logo.svg'
import { modelRequest } from '@stem-sims/nexus'

type DefaultNavbarProps = {
    auth: string
    updatePermissions: () => Promise<void>
    homeURL: string
}

class DefautlNavbar extends React.Component<DefaultNavbarProps> {

    render() {
        return (<Navbar id="navbar" className="fixed-top hidden-print px-1 py-0 " expand="md">
            <Container fluid="xl">
                <Navbar.Brand className="mx-2">
                    <Nav.Link href={this.props.homeURL} onClick={() => {
                        window.scrollTo(0, 0) //Lisa wants to be able to scroll to top instantly
                    }}>
                        <img
                            src={logo}
                            alt="Logo"
                            title="Home"
                            className='py-1'
                        />
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="" id="basic-navbar-nav">
                    <Nav className="m-auto">
                        {this.props.auth.includes("student") ?
                            <Nav.Link href="/student/dashboard" className='px-2'>Exercises</Nav.Link>
                            : ""
                        }
                        {this.props.auth.includes("customer") ?
                            <Nav.Link href="/students" className='px-2'>Students</Nav.Link>
                            : ""
                        }
                        <Nav.Link href="https://tutoring.greatleaps.com/" className='px-2'>Find a Tutor</Nav.Link>
                        {!this.props.auth.includes("student") ?
                            <Nav.Link className="px-2" href="/subscribe">Pricing</Nav.Link>
                            : ""
                        }
                        {this.props.auth.includes("customer") ?
                            <Nav.Link className="nav-link" href="/account/information">Account</Nav.Link>
                            : ""
                        }
                        <Nav.Link href="https://greatleaps.com/collections/training-more" className='px-2'>Training</Nav.Link>
                        {!this.props.auth.includes("student") ?
                            <NavDropdown title="Resources" id="resources-dropdown" className='px-2'>
                                <NavDropdown.Item href={"https://sites.google.com/greatleaps.com/helpcenter/home"} target="_blank" rel="noreferrer">Instructions</NavDropdown.Item > 
                                <NavDropdown.Item href="/help/frequently-asked-questions">FAQ</NavDropdown.Item> 
                                <NavDropdown.Item href={readabilityPDF} target="_blank" rel="noopener noreferrer">Readability of Stories</NavDropdown.Item > 
                                <NavDropdown.Item href="https://greatleaps.com/index.php?main_page=contact_us" target="_blank" rel="noopener noreferrer">Customer Support</NavDropdown.Item>
                                {this.props.auth.includes("guest") ? <NavDropdown.Item href="/account/recover/username">Account Recovery</NavDropdown.Item> : "" }
                            </NavDropdown>
                            : ""
                        }
                    </Nav>
                    <div className="d-flex justify-content-center">
                        {this.props.auth.includes("guest") && <>
                            <Nav.Link className="btn btn-success mx-2 px-2 py-1" href="/account/sign-up">Sign Up</Nav.Link>
                            <Nav.Link id="log-in-button" className="btn btn-outline-success mx-2 px-2 py-1" href="/account/log-in/teacher">Log In</Nav.Link>
                        </>
                        }
                    </div>
                </Navbar.Collapse>
                {!this.props.auth.includes("guest") &&
                    <form id="log-out-form" className="prevent-next-submit" noValidate method="post" action="/log-out" onSubmit={(event) => {
                        event.preventDefault()
                        modelRequest.post("/api/logout").then(() => {
                            this.props.updatePermissions()
                                .then(() => {
                                    redirect.send("/", this.props, () => {
                                        message.success("You have successfully logged out.")
                                    })
                                })
                        })
                    }}>
                        <Button type="submit" variant='success' className="navbar-btn">Log Out</Button>
                    </form>
                }
            </Container>
        </Navbar>)
    }
}

export default DefautlNavbar
