import { modelRequest } from "@stem-sims/nexus" 

/**
 * Switches to organization/group
 * 
 * @param id organization ID
 * @param groupName name of group
 */
export function post(id: string, groupName: string) : Promise<{ message: string }> {
    return modelRequest.post('/api/organizations/switch', {
        id: id,
        groupName: groupName
    })
}
