import React from 'react'
import Button from 'react-bootstrap/Button'
import ClipLoader from 'react-spinners/ClipLoader'
import { useFormikContext } from 'formik'

type SubmitButtonProps = {
    submitting: boolean
    disabled?: boolean
    children: React.ReactNode
}
export default function SubmitButton({ submitting, disabled, children }: SubmitButtonProps) {
    
    const formik = useFormikContext<unknown>()
    if (formik?.isSubmitting) submitting = true

    return (<Button type="submit" variant="primary" id="organization-name-submit" className="btn-lg" disabled = {submitting || disabled}>
        {submitting ?
            <ClipLoader
                size={20}
                color={"#123abc"}
                loading={submitting}
            />
            : children
        }
    </Button>)
}
