import React from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import { Button, Image } from 'react-bootstrap'
import { FormikControl } from '@stem-sims/nexus'
import { useFormikContext } from "formik"
import stringToFloat from '../../helpers/stringToFloat'

type PriceCardProps = {
    color: string
    icon?: string
    title: string
    price: string
    priceUnit: string
    features: string[]
    hasInput?: boolean
    name?: string
    linkTo?: string
}

function PriceCard(props: PriceCardProps) {
    return (<>
        <Card className={`col-lg-5 price-card px-0 mt-5`} style={{borderColor: props.color}}>
            <Card.Body className='d-flex flex-column justify-content-between px-5'>
                <div>
                    {props.icon && <div className='d-flex'>
                        <Image className="mx-auto shadow bg-white rounded mb-5" width={"250px"} src={props.icon} alt={props.title}/>
                    </div>}
                    <Row className='justify-content-between flex-nowrap'>
                        <div className={'d-inline col-7 align-items-center text-start g-0'}>
                            <h2 className="h4 d-inline-block mb-0"><strong>{props.title}</strong></h2>
                        </div>
                        {props.title !== "Training" &&
                        <div className="col price text-end g-0">
                            <strong>
                                ${props.price}
                            </strong>
                            <span>
                            {props.priceUnit}
                            </span>
                        </div>}
                    </Row>

                    <ul className="text-start mt-4 ms-n3">
                        {props.features.map((feature) => 
                            <li className={'font-light mb-3 pb-3'} key={feature}>{feature}</li>
                        )}
                    </ul>
                </div>
                {props.hasInput && <CardInput name={props.name} /> }
            </Card.Body>
            {props.linkTo ? 
            <div className='d-flex flex-column text-light align-items-center justify-content-middle p-3' style={{borderRadius: "0 0 15px 15px", background: props.color}}>
                <p className='h5 text-light text-center w-75 fw-bold'>Interested in discounted group pricing?</p>
                <p className='text-light text-center my-2'>We offer discounts for larger group license orders</p>
                <a href={props.linkTo} className='btn btn-lg bg-light px-5' style={{color: props.color}}><strong>View Group Pricing</strong></a>
            </div>
            :
            <p className='h5 text-dark text-center pb-4' style={{fontWeight: "400"}}>Contact us for <param name="" value="" />pricing</p>
            }
        </Card>
    </>)
}

function CardInput({ name } : { name: string }) {
    const { isSubmitting, setFieldValue, values } = useFormikContext()
    return (
        <Row className='justify-content-center mt-3'>
            <label id="student-licenses" className="col control-label text-center pe-md-3">Licenses</label>
            <InputGroup className="col">
                <Button
                    disabled={isSubmitting}
                    onClick={() => {
                        if(values[name] === 0) return
                        setFieldValue(name, values[name] - 1)
                    }}
                    variant='secondary'
                >
                    <i className='fa fa-minus' />
                </Button>
                <FormikControl
                    name={name}
                    aria-describedby="student-licenses"
                    className="text-center"
                    onBlur={() => {
                        let value = values[name]
                        if (typeof value === "string") {
                            value = Math.ceil(stringToFloat(value))
                        }
                        setFieldValue(name, value)
                    }}
                />
                <Button
                    disabled={isSubmitting}
                    onClick={() =>
                        setFieldValue(name, values[name] + 1)
                    }
                    variant='secondary'
                >
                    <i className='fa fa-plus' />
                </Button>
            </InputGroup>
        </Row>
    )
}

export default PriceCard;
