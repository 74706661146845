import React from 'react'
import Button from "react-bootstrap/Button"
import redirect from '../../../../components/redirect/redirect'
import message from '../../../../components/message/message'
import BeatLoader from 'react-spinners/BeatLoader'
import { Link } from 'react-router-dom'
import buildQuery from '../../../../helpers/buildQuery'
import { modelRequest } from '@stem-sims/nexus'

class Manage extends React.Component {
    state = {
        permissions: [],
        allChecked: false,
        loading: true
    }
    componentDidMount() {
        modelRequest.get('/api/students/share').then((res) => {
            if (res.permissions.length > 0) {
                this.setState({ permissions: res.permissions, loading: false })
            } else {
                return redirect.send("/students", this.props, () => {
                    message.error("You do not currently have any students you are sharing to other users.")
                })
            }

        }).catch((err) => {
            message.error(err ?? "There has been an error getting shared data regarding your student(s).")
        })
    }

    render() {
        var { permissions, allChecked } = this.state
        return (<React.Fragment>
            <h3 className="subheader-text text-center">Manage Student Sharing</h3>
            {this.state.loading ?
                <BeatLoader
                    size={10}
                    color={"#123abc"}
                    loading={this.state.loading}
                />
                :
                <form className="form-horizontal form-validate" noValidate method="get" action="/students/share/revoke">
                    <ul id="checkbox-list" className="list-group">
                        {
                            permissions.map((permission) => {
                                const standardVariables = buildQuery({ id: permission.studentID })

                                return (
                                    <li className="list-group-item action-list">
                                        <input className="checkbox-list-item" type="checkbox" id={`checkbox-${permission.customer}-${permission.studentID}`} name={`permissions[${permission.customer}][]`} value={permission.studentID} />

                                        <label className="break-words" role="button" for={`checkbox-${permission.customer}-${permission.studentID}`}><strong>{permission.student}</strong> shared to <strong>{permission.customer}</strong></label>

                                        <span className="float-right">
                                            <Link to={`/students/share/edit?${standardVariables}`} className="action-link">Revoke</Link>
                                        </span>
                                    </li>)

                            })
                        }
                    </ul>
                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            <input className="checkbox-shortcut mb-2" type="checkbox" id="checkbox-shortcut" checked={allChecked} onChange={() => {
                                var checkboxes = document.getElementsByClassName('checkbox-list-item')
                                for (var checkbox of checkboxes)
                                    checkbox.checked = !allChecked

                                this.setState({ allChecked: !allChecked })
                            }} />

                            <label role="button" for="checkbox-shortcut">Select all</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 subheader-text">
                            <Button type="submit" name="revoke" value="revoke" className="btn btn-md">Revoke Selected</Button>
                        </div>
                    </div>
                </form>
            }
        </React.Fragment>
        )
    }
}


export default Manage
