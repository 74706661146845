import { modelRequest } from '@stem-sims/nexus'

export interface PriceData {
    discountCodeMessage?: {
        message?: string
        messageType?: "clear" | "error" | "success" | "warning"
    },
    prices?: Record<"math" | "reading" | "emergentReader" | "training", number>
    subTotals: {
        math: number,
        reading: number,
        emergentReader?: number
    }
    price?: number
}

interface PriceResponse {
    prices: Record<string, number>
    subTotals: {
        math: number
        reading: number
    }
    discount?: {
        success: false
        message: string
    } | {
        success: true
        message: string
        type: "Fixed" | "Percentage"
        amount: number
    }
    total: number
}

async function getPriceData({ reading=1, math=1, emergentReader=1, discountCode="" }) {
    let response: PriceData = {
        discountCodeMessage: {},
        subTotals: {
            math: 0,
            reading: 0,
            emergentReader: 0
        }
    };

    let products = (await modelRequest.get('/api/products', { params: { math, reading, emergentReader, discountCode } })) as PriceResponse

    response.prices = {
        reading: products.prices?.["Reading Subscription"],
        math: products.prices?.["Math Subscription"],
        emergentReader: products.prices?.["Emergent Reader Subscription"],
        training: products.prices?.["Training"],
    }
    response.price = products.total
    response.subTotals = products.subTotals
    if (!discountCode) {
        response.discountCodeMessage.messageType = "clear"
    } else if (!products.discount.success) {
        response.discountCodeMessage.messageType = "error"
    } else {
        response.discountCodeMessage.messageType = "success"
    }
    response.discountCodeMessage.message = products.discount?.message ?? ""
    return response
}

export default getPriceData;
