import React from "react"
import Container from "react-bootstrap/Container"
import { Route, Link, RouteComponentProps } from "react-router-dom"
import BeatLoader from "react-spinners/BeatLoader"
import * as accountInfo from "../shared/routes/accountInfoRoutes"
import Information from "../pages/account/information/Information"
import Password from "../pages/account/password/Password"
import Subscription from "../pages/account/subscription/Subscription"
import { AccountInfoProps } from "../shared/types/accountTypes"

interface Props extends RouteComponentProps<{}, {}, { info: AccountInfoProps }>
{
    type: string
}


class Account extends React.Component<Props, {
    username: string,
    loading: boolean,
    info: AccountInfoProps
}> {
    state = {
        username: null,
        info: null,
        loading: true
    }
    updateInfo = () => {
        accountInfo.get()
        .then((info) => {
                this.setState({info, loading: false})
        })
    }

    componentDidMount() {
        if (this.props.location?.state?.info) {
            this.setState({info: this.props.location.state.info}, () => {
                this.updateInfo()
            })
        }
        else {
            this.updateInfo()
        }
        
    }

    render() {
        var { type } = this.props
        return (
            <Container className="text-center">
                {this.state.info?.verifiedEmail === false ?
                    <div className="alert alert-danger">
                    You haven't yet verified your email address, and will not have access to manage sharing your subscription to other users until you do. If you'd like us to resend your verification link, please press <Link to="/account/verify-email-address/resend">this link</Link>.
                    </div> : ""}

                    <h1 className="text-center mt-3">{this.state.info?.username}</h1>
                        
                    {this.state?.info?.isSamlUser ?
                        <Route component = {Subscription} />
                        : <>
                        <div className = "row subheader-text">
                            <div className = "col-sm-6 col-md-4">
                                <Link to = {{
                                    pathname: "/account/information",
                                    state: { info: this.state.info }
                                }} className = {`btn btn-secondary btn-lg ${type === "information" ? "active" : ""}`}>Information</Link>
                            </div>
                            
                            <div className = "col-sm-6 col-md-4">
                                <Link to = {{
                                    pathname: "/account/password",
                                    state: { info: this.state.info }
                                }} className = {`btn btn-secondary btn-lg ${type === "password" ? "active" : ""}`}>Password</Link>
                            </div>
                            
                            <div className = "col-sm-6 offset-sm-3 col-md-4 offset-md-0">
                                <Link to = {{
                                        pathname: "/account/subscription",
                                        state: { info: this.state.info }
                                    }} className = {`btn btn-secondary btn-lg ${type === "subscription" ? "active" : ""} `}>Subscription
                                    {/* If soon to expire */}
                                    {/* <span className="glyphicon glyphicon-alert"></span> */}
                                    {/* If expired */}
                                    {/* <span className="glyphicon glyphicon-remove"></span> */}
                                </Link>
                            </div>
                        </div>
                        <Route exact path = "/account/information"  render={(props) => {
        
                            return this.state.loading ? 
                                    <BeatLoader
                                        size={10}
                                        color={"#123abc"}
                                        loading={true} />
                                                
                                    :
                                    <Information {...props} info = {this.state.info} updateInfo = {this.updateInfo} />
                            }
                        }/>
                        <Route exact path = "/account/password"     component = {Password} />
                        <Route exact path = "/account/subscription" component = {Subscription} />
                    </>
                }
            </Container>
        )
    }
}

export default Account
