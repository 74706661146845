import React from 'react'
import { Route, Link } from "react-router-dom"
import { RouteComponentProps } from 'react-router'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import redirect from '../components/redirect/redirect'
import message from '../components/message/message'
import { Phonics, Phrases, Stories, Alphabet, MathProbe } from '@stem-sims/great-leaps-react-common'
import { mathSubjects } from '../shared/types/mathTypes'
import { storyLevels } from '../shared/routes/readingRoutes'
import { isStoryType } from '../shared/routes/probeListRoutes'
import { MatchParams } from '../shared/types/probeListTypes'
import { ProbeContext } from '../pages/probes/ProbeContext'
import AccessibilityModal from '../pages/probes/components/AccessibilityModal'
import { modelRequest } from '@stem-sims/nexus'

interface StudentProbesProps extends RouteComponentProps<MatchParams> {
    type: string
}

class Probes extends React.Component<StudentProbesProps> {
    /*********************
    * Global Variables
    *********************/
    state = {
        //returned from API Request
        title: "",
        responses: [],
        skillSet: null,
        sectionClass: null,
        groupLength: null,
        indentedParagraphs: null,
        fontSize: 1,
        setFontSize: (fontSize) => this.setState({ fontSize: fontSize })
    }


    /****************
    * React Functions
    *****************/

    componentDidMount() {
        const { probeNumber } = this.props.match.params
        const { type } = this.props

        if (isNaN(parseInt(probeNumber))) {
            redirect.send('/student/dashboard', this.props, () => {
                message.error('Please select exercises again.')
            })
        }

        modelRequest.get('/api/probe', {
            params: {
                type: type,
                number: parseInt(probeNumber)
            }
        }).then((data) => {
            this.setState(data)
        }).catch((error) => {
            return redirect.send("/student/dashboard", this.props, () => {
                message.error(error)
            })
        })
    }

    redirectStudent = async () => {
        const { probeNumber } = this.props.match.params
        const { type } = this.props
        const probeInfo = await modelRequest.get('/api/probe', {
            params: {
                type: type,
                number: parseInt(probeNumber)
            }
        })
        let responses = probeInfo?.responses
        if (type === "phonics") {
            responses = probeInfo?.responses?.[0]?.responses
        }

        const prevSubmission = probeInfo?.prevSubmission
        const prevSubmissionDate = new Date(prevSubmission?.Created)
        const errors = prevSubmission?.Errors
        const prevSubmissionProbeNumber = prevSubmission?.Probe_Number
        const allResponsesRecorded = responses?.length === probeInfo?.prevSubmission?.Responses
        const phonicsPlacement = type === "phonics" && parseInt(probeNumber) <= 3

        let errorRequirementMet = errors <= 2
        if (type === "alphabet" || phonicsPlacement) {
            errorRequirementMet = errors === 0
        }
        const goalMet = allResponsesRecorded && errorRequirementMet && !phonicsPlacement && !isStoryType(type)
        const submissionCompletedToday = prevSubmissionDate > new Date(new Date().setHours(0, 0, 0, 0))
        if (goalMet && submissionCompletedToday && prevSubmissionProbeNumber === parseInt(probeNumber)) {
            return redirect.send("/student/goal", this.props)
        }
        redirect.send('/student/dashboard', this.props)
    }

    render() {
        const { title, responses, groupLength, sectionClass, indentedParagraphs, skillSet } = this.state
        const { probeNumber } = this.props.match.params

        var commonRouteProps = {
            exerciseNumber: probeNumber,
            title: title,
            responses: responses,
            hideGhosting: true
        }
        return (<ProbeContext.Provider value={this.state}>
            <Row className='justify-content-between align-items-end'>
                <Col>
                    <Link to="/student/dashboard" onClick={this.redirectStudent} className='nav-link gl-blue m-3'>
                        <i className='fa fa-arrow-left fa-lg px-2' />
                        <span className='d-none d-md-inline'>Return to Exercises</span>
                    </Link>
                </Col>
                <Col className='d-flex justify-content-end px-4'>
                    <AccessibilityModal />
                </Col>
            </Row>
            <Route exact path="/student/probes/phonics/:probeNumber">
                <div className={`align-self-center col-lg-10 text-center font-${this.state.fontSize}`}>
                    <Phonics {...commonRouteProps} sectionClass={sectionClass} groupLength={groupLength} />
                </div>
            </Route>
            <Route exact path="/student/probes/alphabet/:probeNumber">
                <div className={`align-self-center col-lg-10 text-center font-${this.state.fontSize}`}>
                    <Alphabet {...commonRouteProps} sectionClass={sectionClass} groupLength={groupLength} />
                </div>
            </Route>
            <Route exact path="/student/probes/phrases/:probeNumber">
                <div className={`align-self-center col-lg-11 text-center font-${this.state.fontSize}`}>
                    <Phrases {...commonRouteProps} sectionClass={sectionClass} />
                </div>
            </Route>
            {
                storyLevels.map((type) => {
                    return (
                        <Route exact path={`/student/probes/${type}/:probeNumber`}>
                            <div className={`story-probe align-self-center col-lg-10 text-center font-${this.state.fontSize} `}>
                                <Stories {...commonRouteProps} indentedParagraphs={indentedParagraphs} />
                            </div>
                        </Route>)
                })
            }
            {
                mathSubjects.map((subject) => {
                    return (
                        <Route exact path={`/student/probes/${subject}/:probeNumber`}>
                            <div className={`align-self-center col-lg-10 text-center font-${this.state.fontSize}`}>
                                {/* @ts-ignore */}
                                <MathProbe {...commonRouteProps} showAnswer={false} subject={subject} skillSet={skillSet} />
                            </div>
                        </Route>
                    )
                })
            }
            <div className="text-center">
                <Button onClick={this.redirectStudent} className="btn-lg">
                    <i className="fas fa-arrow-left me-2" />
                    <span>Back </span>
                    <span className="d-none d-sm-inline hidden-sm">
                        to Exercise List
                    </span>
                </Button>
            </div>
        </ProbeContext.Provider>)
    }
}
Probes.contextType = ProbeContext
export default Probes
