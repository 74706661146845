import React from "react"
import ResponseComponent from "../pages/probes/Response"
import "./Awareness.css"

type Text = {
    isBold: boolean
    isBlue: boolean
    text: string
    previousError: boolean
}

type Group = {
    type: "Instructions" | "Response"
    style: "Paragraph" | "Linear" | "Columnar"
    columns: number
    text: Text[]
    number: number
}

export type Page = {
    pageType: "Oral Expression" | "Timed Activity" | "Untimed Activity" | "Prep"
    title: string
    groups: Group[]
}

export type AwarenessProps = {
    responses: Page[]
    exerciseNumber: number
    title: string
    onClick?: () => void
    onDoubleClick?: () => void
}

function BoldWrapper({ active, children }) {
    if (active) {
        return <b>{children}</b>
    } else {
        return <>{children}</>
    }
}

function ItalicWrapper({ active, children }) {
    if (active) {
        return <i>{children}</i>
    } else {
        return <>{children}</>
    }
}

function BlueWrapper({ active, children }) {
    return <span className={active ? "text-primary" : ""}>{children}</span>
}

function buildParaGroup(group: Group, groupNumber, prevResponseCount, isResponse: boolean, isNumbered, onClick, onDoubleClick) {
    if (isResponse) {
        return <div
            className={`col-12 response ${group.text[0].previousError ? "previous-error" : ""}`}
            onClick={onClick.bind(null, prevResponseCount + 1, {
                groupNumber,
                responseNumber: 1
            })}
            onDoubleClick={onDoubleClick.bind(null, prevResponseCount + 1)}
        >
            <div className={`response-text text-start ${group.text[0].previousError} ? "previous-error" : ""`} id={`response-${prevResponseCount + 1}`}>
                    {isNumbered &&
                        <>`${prevResponseCount + 1} `</>
                    }
                    {group.text.map((fragment, index) => {
                        return <>
                            <BoldWrapper active={fragment.isBold}>
                                <ItalicWrapper active={fragment.isBlue}>
                                    <BlueWrapper active={fragment.isBlue}>
                                        {fragment.text}
                                    </BlueWrapper>
                                </ItalicWrapper>
                            </BoldWrapper>
                        </>
                    })}
            </div>
        </div>
    } else {
        return <div className="col-12">
            <p className="text-start">
                {group.text.map((fragment, index) => {
                    const text = isNumbered ? `${prevResponseCount + 1} ${fragment.text}` : fragment.text
                    return <>
                        <BoldWrapper active={fragment.isBold}>
                            <ItalicWrapper active={fragment.isBlue}>
                                <BlueWrapper active={fragment.isBlue}>
                                    {text}
                                </BlueWrapper>
                            </ItalicWrapper>
                        </BoldWrapper>
                    </>
                })}
            </p>
        </div>
    }
}

function buildColumnGroup(group: Group, groupNumber: number, isResponse: boolean, onClick, onDoubleClick) {
    return <>
        {group.text.map((fragment, index) => {
            const responseNumber = index + 1
            return <div className={`col-${12 / group.columns} response-container text-center awareness-columns`}>
                <ResponseComponent
                    hadPreviousError={fragment.previousError}
                    response={fragment.text}
                    responseNumber={responseNumber}
                    onClick={onClick.bind(null, responseNumber, {
                        responseNumber,
                        groupNumber,
                    })}
                    onDoubleClick={onDoubleClick.bind(null, responseNumber)}
                />
            </div>
        })}
    </>
}

function buildLinearGroup(group: Group, groupNumber: number, prevResponseCount, isNumbered, onClick, onDoubleClick) {
    const colWidth = 12 / group.columns
    const rows = []
    let fragments = []
    const hadPreviousError = false
    group.text.forEach((fragment, index) => {
        const responseNumber = Math.floor(index / group.columns) + 1 + prevResponseCount
        const isNewRow = index % group.columns === 0
        const responseText = isNumbered && isNewRow ? `${responseNumber}. ${fragment.text}` : fragment.text
        fragments.push(
            <div className={`col-${colWidth} response-container text-start`}>
                <BoldWrapper active={fragment.isBold}>
                    <ItalicWrapper active={fragment.isBlue}>
                        <BlueWrapper active={fragment.isBlue}>
                            {responseText}
                        </BlueWrapper>
                    </ItalicWrapper>
                </BoldWrapper>
            </div>
        )
        if (fragments.length === group.columns) {
            rows.push(<div
                className={`${isNumbered ? "response" : ""} ${fragment.previousError ? "previous-error" : ""}`}
                onClick={isNumbered ? onClick.bind(null, responseNumber, {
                    groupNumber,
                    responseNumber: responseNumber - prevResponseCount
                }) : undefined}
                onDoubleClick={isNumbered ? onDoubleClick.bind(null, responseNumber) : undefined}
            >
                <div className={`${isNumbered ? "response-text" : ""} ${hadPreviousError ? 'previous-error' : ''}`} id={isNumbered ? `response-${responseNumber}` : ""}>
                    <div className="row">
                        {fragments}
                    </div>
                </div>
            </div>)
            fragments = []
        }
    })
    return <>
        {rows}
    </>
}

export function buildResponseList(page: Page, onClick: ()=>void, onDoubleClick: ()=>void ) {
    let responseCount = 0
    return page?.groups.map((group, index) => {
        const isResponse = group.type === "Response"
        if (group.style === "Paragraph") {
            if (group.columns === 1) {
                const formattedGroup = buildParaGroup(group, index + 1, responseCount, isResponse, false, onClick, onDoubleClick)
                if (isResponse) {
                    responseCount += 1
                }
                return formattedGroup
            } else {
                const formattedGroup = buildLinearGroup(group, index + 1, responseCount, isResponse, onClick, onDoubleClick)
                if (isResponse) {
                    responseCount += 1
                }
                return formattedGroup
            }
        } else if (group.style === "Columnar") {
            return buildColumnGroup(group, index + 1, isResponse, onClick, onDoubleClick)
        } else if (group.style === "Linear") {
            const formattedGroup = buildLinearGroup(group, index + 1, responseCount, true, onClick, onDoubleClick)
            if (isResponse) {
                responseCount += group.text.length / group.columns
            }
            return formattedGroup
        } else {
            return []
        }
    }) ?? []
}
