import React from 'react'
import StudentForm from './StudentForm'
import * as statusModel from '../../../shared/routes/statusRoutes'
import { modelRequest, useModel } from '@stem-sims/nexus';
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify';
import { useRoster } from './StudentsContext';
import { ProgramsType } from '../../../shared/types/studentTypes';

type AddProps = {
    show: boolean
    orgId: string
}

function Add(props: AddProps) {
    const roster = useRoster()
    return (<>
        <Modal show={props.show} onHide={roster.hideModals} size={"lg"} className="student-modal">
            <AddForm orgId={props.orgId} />
        </Modal>
    </>)
}

function AddForm({ orgId }: { orgId: string }) {
    const roster = useRoster()
    const { response: status, loading: statusLoading } = useModel({
        model: statusModel.get
    })

    const disabledPrograms: ProgramsType[] = []
    const defaultProgramsSelected: ProgramsType[] = []

    if (!statusLoading) {
        if (status.math.available === 0) {
            disabledPrograms.push("Math")
        }
        if (status.reading.available === 0) {
            disabledPrograms.push("Reading")
        }
        if (status.emergentReader.available === 0) {
            disabledPrograms.push("Emergent Reader")
        }

        if (disabledPrograms.includes("Math") && disabledPrograms.includes("Emergent Reader")) {
            defaultProgramsSelected.push("Reading")
        } else if (disabledPrograms.includes("Reading") && disabledPrograms.includes("Emergent Reader")) {
            defaultProgramsSelected.push("Math")
        } else if(disabledPrograms.includes("Math") && disabledPrograms.includes("Reading")) {
            defaultProgramsSelected.push("Emergent Reader")
        }
        
        if (disabledPrograms.length === 3) {
            toast.error("There aren't enough licenses to add a student")
            roster.refreshStudents()
            roster.hideModals()
        }
    }
    return (
        <StudentForm
            onSubmit={async (values) => {
                try {
                    await modelRequest.post('/api/students/create', values)
                    roster.refreshStudents()
                    roster.hideModals()
                    toast.success("Your student has been created")
                }
                catch (error) {
                    toast.error(error ?? "There has been an error creating your student.")
                }
            }}
            disabledPrograms={disabledPrograms}
            submitLabel="Add"
            initialValues={{
                name: undefined,
                birthDay: undefined,
                programs: defaultProgramsSelected,
                grade: "None"
            }}
            orgId={orgId}
        />
    )
}

export default Add
