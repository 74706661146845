import React, { PropsWithChildren } from "react";
import Skeleton from 'react-loading-skeleton'

import "./sliding-panel.css";

// type Props = {
//   isOpen: boolean;
//   title?: string;
//   subtitle?: string;
//   from?: "left" | "right" | "bottom"; // "right" — default
//   children: React.ReactNode;
//   className?: string;
//   overlayClassName?: string;
//   width?: string; // CSS string for width
//   closeIcon?: React.ReactNode;
//   shouldCloseOnEsc?: boolean;
//   hideHeader?: boolean;
//   onRequestClose: () => void;
//   onAfterOpen?: () => void;
// };

type Props = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

export class SlidingPane extends React.Component<PropsWithChildren<Props>, { animating: boolean }> {
    state = {
        animating: true
    }

    setBackgroundOverflowHidden = () => {
        document.documentElement.style.overflowY = "hidden"
    }

    resetBackgroundOverflow = () => {
        document.documentElement.style.overflowY = "auto"
    }
    
    waitForAnimation = () => {
        setTimeout(() => {
            this.setState({ animating: false })
        }, 400)
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.waitForAnimation()
        }
    }

    componentDidUpdate(prevProps) {

        //if it was previously closed and now open wait for animation to end
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.setBackgroundOverflowHidden()
            this.waitForAnimation()
        }
        
        //if it was open and is closing then set animating
        if (prevProps.isOpen === true && this.props.isOpen === false) {
            this.resetBackgroundOverflow()
            this.setState({ animating: true })
        }
    }

    render() {
        return (
            <div className="slide-pane">
                <div className={`slide-pane-backdrop fade ${this.props.isOpen ? 'show' : 'hide'}`} onClick={() => this.props.onClose()} />
                <div className={`slide-pane-header ${ this.props.isOpen ? `slide-pane-open` : ''}`}>
                    <div className={`slide-pane-arrow-container py-2 fade ${this.props.isOpen ? 'show' : 'hide'}`}
                    onClick={() => this.props.onClose()}>
                        <i className="w-50 fa fa-arrow-left slide-pane-arrow px-3 my-auto"
                            aria-hidden="true"
                        />
                        <h2 className="">{ this.props.title }</h2>
                    </div>
                    <div className='slide-pane-content d-flex justify-content-center'>
                        {this.state.animating ? 
                            <Skeleton height={32} width={'100%'} />
                            :
                            this.props.children
                    }
                        
                        
                    </div>
                </div>
            </div>
        );
    }


    // return (
    //     <React.Fragment>
    //         <div class={"modal " + directionClass} id="side-panel" tabIndex="-1" aria-hidden="true" data-backdrop={shouldCloseOnEsc ? "" : "static"}>
    //             <div className="modal-dialog modal-dialog-scrollable"> style={{
    //                 content: { width: width || "60%" }
    //             }}
    //                 <div className="modal-content">
    //                     <div className="slide-pane__header modal-header">
    //                         <div className="slide-pane__close" data-dismiss="modal">
    //                             {closeIcon || <IconClose />}
    //                         </div>
    //                     </div>
    //                     <div className="slide-pane__content modal-body">{children}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     </React.Fragment>
    // );
}

export default SlidingPane;
