import React from 'react'

import facebook from '../content/images/facebook.svg'
import youtube from '../content/images/youtube.svg'
import instagram1x from '../content/images/instagram-1x.png'
import instagram2x from '../content/images/instagram-2x.png'
import instagram3x from '../content/images/instagram-3x.png'
import instagram4x from '../content/images/instagram-4x.png'
import pinterest from '../content/images/pinterest.svg'

function SocialMedia() {
    return (
        <React.Fragment>
            <div className="social-networking-bar d-none d-xl-block">
                <div className="text-center social-links">
                    <div className="row">
                        <a href="https://www.facebook.com/GreatLeapsEDU/" target="_blank" rel="noreferrer">
                            <img
                                className="float-left"
                                src={facebook}
                                alt="Facebook"
                                title="Like Great Leaps on Facebook"
                            />
                        </a>
                    </div>

                    <div className="row">
                        <a href="https://www.youtube.com/GreatLeapsEDU" target="_blank" rel="noreferrer">
                            <img
                                className="float-left"
                                src={youtube}
                                alt="YouTube"
                                title="Subscribe to Great Leaps on YouTube"
                            />
                        </a>
                    </div>

                    <div className="row">
                        <a href="https://www.instagram.com/greatleapsreading/" target="_blank" rel="noreferrer">
                            <img
                                className="float-left"
                                src={instagram1x}
                                srcSet={instagram1x + " 1x, " + instagram2x + " 2x, " + instagram3x + " 3x, " + instagram4x + " 4x"}
                                alt="Instagram"
                                title="Follow Great Leaps on Instagram"
                            />
                        </a>
                    </div>

                    <div className="row">
                        <a href="https://www.pinterest.com/greatleapsedu/" target="_blank" rel="noreferrer">
                            <img
                                className="float-left"
                                src={pinterest}
                                alt="Pinterest"
                                title="Follow Great Leaps on Pinterest"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="row text-center social-links d-xl-none">
                <div className="col-6">
                    <a href="https://www.facebook.com/GreatLeapsEDU/" target="_blank" rel="noopener noreferrer">
                        <img
                            className="float-right"
                            src={facebook}
                            alt="Facebook"
                            title="Like Great Leaps on Facebook"
                        />
                    </a>

                    <a href="https://www.youtube.com/GreatLeapsEDU" target="_blank" rel="noopener noreferrer">
                        <img
                            className="float-right"
                            src={youtube}
                            alt="YouTube"
                            title="Subscribe to Great Leaps on YouTube"
                        />
                    </a>
                </div>

                <div className="col-6">
                    <a href="https://www.instagram.com/greatleapsreading/" target="_blank" rel="noopener noreferrer">
                        <img
                            className="float-left"
                            src={instagram1x}
                            srcSet={instagram1x + " 1x, " + instagram2x + " 2x, " + instagram3x + " 3x, " + instagram4x + " 4x"}
                            alt="Instagram"
                            title="Follow Great Leaps on Instagram"
                        />
                    </a>

                    <a href="https://www.pinterest.com/greatleapsedu/" target="_blank" rel="noopener noreferrer">
                        <img
                            className="float-left"
                            src={pinterest}
                            alt="Pinterest"
                            title="Follow Great Leaps on Pinterest"
                        />
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SocialMedia
