import Row from 'react-bootstrap/Row';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AuthenticationModal from './AuthenticationModal';
import React, { Dispatch, SetStateAction } from 'react';
import { useFormikContext } from 'formik';

type LoggedOutProps = {
    authenticationModal: boolean
    setAuthenticationModal: Dispatch<SetStateAction<boolean>>
    submitting: boolean
    purchaseView: "add" | "extend" | "default"
}

function LoggedOutLinks(props: LoggedOutProps) {
    const { isSubmitting, values } = useFormikContext()
    const disabled = isSubmitting || (values["math"] === 0 && values["reading"] === 0 && values["emergentReader"] === 0)

    return (
        <Row>
            <OverlayTrigger overlay={
                <Tooltip id="tooltip-disabled-checkout" className={disabled ? "tooltip-disabled" : "d-none"}>
                    You have no licenses selected to buy
                </Tooltip>
            }>
                <span className="d-inline-block">
                    <Button
                        type="button"
                        className="btn-lg"
                        disabled={disabled}
                        {...(props.purchaseView === "extend" && {
                            "type": "button",
                            "data-toggle": "modal",
                            "data-target": "#warning-modal",
                        })}
                        onClick={() => props.setAuthenticationModal(true)}
                    >
                        Proceed to Checkout
                    </Button>
                </span>
            </OverlayTrigger>
            {props.authenticationModal && <AuthenticationModal {...props} licenses={values} />}
        </Row>
    );
}

export default LoggedOutLinks;