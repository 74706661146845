import React from "react";
import getURLParams from "../../helpers/getURLParams";
import { modelRequest } from "@stem-sims/nexus";
import { Page, buildResponseList } from "../../helpers/AwarenessBuilder";
import BeatLoader from "react-spinners/BeatLoader";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";

export default function AwarenessOralExpression(props) {
    const probeNumber = props.match.params.probeNumber
    const studentId = getURLParams(props).id
    const history = useHistory()
    const location = useLocation() as any

    const [ probe, setProbe ] = React.useState<{ responses: Page[]}>(null)

    const [ view, setView ] = React.useState(location.state?.view || "Oral Expression")

    React.useEffect(() => {
        modelRequest.get('/api/probe', {
            params: {
                type: "awareness",
                number: parseInt(probeNumber),
                studentID: studentId,
            }
        }).then((response) => {
            setProbe(response)
        })
    }, [probeNumber, studentId])

    const expressionPage = probe?.responses?.find(page => page.pageType === "Oral Expression")
    const prepPage = probe?.responses?.find(page => page.pageType === "Prep")

    const responseList = expressionPage && buildResponseList(expressionPage, () => {}, () => {})
    const prepResponseList = prepPage && buildResponseList(prepPage, () => {}, () => {})

    React.useEffect(() => {
        if (probe?.responses && !expressionPage) {
            history.push("/dashboard")
        }
    })

    return <React.Fragment>
        {!responseList ?
            <div style={{ margin: '0 auto', display: 'table', height: '50vh' }}>
                <div
                    style={{
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                    }}
                >
                    <BeatLoader size={15} color={'#123abc'} loading={true} />
                </div>
            </div> :
            <React.Fragment>
                {view === "Oral Expression" && <>
                    <h1 className={'h3 mb-4 text-center'}>
                        Exercise {probeNumber} Oral Expression
                    </h1>
                    <div className="row content-block">
                        {responseList}
                    </div>
                </>}

                {(view === "Prep" || view === "PrepOnlyView") && <>
                    <h1 className={'h3 mb-4 text-center'}>
                        Exercise {parseInt(probeNumber) + 1 } Prep: {prepPage.title}
                    </h1>
                    <div className="row mx-5">
                        {prepResponseList}
                    </div>
                </>}

                {prepPage &&
                    <div className="subheader-text row">
                        <div className="col-sm-6">
                            {view === "Oral Expression" &&
                                <Button onClick={() => setView("Prep")} className="btn btn-secondary btn-lg">View Next Exercise Prep</Button>
                            }
                            {view === "Prep" &&
                                <Button onClick={() => setView("Oral Expression")} className="btn btn-secondary btn-lg">Return to Oral Expression</Button>
                            }
                        </div>

                        {view === "PrepOnlyView" && <div className="col-sm-12">
                            <Link to={`/probes/awareness?&id=${studentId}`} className="btn btn-secondary btn-lg">Return to Exercise List</Link>
                        </div>}

                        {!location.state.view && <div className="col-sm-6">
                            <Link to="/dashboard" className="btn btn-secondary btn-lg">Next Student</Link>
                        </div>}
                    </div>
                }
                {!prepPage &&
                    <div className="subheader-text row">
                        <div className="col-sm-12">
                            <Link to="/dashboard" className="btn btn-secondary btn-lg">Next Student</Link>
                        </div>
                    </div>
                }
            </React.Fragment>}
    </React.Fragment>;
}
