import React from 'react'
import Button from "react-bootstrap/Button"
import { initializeFormValidation } from '../../../../helpers/forms/form-validation'
import getURLParameters from '../../../../helpers/getURLParams'
import message from '../../../../components/message/message'
import redirect from '../../../../components/redirect/redirect'
import ClipLoader from 'react-spinners/ClipLoader'
import { modelRequest } from '@stem-sims/nexus'

class EditShared extends React.Component {
    state = {
        permission: {},
        readPermission: false,
        writePermission: false,
        submitting: false,
        loading: true
    }

    componentDidMount() {
        let params = getURLParameters(this.props)

        modelRequest.get('/api/students/share').then((data) => {
            var shared = data.permissions
            for (var i = 0; i < shared.length; i++) {
                var permission = shared[i]
                if (permission.studentID === params.id) {
                    this.setState({ permission: permission, readPermission: permission.read, writePermission: permission.write, loading: false })
                    return
                }
            }

        }).catch((err) => {
            redirect.send("/students/share/manage", this.props, () => {
                message.error("There has been an error finding your permission. Please try again.")
            })
        })

        initializeFormValidation(this.onSubmit);
    }

    onSubmit = () => {
        let { permission } = this.state
        let params = getURLParameters(this.props)
        this.setState({ submitting: true, loading: true }, () => {
            modelRequest.delete('/api/students/share', {
                data: {
                    studentID: params.id,
                    customerUsername: permission.customer
                }
            }).then((data) => {
                redirect.send('/students/share/manage', this.props, () => {
                    message.success('Permissions have been successfully revoked.')
                })
            }).catch((err) => {
                message.error(err)
            }).finally(() => {
                this.setState({ submitting: false, loading: false })
            })
        })
    }

    render() {
        var { permission, loading, submitting } = this.state
        return (
            <div className='text-center mt-3'>
                <h1>Revoke Student Sharing Setting</h1>
                <p>Please confirm revoking existing sharing settings below.</p>

                <form className="form-horizontal form-validate" noValidate method="post">

                    <label className="me-3">Student:</label>
                    <span className="fw-bold">{permission.student}</span>
                    <div className='mb-3'>
                        <label className="me-3">Username:</label>
                        <span className="fw-bold">{permission.customer}</span>
                    </div>
                    <div>
                        <Button type="submit" className="btn btn-secondary btn-lg" disabled={loading}>{submitting ?
                            <ClipLoader
                                size={20}
                                color={"#123abc"}
                                loading={this.state.submitting}
                            /> : "Revoke"}
                        </Button>
                    </div>
                </form>
            </div>
        )
    }
}

export default EditShared
