import React from 'react'
import * as organizationGroups from '../../../shared/routes/organizationGroupsRoutes'
import Form from '../../../components/forms/Form'
import Input from '../../../components/forms/Input'
import SubmitButton from '../../../components/forms/SubmitButton'
import message from '../../../components/message/message'
import redirect from '../../../components/redirect/redirect'
class AddGroup extends React.Component<{ updatePermissions: () => Promise<void> }> {

    submit = (values) => {
        return organizationGroups.post(values.Name.value).then((response) => {
            redirect.send('/manage', this.props)
        }).catch((err) => {
            return message.error(err)
        })
    }

    render() {
        return (
            <div className='text-center'>
                <h1 className='pt-3 h2 mt-4 pb-2'>Create A Group</h1>
                <Form submit={this.submit}>
                    {submitting => {
                        return (<>
                            <Input name="Name" longName="Group Name" placeholder="Name of Group" submitting={submitting} />
                            <SubmitButton submitting={submitting}>Create</SubmitButton>
                        </>)
                    }}
                </Form>
            </div>
        )
    }

}

export default AddGroup
