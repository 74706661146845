import React from 'react'
import Common from '../../../context/Common'
import Summary from '../../subscribe/Summary'

class Subscription extends React.Component {
    static contextType = Common

    render() {
        return (
            <React.Fragment>
                <h3>Current Subscriptions</h3>
                <Summary includeHeader={false} />
            </React.Fragment>
        )
    }
}

export default Subscription
