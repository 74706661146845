import React from 'react'
import { Link } from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { initializeFormValidation } from '../../../helpers/forms/form-validation'
import message from '../../../components/message/message'
import redirect from '../../../components/redirect/redirect'
import * as accountModel from "../../../shared/routes/accountRoutes"
import SubmitButton from '../../../components/forms/SubmitButton'

class RecoverByUsername extends React.Component {
    state = {
        submitting: false
    }
    componentDidMount() {
        initializeFormValidation(this.onSubmit)
    }

    onSubmit = () => {
        this.setState({submitting: true}, () => {
            accountModel.recover({
                username: document.getElementById('username')["value"],
            }).then(() => {
                return redirect.send("/", this.props, () => {
                    message.success("An email has been sent to the address associated with your account. Please follow the contained instructions to regain access to your account. If you do not receive this email within 15 minutes, please check your spam folder.")
                })
            }).catch((err) => {
                message.error(err)
            }).finally(() => this.setState({submitting: false}))
        })
    }

    render() {
        return (<Container className='text-center'>
            <h1 className='text-center py-3'>Recover Your Account Information</h1>
            <p>Enter your username to locate your account.</p>
            <p>If the username exists, an email will be sent to the address associated with your account with instructions to reset your password.</p>
            <form className="form-horizontal form-validate text-center" noValidate method="post">
                <Row className="align-items-center my-5">
                    <label htmlFor="username" className="d-none d-sm-inline col-sm-3 control-label text-end">Username</label>
                    <div className="col-sm-6">
                        <input type="text" className="form-control" disabled={this.state.submitting} id="username" name="username" placeholder="Username" autoFocus data-description="enter a username" />
                    </div>
                </Row>
                <SubmitButton submitting={this.state.submitting}>Continue</SubmitButton>
                <div className='mt-3'>
                    <Link to="/account/recover/email">Forgot Username?</Link>
                </div>
            </form>
        </Container>)
    }
}

export default RecoverByUsername
