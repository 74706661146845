import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { buildQuery } from "@stem-sims/great-leaps-react-common"
import { Col, Image, Row } from "react-bootstrap"
import { RecordsContext } from "../../pages/records/RecordsContext"
import { isStoryType } from "../../shared/routes/probeListRoutes"
import alphabet from "../../content/images/alphabet.svg"
import phonics from "../../content/images/phonics.svg"
import phrases from "../../content/images/phrases.svg"
import stories from "../../content/images/stories.svg"
import alphabetWhite from "../../content/images/alphabet_white.svg"
import phonicsWhite from "../../content/images/phonics_white.svg"
import phrasesWhite from "../../content/images/phrases_white.svg"
import storiesWhite from "../../content/images/stories_white.svg"
import alphabetGray from "../../content/images/alphabet_gray.svg"
import phonicsGray from "../../content/images/phonics_gray.svg"
import phrasesGray from "../../content/images/phrases_gray.svg"
import storiesGray from "../../content/images/stories_gray.svg"
import addition from "../../content/images/plus.svg"
import subtraction from "../../content/images/minus.svg"
import division from "../../content/images/divide.svg"
import multiplication from "../../content/images/multiply.svg"
import additionWhite from "../../content/images/plus_white.svg"
import subtractionWhite from "../../content/images/minus_white.svg"
import divisionWhite from "../../content/images/divide_white.svg"
import multiplicationWhite from "../../content/images/multiply_white.svg"
import additionGray from "../../content/images/plus_gray.svg"
import subtractionGray from "../../content/images/minus_gray.svg"
import multiplicationGray from "../../content/images/multiply_gray.svg"
import divisionGray from "../../content/images/divide_gray.svg"
import * as suggestedModel from '../../shared/routes/suggestedRoutes'

interface ProbeGroupHeaderParameters {
    link: string
    selected: boolean
    icon: any
}

interface SubjectPathParams {
    studentID: string
    typePath: string
    basePath?: string
    extraQuery?: Record<string, string>
}
export const subjectPath = ({studentID, typePath, basePath="/students/records", extraQuery = null}: SubjectPathParams) => {
    const standardVariables = buildQuery({ id: studentID, ...(extraQuery ?? {}) })
    return `${basePath}/${typePath}?${standardVariables}`    
}
export function SubjectTab({link,selected,icon}: ProbeGroupHeaderParameters) {
    return (<>
        <Link to={link} className = {`subject-tab ${selected ? "selected" : "border"}`}>
            <Image src={icon} className="img-fluid p-1" />
        </Link>
    </>)
}
export function MobileBottomTabs({tabs,hide,program}) {
    return(<>
        <Row className={`d-sm-none fixed-bottom bg-white g-0 ${hide && 'd-none'} ${program}`}>
            {tabs.map((Tab,i) => 
                <Col key={i}>
                    {Tab}
                </Col>
            )}
        </Row>
    </>)
}
export default function SubjectTabs({studentID}) {
    const context = useContext(RecordsContext)
    const [suggestedStory,setSuggestedStory] = useState(context.chartData?.suggestedStory)
    const type = context.type
    const isReading = context.program === "Reading"
    const isMath = context.program === "Math"
    const isAlphabet = type === "alphabet"
    const isPhonics = type === "phonics"
    const isPhrases = type === "phrases"
    const isAddition = type === "addition"
    const isSubtraction = type === "subtraction"
    const isMultiplication = type === "multiplication"
    const isDivision = type === "division"

    useEffect(() => {
        if(!context.chartData?.suggestedStory) {
            suggestedModel.get({
                studentID: studentID
            }).then((suggested) => setSuggestedStory(suggested.suggestedStory))
        }
    })

    const alphabetTab = (selectedIcon: string) => <SubjectTab selected={isAlphabet} link={subjectPath({studentID: studentID, typePath: "phonics/alphabet", extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isAlphabet ? selectedIcon : alphabetGray}/>
    const phonicsTab = (selectedIcon: string) => <SubjectTab selected={isPhonics} link={subjectPath({studentID: studentID, typePath: "phonics/phonics", extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isPhonics ? selectedIcon : phonicsGray} />
    const phrasesTab = (selectedIcon: string) => <SubjectTab selected={isPhrases} link={subjectPath({studentID: studentID, typePath: "phrases", extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isPhrases ? selectedIcon : phrasesGray} />
    const storiesTab = (selectedIcon: string) => <SubjectTab selected={isStoryType(type)} link={subjectPath({studentID: studentID, typePath: ("stories/" + suggestedStory), extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isStoryType(type) ? selectedIcon : storiesGray} />
    const additionTab =  (selectedIcon: string) => <SubjectTab selected={isAddition} link={subjectPath({studentID: studentID, typePath: "addition", extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isAddition ? selectedIcon : additionGray} />
    const subtractionTab = (selectedIcon: string) => <SubjectTab selected={isSubtraction} link={subjectPath({studentID: studentID, typePath: "subtraction", extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isSubtraction ? selectedIcon : subtractionGray} />
    const multiplicationTab = (selectedIcon: string) => <SubjectTab selected={isMultiplication} link={subjectPath({studentID: studentID, typePath: "multiplication", extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isMultiplication ? selectedIcon : multiplicationGray} />
    const divisionTab = (selectedIcon: string) => <SubjectTab selected={isDivision} link={subjectPath({studentID: studentID, typePath: "division", extraQuery: { status: context.filteredStatus, instructor: context.filteredInstructor }})} icon={isDivision ? selectedIcon : divisionGray} />

    return (<>
        <div className="d-none d-sm-flex flex-column flex-shrink-1">
            {isReading && alphabetTab(alphabet)}
            {isReading && phonicsTab(phonics)}
            {isReading && phrasesTab(phrases)}
            {isReading && storiesTab(stories)}
            {isMath && additionTab(addition)}
            {isMath && subtractionTab(subtraction)}
            {isMath && multiplicationTab(multiplication)}
            {isMath && divisionTab(division)}
        </div>
        {/* mobile tabs */}
        <MobileBottomTabs tabs={[alphabetTab(alphabetWhite), phonicsTab(phonicsWhite), phrasesTab(phrasesWhite), storiesTab(storiesWhite)]} hide={isMath} program={context.program}/>
        <MobileBottomTabs tabs={[additionTab(additionWhite), subtractionTab(subtractionWhite), multiplicationTab(multiplicationWhite), divisionTab(divisionWhite)]} hide={isReading} program={context.program}/>
    </>)
}
