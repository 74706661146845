import { modelRequest } from '@stem-sims/nexus'
import { SubscriptionStatus } from '../types/subscriptionTypes'

/**
 * Gets the subscription status of the account
 */
export function get(customerUsername?: string, groupName?: string) : Promise<SubscriptionStatus> {
    return modelRequest.get('/api/status', {
        params: {
            customerUsername: customerUsername,
            group: groupName
        }
    })
}
