import React, { useContext, useState } from 'react'
import ClipLoader from "react-spinners/ClipLoader"
import { Link } from 'react-router-dom'
import LoginContext from '../../../context/LoginContext'
import message from '../../../components/message/message'
import { models } from '@stem-sims/great-leaps-react-common'
import redirect from '../../../components/redirect/redirect'
import Common from '../../../context/Common'
import { toast } from 'react-toastify'

function CustomerLogin(props) {
    const [submitting, setSubmitting] = useState(false)
    const login = useContext(LoginContext)
    const context = useContext(Common)
    const messages = props?.useToast ? toast : message

    const submit = (event) => {
        event.preventDefault()
        event.stopPropagation()
        const usernameInput = document.getElementById("username") as HTMLInputElement
        const passwordInput = document.getElementById("password") as HTMLInputElement

        models.login.post(usernameInput.value, passwordInput.value, "customer").then((data) => {
            context.updatePermissions().then(() => {
                login.handleAfterLogin()
            })
        }).catch((err) => {
            message.clear()
            messages.error(err)
            checkAuth();
            (document.getElementById("password") as HTMLInputElement).value = ""

        }).finally(() => {
            setSubmitting(false)
        })
    }

    const checkAuth = () => {
        context.updatePermissions().then(() => {
            let auth = context?.auth
            if (auth.includes("customer")) {
                redirect.send("/dashboard", props, () => {
                    messages.error("You are already logged in.")
                })
            }
        }).catch((err) => {
            messages.error(err)
        })
    }

    return (
        <form className="form-horizontal form-validate" noValidate method="post" action="/account/log-in/teacher" onSubmit={submit}>
            <div className="mb-3 row">
                <label htmlFor="username" className="d-none d-sm-inline col-sm-3 control-label text-end">Username</label>

                <div className="col-sm-6">
                    <input type="text" disabled={submitting} className="form-control" id="username" name="username" placeholder="Username" required data-description="enter your username" autoFocus />
                </div>
            </div>

            <div className="mb-3 row">
                <label htmlFor="password" className="d-none d-sm-inline col-sm-3 control-label text-end">Password</label>

                <div className="col-sm-6">
                    <input type="password" disabled={submitting} className="form-control string" id="password" name="password" placeholder="Password" required data-type="password" min="5" data-description="enter your password" />
                </div>
            </div>

            <button type="submit" id="login-submit" disabled={submitting} className="btn btn-secondary btn-lg">
                {submitting ? <ClipLoader
                    size={20}
                    color={"#123abc"}
                    loading={submitting}
                /> : "Log In"}</button>


            <div className="mb-3 row">
                <p className="medium-small col-12"><Link to="/account/recover/username">Forgot my password</Link></p>
                <p className="col-12">{props.signUpLink}</p>
            </div>
        </form>
    )
}

export default CustomerLogin
