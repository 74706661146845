import React from 'react';

import { initializeFormValidation, validForm } from '../../../helpers/forms/form-validation';
import initializeLocations from '../../../helpers/forms/location-form';
import initializeAutosubmit from '../../../helpers/forms/autosubmit';
import initializeTextAreaSubmit from '../../../helpers/forms/textarea-submit';
import initializeSubmissionPrevention from '../../../helpers/forms/prevent-submit';

import ClipLoader from "react-spinners/ClipLoader";
import message from '../../../components/message/message';
import redirect from '../../../components/redirect/redirect';
import { modelRequest } from '@stem-sims/nexus'

class CreateTransaction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            "email-address": "",
            address: "",
            city: "",
            country: "",
            state: "",
            "postal-code": "",
            phone: "",
            fax: "",
            district: "",
            school: "",
            type: "",
            price: "",
            comments: "",

            loading: false,
            countriesOptions: []
        }
    }

    componentDidMount() {
        modelRequest.get("/api/countries").then((data) => {
            if (!data.countries) {
                const errorMessage = "Cannot connect to API"
                throw errorMessage;
            }
            this.setState({
                countriesOptions: data.countries
            }, () => {
                // initialize scripts for form
                initializeLocations();
                initializeFormValidation();

                initializeAutosubmit();
                initializeTextAreaSubmit();
                initializeSubmissionPrevention();
            });
        }).catch((error) => {
            redirect.send("/admin")
            message.error("Cannot connect to the server. Please refresh the page and try again.")
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;

        let value;
        if (target.type === "checkbox") {
            value = target.checked;
        } else if (target.type === "number") {
            value = parseInt(target.value, 10);
        } else {
            value = target.value;
        }

        this.setState({ [name]: value });
    }

    submitForm = (event) => {
        event.preventDefault()
        message.clear()

        var createTransactionForm = document.getElementById("create-transaction-form");
        var blurEvent = document.createEvent("Event");
        blurEvent.initEvent("blur", false, true);
        blurEvent.submission = false;
        blurEvent.showFeedback = false;

        if (validForm(createTransactionForm, blurEvent) === false) {
            return
        }

        this.setState({ loading: true }, () => {
            modelRequest.post("/api/admin/transaction", {
                contactName: this.state.name,
                emailAddress: this.state['email-address'],
                streetAddress: this.state.address,
                city: this.state.city,
                country: this.state.country,
                state: this.state.state,
                postalCode: this.state['postal-code'],
                phoneNumber: this.state.phone,
                faxNumber: this.state.fax,
                district: this.state.district,
                school: this.state.school,
                transactionType: this.state.type,
                transactionPrice: Number(this.state.price),
                comment: this.state.comments
            })
                .then((data) => {
                    redirect.send("/admin/create-transaction/success?name=" + this.state.name + "&created=" + data.transactionCreated + "&price=" + this.state.price)
                    message.success("Your transaction was successfully created, with details listed below.")
                })
                .catch((error) => {
                    return message.error("There has been an internal error.")
                }).finally(() => this.setState({ loading: false }))
        })
    }

    render() {

        return (
            <React.Fragment>
                <h1>Create Transaction</h1>

                <form id="create-transaction-form" className="form-horizontal form-validate" noValidate method="post" onSubmit={this.submitForm}>
                    <div className="mb-3 row">
                        <label for="name" className="hidden-xs col-sm-3 control-label text-end">Contact Name</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control" id="name" name="name" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.name} placeholder="Name" required data-description="enter the transaction contact's name" autofocus />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="email-address" className="hidden-xs col-sm-3 control-label text-end">Email&nbsp;Address</label>

                        <div className="col-sm-6">
                            <input type="email" className="form-control" id="email-address" name="email-address" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["email-address"]} placeholder="Email Address" required data-description="enter the transaction contact's email address" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="address" className="hidden-xs col-sm-3 control-label text-end">Street Address</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" name="address" id="address" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.address} placeholder="Street Address" required maxlength="175" data-type="address" data-description="enter the transaction contact's street address" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="city" className="hidden-xs col-sm-3 control-label text-end">City</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" name="city" id="city" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.city} placeholder="City" required maxlength="175" data-type="city" data-description="enter the transaction contact's city" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="country" className="hidden-xs col-sm-3 control-label text-end">Country</label>

                        <div className="col-sm-6">
                            <select className="form-control" name="country" id="country" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.country} required data-description="enter the transaction contact's country">
                                <option value="" disabled selected>Country:</option>
                                {this.state.countriesOptions.map((item) => {
                                    return (<option value={item}>{item}</option>)
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="state-autofill-target">
                        <input type="text" name="state-autofill" id="state-autofill" />
                    </div>

                    <div className="mb-3 row">
                        <label for="state" className="hidden-xs col-sm-3 control-label text-end">State or Province</label>

                        <div className="col-sm-6">
                            <select className="form-control" name="state" id="state" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.state} required data-description="enter the transaction contact's state or province">

                            </select>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="postal-code" className="hidden-xs col-sm-3 control-label text-end">Postal Code</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" name="postal-code" id="postal-code" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state["postal-code"]} placeholder="Postal Code" required maxlength="175" data-type="postal code" data-description="enter the transaction contact's postal code" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="phone" className="hidden-xs col-sm-3 control-label text-end">Phone Number</label>

                        <div className="col-sm-6">
                            <input type="text" className="form-control string" name="phone" id="phone" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.phone} placeholder="Phone Number" required maxlength="175" data-type="phone number" data-description="enter the transaction contact's phone number" />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="fax" className="hidden-xs col-sm-3 control-label text-end">Fax Number</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control string" name="fax" id="fax" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.fax} placeholder="Fax Number" maxlength="175" data-type="fax number" data-description="enter the transaction contact's fax number" />

                                <span className="input-group-text">optional</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="district" className="hidden-xs col-sm-3 control-label text-end">District</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control string" id="district" name="district" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.district} placeholder="District" maxlength="175" />

                                <span className="input-group-text">optional</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="school" className="hidden-xs col-sm-3 control-label text-end">School</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control string" id="school" name="school" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.school} placeholder="School" maxlength="175" />

                                <span className="input-group-text">optional</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="type" className="hidden-xs col-sm-3 control-label text-end">Transaction Type</label>

                        <div className="col-sm-6">
                            <select className="form-control" name="type" id="type" disabled={this.state.loading} required value={this.state.type} onChange={this.handleInputChange} data-description="select the transaction type">
                                <option value="" disabled selected>Transaction Type:</option>
                                {
                                    ["Trial", "Pilot", "Paid"].map((type) => {
                                        return <option value={type}>{type}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="price" className="hidden-xs col-sm-3 control-label text-end">Transaction Price</label>

                        <div className="col-sm-6">
                            <div className="input-group">
                                <span className="input-group-text">$</span>

                                <input type="number" className="form-control" id="price" name="price" min="0" step="1" max="9999" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.price} placeholder="Price" required data-description="enter the transaction price" />

                                <span className="input-group-text">.00</span>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="comments" className="hidden-xs col-sm-3 control-label text-end">Comments (optional)</label>

                        <div className="col-sm-6">
                            <textarea className="form-control" id="comments" name="comments" disabled={this.state.loading} onChange={this.handleInputChange} value={this.state.comments} placeholder="Comments"></textarea>
                        </div>
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-secondary btn-lg">
                        {this.state.loading ? <ClipLoader
                            size={20}
                            color={"#123abc"}
                            loading={this.state.loading}
                        /> : "Create Transaction"}
                    </button>
                </form>

            </React.Fragment>
        )

    }

}

export default CreateTransaction;
