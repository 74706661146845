import React from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import studentCert from '../../../../content/images/student-cert.jpg'
import message from '../../../../components/message/message'
import redirect from '../../../../components/redirect/redirect'

function SignUpSuccess(props) {
    return (<>
        <div className="alert alert-info row justify-content-center">
            <p>Please check your email for a verification link. You'll need to verify your email address in order to access all of the features of your trial subscription.</p>
            <p><strong>If you do not receive this email within 15 minutes, please check your spam folder.</strong></p>
        </div>
        <Container>
            <h1 className="section-header text-center">Welcome to Great Leaps Digital!</h1>
            <div className="row">
                <div className="d-flex col-md-12 col-lg-7">
                    <img className="img-fluid m-auto" src = {studentCert} alt = "Student holding a great leaps certificate" title = "Welcome!" />
                </div><div className="col-md-12 col-lg-5 text-start vcenter">
                    <br className="d-block d-sm-none visible-sm" />
                    <p><strong>Congratulations</strong> again in taking this "<em>Great Leap</em>" in your student or child's reading development. If you provided your contact information, expect a member of our team to reach out to you. Great learning starts with a great first experience and it is our aim to be your solution of choice from the first step through the finish line.</p>
                    
                    <p>In the meantime if you have any questions at all do not hesitate to call, our team is always here to support you.</p>
                    
                    <p>At your service,</p>
                    
                    <p><strong>The Great Leaps Team</strong>
                    <br/><a href="mailto:glinfo@greatleaps.com">glinfo@greatleaps.com</a><br/>+1 (877) 475-3277</p>

                </div>
            </div>
            <div className="text-center">
                <Button variant="secondary" size='lg' className="mb-3" onClick={() => {
                    redirect.send("/students", props, () => {
                        message.info("Add a student below then click the dolphins above to navigate to your dashboard and get started!")
                    })
                }}>
                    Lets Get Started!
                </Button>
                <p>Don't forget our <Link to = "/help/frequently-asked-questions">FAQ</Link> and <Link to = "/dashboard">instructional videos</Link>.</p>
                <p className="mb-0">Our mission is to <strong>equip all students</strong> for success through <strong>simple</strong> and <strong>effective</strong> educational programs.</p>
            </div>
        </Container>
    </>)    
}

export default SignUpSuccess
