


import React from 'react'
type ProbeContextType = {
    setFontSize: (fontsize: number) => any,
    fontSize: number
    currentResponseCount?: 0, 
    setCurrentResponseCount?: (currentResponseCount) => any, 
    errors?: any[], 
    setErrors?: (errors) => any,
    timer?: false,
    setTimer?: (timer) => any,
    setPopup?: (popup) => any,
    popup?: string,

}
export const ProbeContext = React.createContext<ProbeContextType>({
    setFontSize: (fontsize: number) => {},
    fontSize: 1,
    currentResponseCount: 0, 
    setCurrentResponseCount: (currentResponseCount) => {}, 
    errors: [], 
    setErrors: (errors) => {},
    timer: false,
    setTimer: (timer) => {},
    setPopup: (popup) => {},
    popup: ""
});
