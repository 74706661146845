import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import getURLParameters from '../../helpers/getURLParams'
import ChartHelp from '../../components/help/ChartHelp'
import redirect from '../../components/redirect/redirect'
import message from '../../components/message/message'
import BeatLoader from 'react-spinners/BeatLoader'
import buildQuery from '../../helpers/buildQuery'
import { ReadingSubjects, StoryLevels } from '../../shared/routes/readingRoutes'
import { MathSubjects } from '../../shared/types/mathTypes'
import Skeleton from 'react-loading-skeleton'
import * as studentModel from "../../shared/routes/studentRoutes"
import { ProgramsType, Student } from '../../shared/types/studentTypes'
import * as recordsModel from '../../shared/routes/recordRoutes'
import StudentRoster from '../../components/StudentRoster'
import ExerciseRecords from '../../pages/records/components/ExerciseRecords'
import Chart, { ChartType } from './components/Chart'
import { ChartData, ChartResponse, RawChartResponse } from '../../shared/types/recordTypes'
import { isStoryType } from '../../shared/routes/probeListRoutes'
import RecordsRoster from './components/RecordsRoster'
import { RecordsContext } from './RecordsContext'
import { modelRequest } from '@stem-sims/nexus'
import moment from 'moment'
import { EmergentReaderSubjects } from '../../shared/types/emergentReaderTypes'

type RecordsPageResponses = [ChartData, Student, any]

class Records extends React.Component<{
    type: ReadingSubjects | StoryLevels | MathSubjects | EmergentReaderSubjects,
    program: ProgramsType,
    location: any
}, {
    studentID: string
    studentName: string
    customerUsername: string
    adjacent: {
        next: any
        prev: any
    }, //adjacent students
    rawRecords: any[],
    donutData: any
    suggestedPhonicsType: "phonics" | "alphabet"
    loading: boolean
    buttonsDisabled: boolean
    closeRoster: boolean,
    currentChart: ChartType,
    chartData: ChartData,
    program: ProgramsType,
    filteredStudents: Student[],
    filteredInstructor: string,
    filteredStatus: string,
    savedDateRange: { startDate: string, endDate: string },
    rawGradeResponses: RawChartResponse[]
    gradeStartDate: string
    gradeEndDate: string
    gradeResponses: ChartResponse[]
}>
{
    /*********************
    * Global Variables
    *********************/
    state = {
        studentID: null,
        studentName: "",
        customerUsername: "",
        adjacent: {
            next: null,
            prev: null
        }, //adjacent students
        rawRecords: [],
        donutData: {},
        suggestedPhonicsType: "phonics" as "phonics" | "alphabet",
        loading: true,
        buttonsDisabled: true,
        donutsLoading: true,
        donutsRefresh: true,
        closeRoster: false,
        currentChart: this.props.location.state?.currentChart || "performanceChart",
        program: this.props.program,
        chartData: null,
        filteredStudents: [],
        filteredInstructor: null,
        filteredStatus: "Active",
        savedDateRange: { startDate: null, endDate: null },
        rawGradeResponses: [],
        gradeStartDate: null,
        gradeEndDate: null,
        gradeResponses: []
    }

    getUpdatedGradeExerciseResponses = (responses, chartGoals) => {
        let responsesCopy = [...responses]
        let newChartResponses = []
        let startedProgressResponses = false
        if(responsesCopy.length > 1) {
            for(let i = 0; i < responsesCopy.length; i++) {
                let currChartResponseDate = moment(responsesCopy[i].date).format("YYYY-MM-DD")

                if (!chartGoals[i] || currChartResponseDate != chartGoals[i].date) {
                    startedProgressResponses = true
                }

                // Fixing invalid date for first chart
                if(startedProgressResponses) {
                    newChartResponses.push({...responsesCopy[i], date: currChartResponseDate })
                }
            }
            
        } else {
            newChartResponses = []
        }

        return newChartResponses
    }

    updateRecords = async () => {
        await new Promise((resolve, reject) => {
            this.setState({ loading: true }, async () => {
                const params = getURLParameters(this.props)
                const getRecords = recordsModel.get(params.id, this.props.type)
                const getDonuts = modelRequest.get("/api/donuts", {
                    params: {
                        studentID: params.id,
                    }
                })
                try {
                    const [chartData, student, donutData]: RecordsPageResponses = await Promise.all([getRecords, studentModel.getOne(params.id), getDonuts])
                    const keepUserDateRange = this.state.savedDateRange.endDate && this.state.savedDateRange.startDate ? true : false

                    const gradeResponses = this.getUpdatedGradeExerciseResponses(chartData.chartResponses, chartData.chartGoals)
                    const rawGradeResponses = this.getUpdatedGradeExerciseResponses(chartData.rawRecords, chartData.chartGoals)
                    
                    let gradeStartDate
                    let gradeEndDate

                    if(!keepUserDateRange) {
                        if(gradeResponses.length === 0) {
                            gradeStartDate = chartData.startDate
                            gradeEndDate = chartData.endDate
                        } else if(gradeResponses.length === 1) {
                            gradeStartDate  = moment(gradeResponses[0].date).subtract("day", 1).format("YYYY-MM-DD")
                            gradeEndDate = moment().format("YYYY-MM-DD")
                        } else {
                            gradeStartDate  = moment(gradeResponses[0].date).subtract("day", 1).format("YYYY-MM-DD")
                            gradeEndDate = gradeResponses[gradeResponses.length - 1].date
                        }
                    } else {
                        gradeStartDate = this.state.savedDateRange.startDate
                        gradeEndDate = this.state.savedDateRange.endDate
                    }

                    this.setState({
                        gradeStartDate,
                        gradeEndDate,
                        gradeResponses,
                        rawGradeResponses,
                        chartData,
                        savedDateRange: keepUserDateRange ?
                            { endDate: this.state.savedDateRange.endDate, startDate: this.state.savedDateRange.startDate } :
                            { endDate: null, startDate: null },
                        studentID: params.id,
                        studentName: student?.name,
                        customerUsername: student?.owner?.username ?? null,
                        filteredStatus: params.status ?? "Active",
                        filteredInstructor: params.instructor ?? null,
                        loading: false,
                        donutData: donutData,
                    }, () => {
                        resolve("")
                    })
                } catch (error) {
                    return redirect.send('/students', this.props, () => {
                        message.error(error ?? "There has been an error getting your student's records.")
                    })
                }
            })
        })
    }

    setSavedDateRange = (chartDates) => {
        this.setState({ savedDateRange: chartDates })
    }

    toggleRosterClicked = () => {
        this.setState({ closeRoster: !this.state.closeRoster })
    }

    setActiveStudent = async (studentId) => {
        let recommendedType = this.props.type
        const isStory = isStoryType(this.props.type)
        const suggestedExercise = await recordsModel.get(studentId, this.props.type)
        if(isStory) {
            recommendedType = suggestedExercise.suggestedStory
        } else if(["phonics", "alphabet"].includes(this.props.type)) {
            recommendedType = suggestedExercise.suggestedPhonicsType  
        }
        const redirectLink = `/students/records/${isStory ? "stories/" : ["phonics", "alphabet"].includes(recommendedType) ? "phonics/" : ""}${recommendedType}`;
        (this.props as any).history.push({
            pathname: redirectLink,
            search: `?${buildQuery({ id: studentId })}`,
            state: { type: recommendedType, currentChart: this.state.currentChart, program: this.props.program }
        })
        setTimeout(() => {
            this.updateRecords() // refreshes page after url changes
        }) // redirect does not reload the page when pathname doesn't change
    }

    clickStudentRow = (row) => {
        this.setActiveStudent(row.original.id)
    }

    /****************
    * React Functions
    *****************/
    componentDidMount() {
        this.updateRecords()
    }

    recordsContextProvider = () => {
        return {
            ...this.state,
            type: this.props.type,
            setFilteredStudents: (students: Student[]) => { this.setState({ filteredStudents: students }) },
            setFilteredInstructor: (instructor: string) => { this.setState({ filteredInstructor: instructor }) },
            setFilteredStatus: (status: string) => { this.setState({ filteredStatus: status }) },
            updateChartType: (type: ChartType) => { this.setState({ currentChart: type }) }
        }
    }

    render() {
        const { studentID, studentName, customerUsername, loading } = this.state
        const { type, program } = this.props

        return (
            <Container fluid="xl">
                <Row className="text-center mt-3 pb-2">
                    <h1 className="break-words h3 fw-bold">{
                        studentName ? `
                    ${studentName}${customerUsername ? ` (from ${customerUsername})` : ""}'s
                ` : <Skeleton width={120} />} Records
                        <ChartHelp />
                    </h1>
                </Row>
                <RecordsContext.Provider value={this.recordsContextProvider()}>
                    <Row className='flex-row-reverse border rounded ' id="records">
                        <Col lg={this.state.closeRoster ? 11 : 8} className="animate-mid p-3">
                            {loading ?
                                <div style={{ margin: "0 auto", display: "table", height: "50vh" }}>
                                    <div style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                                        <BeatLoader
                                            size={15}
                                            color={"#123abc"}
                                            loading={true}
                                        />
                                    </div>
                                </div>
                                : <>
                                    <Chart setSavedDateRange={this.setSavedDateRange} setActiveStudent={this.setActiveStudent} />
                                    {this.state.currentChart !== "studentSummary" && <>
                                        <hr />
                                        <div className="text-center mx-n3 mt-4" id="raw-records">
                                            <ExerciseRecords studentId={studentID} probeType={type} program={program} />
                                        </div>
                                    </>}
                                </>
                            }
                        </Col>
                        <Col lg={this.state.closeRoster ? 1 : 4} className={`dont-print d-flex g-0 animate-mid ${this.state.closeRoster && "closed"} `} id="records-student-roster">
                            <div className='w-100 react-table-col position-relative pt-3'>
                                <StudentRoster clickRow={this.clickStudentRow} Roster={RecordsRoster} />
                            </div>
                            <button onClick={this.toggleRosterClicked} className='btn btn-secondary rounded-0 fw-bold px-1'>
                                <i className={`fas fa-chevron-${this.state.closeRoster ? "right" : "left"}`} />
                            </button>
                        </Col>
                    </Row>
                </RecordsContext.Provider>
            </Container>
        )
    }
}

export default Records
