import React from "react"
import { Link } from "react-router-dom"
import { buildQuery } from "@stem-sims/great-leaps-react-common"
import { ReadingSubjects, StoryLevels, storyLevels } from "../../shared/routes/readingRoutes"

interface ProbeGroupHeaderParameters {
    studentID: string
    active: ReadingSubjects | StoryLevels
    suggestedStory: "high" | "middle" | "elementary"
    suggestedPhonicsType: "phonics" | "alphabet"
    basePath: string
}

export const phonicsPath = ({studentID, suggestedPhonicsType, basePath}) => {
    const standardVariables = buildQuery({ id: studentID })
    return`${basePath}/${suggestedPhonicsType}?${standardVariables}`    
}

export default function ReadingLinks({studentID, active, suggestedStory, suggestedPhonicsType, basePath}: ProbeGroupHeaderParameters) {
    const standardVariables = buildQuery({ id: studentID })
    return (<>
        <div className = "row subheader-text justify-content-around">
            <Link
                to = {`${basePath}/${suggestedPhonicsType}?${standardVariables}`}
                className = {`col-12 col-md-3 btn btn-secondary btn-lg ${active === "phonics" ? "active" : ""}`}
            >
                Phonics
            </Link>

            <Link 
                to = {`${basePath}/phrases?${standardVariables}`}
                className = {`col-12 col-md-3 btn btn-secondary btn-lg ${active === "phrases" ? "active" : ""}`}
            >
                Phrases
            </Link>

            <Link
                to = {`${basePath}/stories/${suggestedStory}?${standardVariables}`}
                className = {`col-12 col-md-3 offset-md-0 btn btn-secondary btn-lg ${storyLevels.includes(active) ? "active" : ""}`}
            >
                Stories
            </Link>
        </div>

        {/* Stories Sub heading */}
        {storyLevels.includes(active) &&
            <div className = "row">
                <div className = "col-sm-10 offset-sm-1">
                    <div className = "row subheader-text justify-content-around">
                        <Link
                            to = {`${basePath}/stories/elementary?${standardVariables}`}
                            className = {`col-sm-3 btn btn-secondary alternate-fill btn-lg ${active === "elementary" ? "active" : ""}`}
                        >
                            Elementary
                        </Link>
                        <Link
                            to = {`${basePath}/stories/middle?${standardVariables}`}
                            className = {`col-sm-3 btn btn-secondary alternate-fill btn-lg ${active === "middle" ? "active" : ""}`}
                        >
                            Middle
                        </Link>
                        <Link
                            to = {`${basePath}/stories/high?${standardVariables}`}
                            className = {`col-sm-3 btn btn-secondary alternate-fill btn-lg ${active === "high" ? "active" : ""}`}
                        >
                            High
                        </Link>
                    </div>
                </div>
            </div>
        }
    </>)
}
