import React from 'react'
import { Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as organizationUsers from '../../shared/routes/organizationUsersRoutes'
import * as statusModel from '../../shared/routes/statusRoutes'
import { ReactTable } from '@stem-sims/nexus'
import buildQuery from '../../helpers/buildQuery'
import message from '../../components/message/message'
import RemoveInstructor from '../../components/organization/RemoveInstructor'
import StudentsPreviewSlidingPane from '../../components/organization/StudentsPreview'
import AssignInstructor from "../../pages/manage/instructor/AssignUser"
import * as organizationUsersTypes from "../../shared/types/orgUserTypes"
import { Status } from '../../shared/types/recordTypes'
import RemoveInstructorGroup from '../../components/organization/RemoveInstructorGroup'

interface ManageProps extends RouteComponentProps {
    org: {
        id: string,
        name: string,
        personal: boolean,
        permissions: string,
        groupName: string
    }
}

class ManageOrg extends React.Component<ManageProps,
    {
        isStudentPreviewOpen: boolean,
        studentPreviewInstructor: organizationUsersTypes.Instructor,
        studentPreviewInstructorGroupName: string,
        isRemoveInstructorOpen: boolean,
        isRemoveInstructorGroupOpen: boolean,
        instructorToRemove: organizationUsersTypes.Instructor,
        initialInstructors: organizationUsersTypes.Group[],
        nonDefaultMembers: Set<string>,
        totalOrgMembers: Set<string>,
        groups: organizationUsersTypes.Group[],
        status: Status["reading"],
        invites: organizationUsersTypes.Invite[],
        assignModal: any
    }>{

    state = {
        studentPreviewInstructor: { name: null, username: null, permission: null, groupName: null, studentLimit: null },
        studentPreviewInstructorGroupName: null,
        isStudentPreviewOpen: false,
        isRemoveInstructorOpen: false,
        isRemoveInstructorGroupOpen: false,
        instructorToRemove: { name: null, username: null, permission: null, groupName: null, studentLimit: null },
        initialInstructors: [],
        groups: [],
        nonDefaultMembers: new Set<string>(),
        totalOrgMembers: new Set<string>(),
        status: {
            active: null,
            available: null,
            used: null,
            delegated: null,
            shared: [],
            expiringSoon: []
        },
        invites: [],
        assignModal: null
    }

    componentDidMount() {
        this.loadInstructorsAndStatus()
    }

    loadInstructorsAndStatus = () => {
        Promise.all([organizationUsers.get(), statusModel.get()]).then(([users, status]) => {
            let nonDefaultMembers = new Set<string>()
            let totalOrgMembers = new Set<string>()
            for (let groups of users.groups) {
                for (let instructor of groups.instructors) {
                    if (groups.name !== 'Default') {
                        nonDefaultMembers.add(instructor.username)
                    }
                    totalOrgMembers.add(instructor.username)
                }
            }
            this.setState({
                initialInstructors: users.groups,
                groups: users.groups,
                nonDefaultMembers: nonDefaultMembers,
                totalOrgMembers: totalOrgMembers,
                status: status.reading,
                invites: users.invites
            })
        }).catch((err) => {
            message.error(err)
        })
    }

    filterInstructors = (event) => {
        var updatedList = this.state.initialInstructors;
        updatedList = updatedList.filter((item) => {
            return item.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
        });
        this.setState({ groups: updatedList });
    }

    viewClass = (instructor, groupName, event) => {
        event.preventDefault()
        this.setState({ studentPreviewInstructor: instructor, studentPreviewInstructorGroupName: groupName, isStudentPreviewOpen: true })
    }
    instructorsEdited = () => {
        this.setState({ assignModal: null })
        this.loadInstructorsAndStatus()
    }
    render() {
        const columns = [
            {
                id: 'selection',
                disableSortBy: true,
                width: 35,
                Header: ({ getToggleAllRowsSelectedProps }) => {
                    const { indeterminate, ...rest } = getToggleAllRowsSelectedProps()
                    return (<Form.Check type="checkbox" className="tableSelect"  {...rest} />)
                },
                Cell: ({ row }) => {
                    const { indeterminate, ...rest } = row.getToggleRowSelectedProps()
                    return (<Form.Check type="checkbox" className="tableSelect"  {...rest} />)
                }
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'View Students',
                Cell: ({ row }) => (
                    <Button onClick={this.viewClass.bind(this, row.original, row.original.groupName)} variant="link" className="text-center">
                        Students
                    </Button>
                ),
                maxWidth: 120,
            },
            {
                Header: 'Remove Instructor',
                Cell: ({ row }) => (<>
                    {row.original.groupName && row.original.groupName !== "Default" && <>
                    <Button
                        onClick={() => {
                            this.setState({ isRemoveInstructorGroupOpen: true, instructorToRemove: row.original })
                        }}
                        className="text-center"
                        variant="link"
                    >
                        Remove From Group
                    </Button>
                    <br />
                    </>}
                    <Button
                        onClick={() => {
                            this.setState({ isRemoveInstructorOpen: true, instructorToRemove: row.original })
                        }}
                        className="text-center"
                        variant="link"
                    >
                        Kick From Organization
                    </Button>
                </>),
                maxWidth: 120,
            }
        ]
        let { status } = this.state

        return (<Container id="student-roster">
            <h1 className="text-info pt-4 h3">Manage {this.props.org?.name}'s Organization</h1>
            <Row className="my-4">
                <Col md={4} xl={3}>
                    <Card>
                        <Card.Header className="bg-blue text-white">
                            <h2 className='h5 mb-0'>Info <i className="fa fa-info-circle float-right mx-1" /></h2>
                        </Card.Header>
                        <Card.Body className="blue-panel text-info">
                            <p className='mt-3'>Students Purchased: <b className="float-right">{status.active}</b></p>
                            <p>Students Used: <b className="gl-coral float-right">{status.used}</b></p>
                            <p>Students Available: <b className="gl-blue float-right">{status.available}</b></p>
                            <h2 className='h5 text-center mt-5'>Pending Invites <i className="fa fa-hourglass mx-1" /></h2>
                            <ul id="checkbox-list" className="list-group mb-3">
                                {this.state.invites.map((invite: organizationUsersTypes.Invite, i) =>
                                    <li className={`list-group-item`} key={i}>
                                        <div className="py-2">{invite.email}</div>
                                    </li>
                                )}
                                <Link to="/manage/instructor/invite" className={`btn btn-primary py-1`}>
                                    <span className=" ">+ Invite User</span>
                                </Link>
                            </ul>
                        </Card.Body>

                    </Card>
                </Col>
                <Col md={8} xl={9}>
                    <Card className="shadow-sm">
                        <Card.Header className="bg-blue">
                            <h2 className='h4 mb-1'>Instructors <i className="fa fa-users mx-1 float-right" /></h2>
                        </Card.Header>
                        <Card.Body>
                            <ul id="checkbox-list" className="list-group mb-3">
                                {this.state.totalOrgMembers.size === 0 ?
                                    <p className="text-center">There aren't any instructors in your organization <Link to="/manage/instructor/invite">Click here</Link> to invite instructors.</p>
                                    :
                                    <div className="d-flex justify-content-between ml-0 mb-3 section-header">
                                        <h3 className="text-info mb-0">Groups</h3>
                                        {this.props.org.permissions !== 'Manager' &&
                                            <Link to="/manage/group/create" className="btn btn-secondary mx-3">Create Group</Link>
                                        }
                                    </div>
                                }
                                {this.state.groups.map((group: organizationUsersTypes.Group) => {
                                    let nameQuery = buildQuery({ name: group.name, studentLimit: String(group.studentLimit) })
                                    group.instructors = group.instructors.map((instructor) => ({ id: instructor.username, group: group.name, ...instructor })) // adding id for react table
                                    return <div className="groups mb-3" key={group.name}>
                                        <div className="d-flex">
                                            <label className="h5 mb-0" style={{ 'whiteSpace': 'nowrap', overflow: 'hidden', 'textOverflow': 'ellipsis' }}><b className="align-middle">{group.name}</b></label>
                                            {group.name !== 'Default' && <div>
                                                <Link className="action-link text-info" to={`/manage/group/edit?${nameQuery}`}><i className="fa fa-edit" /></Link>
                                                {this.state.totalOrgMembers.size !== 0 && <Button variant="outline-light" className="text-info py-0" onClick={() => this.setState({ assignModal: group.name })}>
                                                    <i className="fa fa-plus" />
                                                    <span> Edit Instructors</span>
                                                </Button>}
                                                <Link className="align-middle gl-coral mx-2" to={`/manage/group/remove?${nameQuery}`}>Remove</Link>
                                            </div>}
                                        </div>
                                        {group.instructors.length > 0 ? <>
                                            <div className="text-center" >
                                                <ReactTable columns={columns} data={group.instructors} bulkActions={[]} sortBy={[{ id: "name" }]} />
                                            </div>
                                        </>
                                            :
                                            <p className='mt-2'>No instructors in the <b>{group.name}</b> group.</p>
                                        }
                                    </div>
                                })}
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <StudentsPreviewSlidingPane
                isOpen={this.state.isStudentPreviewOpen}
                onClose={() => {
                    message.clear()
                    this.setState({ isStudentPreviewOpen: false })
                }}
                selectedInstructor={this.state.studentPreviewInstructor}
                groupName={this.state.studentPreviewInstructorGroupName}
            />
            <RemoveInstructor
                isOpen={this.state.isRemoveInstructorOpen}
                onClose={() => {
                    this.setState({ isRemoveInstructorOpen: false })
                    this.loadInstructorsAndStatus()
                }}
                selectedInstructor={this.state.instructorToRemove}
                orgId={this.props.org?.id}
            />

            <RemoveInstructorGroup
                isOpen={this.state.isRemoveInstructorGroupOpen}
                onClose={() => {
                    this.setState({ isRemoveInstructorGroupOpen: false })
                    this.loadInstructorsAndStatus()
                }}
                selectedInstructor={this.state.instructorToRemove}
                groupName={this.state.instructorToRemove.groupName}
            />

            <Modal show={this.state.assignModal} onHide={() => this.setState({ assignModal: null })}>
                <Modal.Header closeButton>
                    <h2 className="h4 text-info">Assign Instructor to {this.state.assignModal}</h2>
                </Modal.Header>
                <Modal.Body>
                    <AssignInstructor groupName={this.state.assignModal} submitCallback={this.instructorsEdited} />
                </Modal.Body>
            </Modal>
        </Container >)
    }

}

export default ManageOrg;
