import React from 'react'
import getURLParameters from '../../../../../helpers/getURLParams'
import redirect from '../../../../../components/redirect/redirect'
import message from '../../../../../components/message/message'
import { initializeFormValidation } from '../../../../../helpers/forms/form-validation'
import { modelRequest } from '@stem-sims/nexus'

class Edit extends React.Component {
    state = {
        loading: true,
        user: null,
        maxStudents: null,
        minStudents: null,
        delegation: {
            username: null,
            used: null,
            delegated: null
        }
    }
    componentDidMount() {
        let params = getURLParameters(this.props)

        Promise.all([modelRequest.get("/api/subscription/share?username=" + params.user), modelRequest.get("/api/status")]).then(([delegation, status]) => {
            this.setState({ delegation: delegation, maxStudents: delegation.delegated + status.available, minStudents: delegation.used, user: params.user })
        }).catch((err) => {
            message.error(err ?? "There has been an error loading share students. Please try again.")
        }).finally(() => {
            this.setState({ loading: false })
        })

        initializeFormValidation(this.onSubmit)
    }

    onSubmit = () => {
        this.setState({ submitting: true }, () => {
            modelRequest.put("/api/subscription/share", {
                'students': document.getElementById('students').value,
                'username': this.state.user
            }).then((data) => {
                message.clear()

                return redirect.send("/account/subscription/share/manage", this.props, () => {
                    message.success("Successfully edited.")
                })
            }).catch((err) => {
                message.error(err)
            }).finally(() => this.setState({ submitting: false }))
        })
    }

    render() {
        let { delegation, maxStudents, minStudents } = this.state
        return (
            <React.Fragment>
                <h1>Edit Susbcription Sharing Setting</h1>

                <div className="row subheader-text">
                    <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2">
                        <p>Increase or decrease the number of students shared to a user below. Please note that the number of students can only be decreased up to the number of unused students in the user's account.</p>
                    </div>
                </div>

                <form className="form-horizontal form-validate" noValidate method="post">
                    <div className="mb-3 row">
                        <label className="text-center-xs bold-xs col-sm-3 control-label text-end">User</label>

                        <div className="col-sm-6">
                            <p className="form-control-static text-start text-center-xs break-words">{delegation.username}</p>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label for="students" className="col-sm-3 control-label text-end">Students</label>

                        <div className="col-sm-6">
                            <input type="number" className="form-control" id="students" name="students" min={minStudents} step="1" max={maxStudents} defaultValue={delegation.delegated} required data-description="enter the number of students to share" />
                        </div>
                    </div>


                    <button type="submit" className="btn btn-secondary btn-lg">Edit</button>
                </form>
            </React.Fragment>
        )
    }
}

export default Edit
